//注文情報詳細に表示する注文情報部分のコンポーネント
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import * as expfnc from '../../common/commonFunction';
import { TableCell, TableHead, TableRow } from '../../components/table/TableStyled';
import group from '../../data/Group.json';

export const AcceptInfo = (props) => {
    return (
        <Box>
            注文情報
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHead>注文番号</TableHead>
                                <TableCell>{props.orderData.order_no}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>注文日時</TableHead>
                                <TableCell>{expfnc.datetostr(props.orderData.order_date, false, true)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>営業所名</TableHead>
                                <TableCell>{expfnc.getJsonData(group, props.orderData.group_cd)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>担当者名</TableHead>
                                <TableCell>{props.orderData.person_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>客先</TableHead>
                                <TableCell>{props.orderData.customer_name}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};
