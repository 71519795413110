import ApiClient from './ApiClient';

class MstLogService {
    search(date_from, date_to, mst_name, key_code) {
        return ApiClient.get(
            '/api/mstLog/search',
            (response) => {
                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    date_from: date_from,
                    date_to: date_to,
                    mst_name: mst_name,
                    key_code: key_code,
                },
            },
        );
    }
}

export default new MstLogService();
