/**
 * 注文状況検索結果(得意先用)
 */
import { useContext, useState } from 'react';

import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import muiTableCell from '@mui/material/TableCell';
import muiTableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { orderContext } from '../../components/MainLayout';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { AcceptDetailModalCustomer } from './AcceptDetailModalCustomer';

const TableHeadCell = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'left',
    border: 'solid',
    borderColor: grey[200],
    borderWidth: '2px',
}));
const TableBodyCell = styled(muiTableCell)(() => ({
    fontSize: '13px',
    textAlign: 'left',
    alignItems: 'left',
    border: 'solid',
    borderColor: grey[200],
    borderWidth: '2px',
}));
const TableRow = styled(muiTableRow)({
    height: 10,
});

export const AcceptResultCustomer = (props) => {
    const [visibleRows, setVisibleRows] = useState(props.rows);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [, setResultData] = useState();
    const [, setOrder] = useContext(orderContext);

    const ModalDetail = (row) => {
        // フロート画面表示用
        setOrder(row);
        setResultData(row);
        setShowModalDetail(true);
    };

    return (
        <div>
            <TableSearchResult rows={props.rows} setVisibleRows={setVisibleRows}>
                {/* <TableHead> */}
                <TableRow>
                    <TableHeadCell sx={{ width: 120 }}>伝票番号</TableHeadCell>
                    <TableHeadCell sx={{ width: 120 }}>予定納品日</TableHeadCell>
                    <TableHeadCell>物件名</TableHeadCell>
                    <TableHeadCell>住所</TableHeadCell>
                    <TableHeadCell sx={{ width: 150 }}>担当者名</TableHeadCell>
                </TableRow>
                {/* </TableHead> */}
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow key={row.order_no}>
                            <TableBodyCell sx={{ padding: 2 }}>
                                {/* 注文番号 */}
                                <Link style={{ cursor: 'pointer' }} onClick={() => ModalDetail(row)}>
                                    {row.jdn_no}
                                </Link>
                            </TableBodyCell>

                            {/* 予定納品日 */}
                            <TableBodyCell sx={{ padding: 1 }}>{expfnc.datetostr(row.scheduled_delivery_date)}</TableBodyCell>

                            {/* 物件名・客先 */}
                            <TableBodyCell sx={{ padding: 1 }}>{row.property_name}</TableBodyCell>

                            {/* 住所 */}
                            <TableBodyCell sx={{ padding: 1 }}>
                                {row.delivery_add2}
                                <br />
                                {row.delivery_add3}
                            </TableBodyCell>

                            {/* 担当者名 */}
                            <TableBodyCell sx={{ padding: 1 }}>{row.person_name}</TableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TableSearchResult>

            {showModalDetail ? <AcceptDetailModalCustomer showModal={showModalDetail} setShowModal={setShowModalDetail} /> : <></>}
        </div>
    );
};
