import React from 'react';
import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import muiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { orderContext } from '../../components/MainLayout';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import AcceptService from '../../services/Accept.service';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    backgroundColor: '#F4F8FE',
    // backgroundColor: 'white',
    position: 'absolute',
    top: '60px',
    left: '80px',
    right: '80px',
    bottom: '60px',
    overflow: 'auto',
};

const TitleHead = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    backgroundColor: '#64b5f6',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'left',
    height: '53px',
}));
const TitleHeadCell = styled(muiTableCell)(() => ({
    fontSize: '13px',
    backgroundColor: '#64b5f6',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'left',
    height: '53px',
}));

export const AcceptDetailModalCustomer = (props) => {
    const [visibleRows, setVisibleRows] = useState([
        {
            lin_no: '',
            product_name: '',
            standard: '',
            order_quantity: '',
            unit: '',
        },
    ]);
    const [order] = useContext(orderContext);

    useEffect(() => {
        //初期処理
        //明細取得
        AcceptService.getDetailCustomer(order.jdn_no).then(
            (response) => {
                setVisibleRows(response);
            },
            (error) => {
                //ステータス履歴データ取得エラー
            },
        );
    }, []);

    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            {/* ボタンは右上に固定表示する */}
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    position: 'fixed',
                                    top: '70px',
                                    right: '110px',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>
                            <Typography>注文情報</Typography>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableHead sx={{ width: 115 }}>伝票番号</TableHead>
                                                <TableCell>{order.jdn_no}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>担当者名</TableHead>
                                                <TableCell>{order.person_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>物件名</TableHead>
                                                <TableCell>{order.property_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>納品先住所</TableHead>
                                                <TableCell>
                                                    〒{order.delivery_add1}
                                                    <br />
                                                    {order.delivery_add2}
                                                    <br />
                                                    {order.delivery_add3}
                                                    <br />
                                                    {order.delivery_add4}
                                                    <br />
                                                    {order.delivery_add5}
                                                    <br />
                                                    {order.delivery_add6}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>TEL1/担当者</TableHead>
                                                <TableCell>
                                                    {order.delivery_tel1} / {order.delivery_person_name1}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>TEL2/担当者</TableHead>
                                                <TableCell>
                                                    {order.delivery_tel2} / {order.delivery_person_name2}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>FAX</TableHead>
                                                <TableCell>{order.delivery_fax}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Box sx={{ mt: 2 }}>
                                <Typography>注文内容</Typography>
                            </Box>
                            <Box component="main">
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="headertable">
                                            {/* <HeaderTableRow> */}
                                            <TableRow>
                                                <TitleHead sx={{ width: 90 }}>予定納品日</TitleHead>
                                                <TitleHeadCell sx={{ width: 100 }}>
                                                    {expfnc.datetostr(order.scheduled_delivery_date, true, false)}
                                                </TitleHeadCell>
                                                <TitleHead sx={{ width: 90 }}>送り状番号</TitleHead>
                                                <TitleHeadCell>
                                                    {order.shipping_company_code === '01' ? (
                                                        <Link
                                                            href={`https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${order.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {order.destination_number}
                                                        </Link>
                                                    ) : order.shipping_company_code === '02' ? (
                                                        <Link
                                                            href={`https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${order.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {order.destination_number}
                                                        </Link>
                                                    ) : order.shipping_company_code === '03' ? (
                                                        <Link
                                                            href={`https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S003&locale=ja&SVID=023&reqCodeNo1=${order.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {order.destination_number}
                                                        </Link>
                                                    ) : order.shipping_company_code === '04' ? (
                                                        <Link
                                                            href={`https://track.seino.co.jp/cgi-bin/gnpquery.pgm?GNPNO1=${order.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {order.destination_number}
                                                        </Link>
                                                    ) : order.shipping_company_code === '05' ? (
                                                        <Link
                                                            href={`https://lp-trace.nittsu.co.jp/web/webarpaa702.srv?denpyoNo1=${order.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {order.destination_number}
                                                        </Link>
                                                    ) : (
                                                        order.destination_number
                                                    )}
                                                </TitleHeadCell>
                                            </TableRow>
                                            {/* </HeaderTableRow> */}
                                        </Table>
                                        <Table sx={{ minWidth: 650 }} aria-label="result table">
                                            <TableHeaderRow>
                                                <TableRow>
                                                    <TableHead sx={{ width: 70 }}>行番</TableHead>
                                                    <TableHead>品名</TableHead>
                                                    <TableHead>規格</TableHead>
                                                    <TableHead sx={{ width: 80 }}>注文数量</TableHead>
                                                    <TableHead sx={{ width: 70 }}>単位</TableHead>
                                                </TableRow>
                                            </TableHeaderRow>
                                            <TableBody>
                                                {visibleRows.map((row, index) => (
                                                    <TableRow>
                                                        {/* <TableCellRow>{('00' + Number(row.lin_no)).slice(-2)}</TableCellRow> */}
                                                        <TableCellRow>{('00' + (index + 1)).slice(-2)}</TableCellRow>
                                                        <TableCellRow>{row.product_name}</TableCellRow>
                                                        <TableCellRow>{row.standard}</TableCellRow>
                                                        <TableCellRow>{row.order_quantity.toLocaleString()}</TableCellRow>
                                                        <TableCellRow>{row.unit}</TableCellRow>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
