import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Link } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { darken, lighten, styled } from '@mui/system';

import * as expfnc from '../../common/commonFunction';
import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { InputTextComment } from '../../components/input/InputTextComment';
import { RadioShare } from '../../components/radio/RadioShare';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { LOGIN_TYPE, MAX_FILE_SIZE_MB, MESSAGE, PAGE_NAME } from '../../constants';
import groupJson from '../../data/Group.json';
import fileuploadService from '../../services/FileUpload.service';
import AuthService from '../../services/auth.service';
import customerService from '../../services/customer.service';
import supplierService from '../../services/supplier.service';

//ヘッダー
export const FileUploadHeader = () => {
    const logintype = AuthService.getCurrentUser().logintype;
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.FILE_UPLOAD}
            </Typography>

            {/* 画面説明 */}
            {logintype === LOGIN_TYPE.SUPPLIER && (
                <Typography sx={{ flexGrow: 1, mb: 1 }}>
                    ファイルを追加後、ファイル種別・該当営業所を選択して、「アップロードを実行」ボタンを押してください。
                    <br />
                    ※ファイルサイズの上限は、１回のアップロードあたり5MBになります。
                </Typography>
            )}
            {logintype === LOGIN_TYPE.ADMIN && (
                <Typography sx={{ flexGrow: 1, mb: 1 }}>
                    ファイルを追加後、ファイル種別・共有先を選択して、「アップロードを実行」ボタンを押してください。
                    <br />
                    ※ファイルサイズの上限は、１回のアップロードあたり5MBになります。
                </Typography>
            )}
        </>
    );
};

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: theme.palette.mode === 'light' ? lighten('#FFFFFF', 0.85) : darken('#FFFFFF', 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

//メインページ
export const FileUpload = () => {
    //ヘッダー
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<FileUploadHeader />);
    }, []);

    const logintype = AuthService.getCurrentUser().logintype;
    const edicd = AuthService.getCurrentUser().edicd;
    const [supplierList, setSupplierList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [groupList, setgroupList] = useState();
    const [supplierName, setSupplierName] = useState();
    useEffect(() => {
        //初期処理
        if (logintype === LOGIN_TYPE.ADMIN) {
            //オートコンプリート用仕入先リスト取得
            supplierService.searchAllList().then(
                (response) => {
                    //検索結果データ取得成功
                    // setSupplierList = response;
                    setSupplierList(response);
                },
                (error) => {
                    //検索結果データ取得エラー
                },
            );
            //オートコンプリート用得意先リスト取得
            customerService.searchAllList().then(
                (response) => {
                    //検索結果データ取得成功
                    // setCustomerList = response;
                    setCustomerList(response);
                },
                (error) => {
                    //検索結果データ取得エラー
                },
            );
        }
        if (logintype === LOGIN_TYPE.SUPPLIER) {
            //自身の仕入先情報を取得
            supplierService.search().then(
                (response) => {
                    //検索結果データ取得成功
                    setSupplierName(response.supplier_name);
                    //営業所選択の表示切替のため営業所コードの配列設定
                    setgroupList(response.group_cd.split(','));
                },
                (error) => {
                    //検索結果データ取得エラー
                },
            );
        }
    }, []);

    const useFormMethods = useForm({
        defaultValues: {
            files: [
                // {
                //     filetype: '',
                //     sharecompany: '',
                //     companyname: '',
                //     filename: '',
                //     fileurl: '',
                //     comment: '',
                // },
            ],
        },
    });
    const { register, handleSubmit, control, reset } = useFormMethods;
    const { fields, remove, insert, update } = useFieldArray({
        name: 'files',
        control,
    });

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const submit = async (data) => {
        console.log(data);
        if (data.files.length === 0) {
            return;
        }
        fileupload(data);
    };

    const [visible, setVisible] = useState(false); //結果部分表示制御
    const fileSizeInMB = useRef(0);
    const fileCount = useRef(0);

    //ファイル追加処理
    const onDrop = useCallback((acceptedFiles) => {
        //一度に10件以上アップロードはできないように制御する
        if (acceptedFiles.length + fileCount.current > 10) {
            setDigProp({
                ...digProp,
                message: MESSAGE.FILE_UPLOAD_ORVER,
            });
            setDigOpen(true);
            return;
        }
        fileCount.current = fileCount.current + acceptedFiles.length;

        let fileSize = 0;
        for (let index = 0; index < acceptedFiles.length; index++) {
            const file = acceptedFiles[index];
            fileSize = fileSize + file.size / (1024 * 1024);
            if (fileSize + fileSizeInMB.current > MAX_FILE_SIZE_MB) {
                //5M以上
                setDigProp({
                    ...digProp,
                    message: MESSAGE.FILE_UPLOAD_SIZE_ORVER,
                });
                setDigOpen(true);
                return;
            }
        }
        fileSizeInMB.current = fileSizeInMB.current + fileSize;

        acceptedFiles.forEach((file, index) => {
            insert(index, {
                shared: '01', //共有先
                filename: file.name, //ファイル名
                fileurl: URL.createObjectURL(file), //ファイルURL
                filedata: file, //ファイルデータ
            });
        });
        setVisible(true);
    }, []);

    //ファイルアップロード実行処理
    async function fileupload(data) {
        for (let i = 0; i < data.files.length; i++) {}

        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        let detailList = [];
        for (let i = 0; i < data.files.length; i++) {
            await new Promise((resolve) => {
                setTimeout(() => {
                    //   console.log(i);
                    resolve();
                }, 1000);
            });
            let typ = '';
            let supCd = '';
            let supName = '';
            let cusCd = '';
            let cusName = '';
            let groupcd = '';
            let shared = '';
            if (logintype === LOGIN_TYPE.ADMIN) {
                switch (
                    shareselect //共有の種類
                ) {
                    case '00': //共有先の指定
                        switch (data.files[i].shared) {
                            case '01':
                                //得意先
                                cusName = data.files[i].customerComp.customer_name;
                                cusCd = data.files[i].customerComp.customer_cd;
                                //ファイル種別
                                typ = data.files[i].filetypecustomer;
                                groupcd = data.files[i].customerComp.group_cd;
                                break;
                            case '02':
                                //仕入先
                                supCd = data.files[i].supplierComp.supplier_cd;
                                supName = data.files[i].supplierComp.supplier_name;
                                //ファイル種別
                                typ = data.files[i].filetypesupplier;
                                groupcd = data.files[i].supplierComp.group_cd;
                                break;
                            default:
                        }
                        break;
                    case '01': //すべての得意先
                        //ファイル種別
                        typ = data.files[i].filetypecustomer;
                        break;
                    case '02': //すべての仕入先
                        //ファイル種別
                        typ = data.files[i].filetypesupplier;
                        break;
                    default:
                }
                shared = data.files[i].shared;

                if (shareselect === '00') {
                    if (data.files[i].shared === '01' && cusCd === void 0) {
                        setDigProp({
                            ...digProp,
                            message: '得意先を選択してください。',
                        });
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                        return;
                    }
                    if (data.files[i].shared === '02' && supCd === void 0) {
                        setDigProp({
                            ...digProp,
                            message: '仕入先を選択してください。',
                        });
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                        return;
                    }
                }
            } else {
                if (data.files[i].groupcd === '99') {
                    //営業所選択必須
                    setDigProp({
                        ...digProp,
                        message: '営業所を選択してください。',
                    });
                    setDigOpen(true);
                    submitProcessing.current = false;
                    setProcessing(false);
                    return;
                }
                typ = data.files[i].filetypesupplierself;
                supCd = edicd;
                supName = supplierName;
                groupcd = data.files[i].groupcd;
            }

            let dataURL = await convert2DataUrl(data.files[i].filedata);
            const detail = {
                file_type: typ, //ファイル種別
                customer_cd: cusCd, //得意先コード
                customer_name: cusName, //得意先名
                supplier_cd: supCd, //仕入先コード
                supplier_name: supName, //仕入先名
                group_cd: groupcd, //営業所コード
                file_name: data.files[i].filename, //ファイル名
                comment: data.files[i].comment, //コメント
                file: dataURL, //ファイルデータ
                shared: shared, //共有先
            };
            detailList.push(detail);
        }

        //ファイルアップロード実行
        fileuploadService.upload(shareselect, detailList).then(
            (response) => {
                //検索結果データ取得成功
                console.log(response);
                reset({
                    files: [],
                });

                setDigProp({
                    ...digProp,
                    message: 'ファイルのアップロードが完了しました。',
                });
                setDigOpen(true);
                setVisible(false);

                fileSizeInMB.current = 0;
                fileCount.current = 0;

                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log(error);
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    }

    //ファイルデータ変換
    async function convert2DataUrl(blobOrFile) {
        let reader = new FileReader();
        reader.readAsDataURL(blobOrFile);
        await new Promise((resolve) => (reader.onload = () => resolve()));
        return reader.result.split(',')[1];
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const [shareselect, setshareSelect] = useState('00'); //共有先を指定するラジオ

    //ラジオボタン選択時
    const handleValueChange = (event) => {
        setshareSelect(event.target.value);
    };

    // 共有先の選択時処理
    const handleSelectChange = (index, selectedValue) => {
        //選択内容を更新しファイル種別の表示を更新する
        update(index, { ...fields[index], shared: selectedValue });
    };

    //ダイアログ表示
    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
        onClose: () => setDigOpen(false),
    });

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    {/* ファイルアップロード */}
                    <Box sx={{ m: 1 }}>
                        <Box
                            {...getRootProps()}
                            sx={{
                                display: 'flex',
                                height: 200,
                                border: '1px dotted #888',
                                '&:hover': {
                                    backgroundColor: 'primary.main',
                                    opacity: [0.9, 0.8, 0.7],
                                },
                            }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <input {...getInputProps()} />
                            <Typography>アップロードしたいファイルをここにドラッグしてください。</Typography>
                        </Box>
                        {logintype === LOGIN_TYPE.ADMIN && <RadioShare name="shareselect" handleValueChange={handleValueChange} />}
                    </Box>

                    {visible && (
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="result table">
                                    <TableHeaderRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 130 }}>ファイル種別</TableHead>
                                            {logintype === LOGIN_TYPE.ADMIN && shareselect === '00' && (
                                                <TableHead sx={{ width: 330, textAlign: 'left' }}>共有先</TableHead>
                                            )}
                                            {logintype === LOGIN_TYPE.SUPPLIER && <TableHead sx={{ textAlign: 'left' }}>該当営業所</TableHead>}
                                            <TableHead sx={{ width: 300, textAlign: 'left' }}>ファイル名</TableHead>
                                            <TableHead sx={{ textAlign: 'left' }}>コメント</TableHead>
                                            <TableHead sx={{ width: 100, textAlign: 'left' }}>削除</TableHead>
                                        </TableRow>
                                    </TableHeaderRow>
                                    <TableBody>
                                        {fields.map((file, index) => (
                                            <TableRow key={file.id}>
                                                <TableCellRow>
                                                    {/* stt---ファイル種別 */}
                                                    {logintype === LOGIN_TYPE.ADMIN &&
                                                        ((shareselect === '00' && fields[index].shared === '01') || shareselect === '01') && (
                                                            <FormControl variant="standard" sx={{ minWidth: 100 }}>
                                                                <Select defaultValue={'1'} {...register(`files.${index}.filetypecustomer`)}>
                                                                    <MenuItem value={'1'}>納品書</MenuItem>
                                                                    <MenuItem value={'2'}>請求書</MenuItem>
                                                                    <MenuItem value={'3'}>お知らせ</MenuItem>
                                                                    <MenuItem value={'4'}>その他</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    {logintype === LOGIN_TYPE.ADMIN &&
                                                        ((shareselect === '00' && fields[index].shared === '02') || shareselect === '02') && (
                                                            <FormControl variant="standard" sx={{ minWidth: 100 }}>
                                                                <Select defaultValue={'5'} {...register(`files.${index}.filetypesupplier`)}>
                                                                    <MenuItem value={'5'}>支払明細書</MenuItem>
                                                                    <MenuItem value={'3'}>お知らせ</MenuItem>
                                                                    <MenuItem value={'4'}>その他</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    {logintype === LOGIN_TYPE.SUPPLIER && (
                                                        <FormControl variant="standard" sx={{ minWidth: 100 }}>
                                                            <Select defaultValue={'1'} {...register(`files.${index}.filetypesupplierself`)}>
                                                                <MenuItem value={'1'}>納品書</MenuItem>
                                                                <MenuItem value={'2'}>請求書</MenuItem>
                                                                <MenuItem value={'4'}>その他</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                    {/* end---ファイル種別 */}
                                                </TableCellRow>

                                                {logintype === LOGIN_TYPE.ADMIN && shareselect === '00' && (
                                                    <TableCellRow>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            {/* stt---共有先 */}
                                                            <FormControl variant="standard" sx={{ minWidth: 90, mr: 2 }} name={'shares' + index}>
                                                                <Select
                                                                    defaultValue={'01'}
                                                                    value={fields[index].shared}
                                                                    {...register(`files.${index}.shared`)}
                                                                    onChange={(e) => handleSelectChange(index, e.target.value)}
                                                                >
                                                                    <MenuItem value={'01'}>得意先</MenuItem>
                                                                    <MenuItem value={'02'}>仕入先</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            {/* end---共有先 */}

                                                            {/* stt---得意先の選択 */}
                                                            {fields[index].shared === '01' && (
                                                                <Controller
                                                                    name={`files.[${index}].customerComp`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field, fieldState }) => {
                                                                        return (
                                                                            <Autocomplete
                                                                                //optionsには value に入力される値の配列を渡す
                                                                                id="customerAutocomplete"
                                                                                options={customerList.sort(
                                                                                    (a, b) => -b.group_cd.localeCompare(a.group_cd),
                                                                                )}
                                                                                groupBy={(option) => option.group_name}
                                                                                getOptionLabel={(option) =>
                                                                                    option.customer_name + '/' + option.customer_cd
                                                                                }
                                                                                sx={{ width: 400 }}
                                                                                onChange={(e, value) => field.onChange(value)}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        name={'customername'}
                                                                                        placeholder="得意先名"
                                                                                    />
                                                                                )}
                                                                                size="small"
                                                                                renderGroup={(params) => (
                                                                                    <li key={params.key}>
                                                                                        <GroupHeader>{params.group}</GroupHeader>
                                                                                        <GroupItems>{params.children}</GroupItems>
                                                                                    </li>
                                                                                )}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {/* end---得意先の選択 */}
                                                            {/* stt---仕入先の選択 */}
                                                            {fields[index].shared === '02' && (
                                                                <Controller
                                                                    name={`files.[${index}].supplierComp`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field, fieldState }) => {
                                                                        return (
                                                                            <Autocomplete
                                                                                //optionsには value に入力される値の配列を渡す
                                                                                id="supplierAutocomplete"
                                                                                options={supplierList.sort(
                                                                                    (a, b) => -b.group_cd.localeCompare(a.group_cd),
                                                                                )}
                                                                                groupBy={(option) => option.group_name}
                                                                                getOptionLabel={(option) =>
                                                                                    option.supplier_name + '/' + option.supplier_cd
                                                                                }
                                                                                sx={{ width: 400 }}
                                                                                onChange={(e, value) => field.onChange(value)}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        name={'suppliername'}
                                                                                        placeholder="仕入先名"
                                                                                    />
                                                                                )}
                                                                                size="small"
                                                                                renderGroup={(params) => (
                                                                                    <li key={params.key}>
                                                                                        <GroupHeader>{params.group}</GroupHeader>
                                                                                        <GroupItems>{params.children}</GroupItems>
                                                                                    </li>
                                                                                )}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {/* end---得意先の選択 */}
                                                        </Box>
                                                    </TableCellRow>
                                                )}
                                                {logintype === LOGIN_TYPE.SUPPLIER && (
                                                    <TableCellRow>
                                                        <FormControl variant="standard" fullWidth sx={{ minWidth: 90, mr: 2 }}>
                                                            {groupList.length === 1 && (
                                                                <Select {...register(`files.${index}.groupcd`)} defaultValue={groupList[0]}>
                                                                    <MenuItem value={groupList[0]}>
                                                                        {expfnc.getJsonData(groupJson, groupList[0])}
                                                                    </MenuItem>
                                                                </Select>
                                                            )}
                                                            {groupList.length > 1 && (
                                                                <Select {...register(`files.${index}.groupcd`)} defaultValue={'99'}>
                                                                    <MenuItem key={99} value={'99'}></MenuItem>
                                                                    {groupList.map((cd, idx) => (
                                                                        <MenuItem key={idx} value={cd}>
                                                                            {expfnc.getJsonData(groupJson, cd)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </FormControl>
                                                    </TableCellRow>
                                                )}

                                                <TableCellRow>
                                                    <Link underline="always" target="_blank" rel="noopener" href={file.fileurl}>
                                                        {file.filename}
                                                    </Link>
                                                </TableCellRow>

                                                <TableCellRow>
                                                    <InputTextComment name={'comment'} {...register(`files.${index}.comment`)} />
                                                </TableCellRow>
                                                <TableCellRow>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            fileSizeInMB.current = fileSizeInMB.current - file.filedata.size / (1024 * 1024);
                                                            fileCount.current = fileCount.current - 1;
                                                            remove(index);
                                                        }}
                                                    >
                                                        削除
                                                    </Button>
                                                </TableCellRow>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}
                    <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                        <Button variant="outlined" {...getRootProps()}>
                            新しいファイルを追加
                        </Button>
                        <Button type="submit" variant="contained" disabled={processing}>
                            アップロードを実行
                        </Button>
                    </Stack>
                </Box>
                <CommonDialog
                    title={digProp.title}
                    message={digProp.message}
                    buttonType={digProp.buttonType}
                    open={digOpen}
                    onAccept={() => ''} //OK時の処理など
                    onClose={digProp.onClose}
                />
            </form>
        </FormProvider>
    );
};
