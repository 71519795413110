import ApiClient from '../services/ApiClient';

class ChangePasswordService {
    changepass(login_id, login_type, currentpassword, newpassword) {
        return ApiClient.post(
            'api/changepass/update',
            (response) => {
                console.log(response);
                if (response.token) {
                    // sessionStorage.setItem('user', JSON.stringify(response));
                    let cookies = '';
                    let expire = '';
                    let period = '';
                    //Cookieの保存名と値を指定
                    cookies = 'webediuser=' + JSON.stringify(response) + ';';
                    //Cookieを保存するパスを指定
                    cookies += 'path=/ ;';
                    //Cookieを保存する期間を指定
                    period = 1; //保存日数
                    expire = new Date();
                    expire.setTime(expire.getTime() + 1000 * 3600 * 24 * period);
                    expire.toUTCString();
                    cookies += 'expires=' + expire + ';';
                    //Cookieを保存する
                    document.cookie = cookies;
                }
                return response;
            },
            {
                // postの場合は「params:」は不要
                login_id: login_id,
                login_type: login_type,
                currentpassword: currentpassword,
                newpassword: newpassword,
            },
        );
    }
}

export default new ChangePasswordService();
