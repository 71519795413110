import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Link, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioUploadItems } from '../../components/radio/RadioUploadItems';
import { RadioUploadStatus } from '../../components/radio/RadioUploadStatus';
import { PAGE_NAME, ROUTE_PATH } from '../../constants';
import companylist from '../../data/shippingCompanyCode.json';
import AcceptDataUploadService from '../../services/AcceptDataUpload.service';
import AuthService from '../../services/auth.service';

export const AcceptDataUploadHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.ACEPPT_UPLOAD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                注文確認データのアップロードを行います。
                <br />
                データの使用について、よくご確認の上、操作を行ってください
            </Typography>
        </>
    );
};

//メインページ
export const AcceptDataUpload = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<AcceptDataUploadHeader />);
    }, []);

    // 入力項目の初期値設定
    const useFormMethods = useForm({
        defaultValues: {
            uploadstatus: '05', //実行後のステータス
            uploadtype: '01', //アップロード形式
            filedata: null, //ファイル選択
            comment: '', //コメント
        },
    });

    const [errors, setErrors] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [getfile, setGetFile] = useState(false);
    const { handleSubmit, setValue } = useFormMethods;
    const [digOpen, setDigOpen] = useState(false);
    //ダイアログ内の表示内容
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });

    const onDrop = (acceptedFiles) => {
        // エラーが出ても取得をしたら true にし、メッセージなどを表示する

        // ファイル情報設定
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const resultData = reader.result;
                const data = resultData.split('\r\n').map((row) => row.split('\t'));
                setValue('filedata', data);
            };

            //選択したファイル内のテキスト内容取得
            reader.readAsText(file);
        });

        // ファイル名を取得し表示する
        if (acceptedFiles.length > 0) {
            setGetFile(true);
            setFileName(<Typography sx={{ color: 'gray', mt: 1, ml: 2 }}>{acceptedFiles[0].name}</Typography>);
        }
    };

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        //選択できるファイル種類
        accept: { 'text/csv': ['.txt'] },
        // 最大で選択できるファイル数（1）
        maxFiles: 1,
    });

    //エラー内容を取得し表示する onDrop時
    useMemo(() => {
        if (fileRejections.length > 0) {
            setGetFile(true);
            return setErrors(<Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'ファイルは１つだけ選択してください。'}</Typography>);
        }
        return setErrors(null);
    }, [fileRejections]);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const submit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        let fileDatas = [];
        setErrors(null);
        if (data.filedata === null) {
            setGetFile(true);
            setErrors(<Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'ファイルを選択してください。'}</Typography>);
            submitProcessing.current = false;
            setProcessing(false);
        } else {
            //正規表現　数字以外
            const intger = /^[0-9]*$/;
            if (data.uploadtype === '01') {
                if (data.uploadstatus === '05') {
                    //金額確定　必須項目 "order_no", "line_no", "unit_price", "order_status"
                    for (let i = 0; i < data.filedata.length; i++) {
                        if (data.filedata[i].length !== 3) {
                            setErrors(
                                <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>
                                    {'[' + (i + 1) + '行目]が選択したアップロード形式と項目数が違います。'}
                                </Typography>,
                            );

                            break;
                        } else if (data.filedata[i][0] === '' || data.filedata[i][1] === '' || data.filedata[i][2] === '') {
                            setErrors(
                                <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'[' + (i + 1) + '行目]必須項目が入力されていません。'}</Typography>,
                            );

                            break;
                        } else if (!intger.test(data.filedata[i][0]) || !intger.test(data.filedata[i][1]) || !intger.test(data.filedata[i][2])) {
                            setErrors(
                                <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'[' + (i + 1) + '行目]必須項目の入力形式が違います。'}</Typography>,
                            );

                            break;
                        } else {
                            fileDatas.push({
                                file_no: String(i + 1),
                                order_no: data.filedata[i][0],
                                line_no: data.filedata[i][1],
                                unit_price: data.filedata[i][2],
                                scheduled_delivery_date: '',
                                notifications: '',
                                shipping_company_code: '',
                                number_of_pieces: '',
                                destination_number: '',
                            });
                        }
                    }
                }
                if (data.uploadstatus === '06') {
                    // 計上完了 必須項目 "order_no", "order_status"
                    for (let i = 0; i < data.filedata.length; i++) {
                        if (data.filedata[i].length !== 1) {
                            setErrors(
                                <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>
                                    {'[' + (i + 1) + '行目]選択したアップロード形式と項目数が違います。'}
                                </Typography>,
                            );

                            break;
                        } else if (data.filedata[i][0] === '' || !intger.test(data.filedata[i][0])) {
                            setErrors(
                                <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'[' + (i + 1) + '行目]注文番号の入力形式が違います。'}</Typography>,
                            );

                            break;
                        } else {
                            fileDatas.push({
                                file_no: String(i + 1),
                                order_no: data.filedata[i][0],
                                line_no: '',
                                unit_price: '',
                                scheduled_delivery_date: '',
                                notifications: '',
                                shipping_company_code: '',
                                number_of_pieces: '',
                                destination_number: '',
                            });
                        }
                    }
                }
            }

            if (data.uploadtype === '02') {
                // すべての項目 "order_no", "line_no", "unit_price", "scheduled_delivery_date", "notifications",
                // "shipping_company_code", "number_of_pieces", "destination_number", "order_status"
                for (let i = 0; i < data.filedata.length; i++) {
                    if (data.filedata[i].length !== 8) {
                        setErrors(
                            <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>
                                {'[' + (i + 1) + '行目]選択したアップロード形式と項目数が違います。'}
                            </Typography>,
                        );

                        break;
                    } else if (data.filedata[i][0] === '' || data.filedata[i][1] === '' || data.filedata[i][2] === '') {
                        setErrors(
                            <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'[' + (i + 1) + '行目]必須項目が入力されていません。'}</Typography>,
                        );

                        break;
                    } else if (!intger.test(data.filedata[i][0]) || !intger.test(data.filedata[i][1]) || !intger.test(data.filedata[i][2])) {
                        setErrors(
                            <Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'[' + (i + 1) + '行目]必須項目の入力形式が違います。'}</Typography>,
                        );

                        break;
                    } else {
                        fileDatas.push({
                            file_no: String(i + 1),
                            order_no: data.filedata[i][0],
                            line_no: data.filedata[i][1],
                            unit_price: data.filedata[i][2],
                            scheduled_delivery_date: data.filedata[i][3],
                            notifications: data.filedata[i][4],
                            shipping_company_code: getCompanyIdByCompanyName(data.filedata[i][5]),
                            number_of_pieces: data.filedata[i][6],
                            destination_number: data.filedata[i][7],
                        });
                    }
                }
            }

            const sortData = (fileDatas) => {
                // リストのソートを行う
                if (fileDatas === null || fileDatas.length === 0) return '';
                fileDatas.sort((a, b) => {
                    if (a.order_no < b.order_no) {
                        return -1;
                    }
                    if (a.order_no > b.order_no) {
                        return 1;
                    }
                    if (a.line_no !== '') {
                        if (a.line_no < b.line_no) {
                            return -1;
                        }
                        if (a.line_no > b.line_no) {
                            return 1;
                        }
                    }
                    return 0;
                });
                return fileDatas;
            };

            //取得したデータを並び替える
            const datasSort = sortData(fileDatas);
            let resultList = [];
            if (datasSort.length === 1) resultList.push(datasSort[0]);
            if (data.uploadtype === '02' || data.uploadstatus === '05') {
                for (let i = 1; i < datasSort.length; i++) {
                    if (datasSort[i].order_no === datasSort[i - 1].order_no && datasSort[i].line_no === datasSort[i - 1].line_no) {
                        setErrors(<Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'同一の入力内容が存在しています。'}</Typography>);
                        break;
                    }
                    resultList.push(datasSort[i - 1]);
                    if (i === datasSort.length - 1) {
                        resultList.push(datasSort[i]);
                    }
                }
            } else {
                for (let i = 1; i < datasSort.length; i++) {
                    if (datasSort[i].order_no === datasSort[i - 1].order_no) {
                        setErrors(<Typography sx={{ color: 'red', mt: 1, ml: 2 }}>{'同一の入力内容が存在しています。'}</Typography>);
                        break;
                    }
                    resultList.push(datasSort[i - 1]);
                    if (i === datasSort.length - 1) {
                        resultList.push(datasSort[i]);
                    }
                }
            }

            if (resultList.length === data.filedata.length && fileDatas.length === data.filedata.length) {
                //注文番号ごとに件数を出す
                const count = datasSort.reduce((result, current) => {
                    const element = result.find((value) => value.order_no === current.order_no);
                    if (element) {
                        element.count++;
                    } else {
                        result.push({
                            order_no: current.order_no,
                            count: 1,
                        });
                    }
                    return result;
                }, []); //初期値は[]

                AcceptDataUploadService.upload(
                    data.uploadstatus,
                    data.uploadtype,
                    datasSort,
                    data.comment,
                    AuthService.getCurrentUser().loginid,
                    count,
                ).then(
                    (response) => {
                        console.log(response);

                        setDigProp({
                            ...digProp,
                            title: response.title,
                            message: response.message,
                            buttonType: 'detail',
                        });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                    (error) => {
                        setDigProp({ ...digProp, title: error.title, message: error.message, buttonType: 'detail' });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                );
                // setDigProp({ ...digProp });
                // //ダイアログ表示
                // setDigOpen(true);
            } else {
                submitProcessing.current = false;
                setProcessing(false);
            }
        }
    };

    function getCompanyIdByCompanyName(info) {
        if (typeof info === 'string') {
            const company = companylist.find((item) => item.name === info);
            return company ? company.id : '99';
        } else if (typeof info === 'number' || typeof info === 'string') {
            const company = companylist.find((item) => item.id === String(info));
            return company ? company.id : '99';
        } else {
            return '99';
        }
    }

    const navigate = useNavigate();
    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            m: 2,
                            gap: 2,
                        }}
                    >
                        <RadioUploadStatus name={'uploadstatus'} label={'実行後のステータス'} />
                        <RadioUploadItems name={'uploadtype'} label={'アップロード形式'} />

                        <Link
                            sx={{ mt: 2, width: 800 }}
                            href="/pdf/webedi_manual_s.pdf#page=21"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            アップロード形式の詳細についてはこちら
                        </Link>
                    </Box>
                    {/* ファイルアップロード */}
                    <Box sx={{ m: 1 }}>
                        <Typography sx={{ fontSize: 12, color: 'gray' }}>アップロードファイル</Typography>
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                                <Button variant="outlined" component="label" {...getRootProps()}>
                                    ファイルの選択
                                </Button>
                                {!getfile ? (
                                    <Typography sx={{ color: 'gray', mt: 1, ml: 2 }}>ファイルが選択されていません。</Typography>
                                ) : errors !== null ? (
                                    //エラー内容
                                    <>{errors}</>
                                ) : (
                                    //ファイル名
                                    <>{fileName}</>
                                )}
                            </Box>
                            <Box
                                {...getRootProps()}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 222,
                                    borderRadius: '4px',
                                    border: '1px dotted #204FA4;',
                                    backgroundColor: ' rgba(32, 79, 164, 0.04)',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                        opacity: [0.9, 0.8, 0.7],
                                    },
                                    mb: 2,
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <input {...getInputProps()} />
                                <Typography>アップロードしたいファイルをここにドラッグしてください。</Typography>
                            </Box>
                        </Box>
                        <InputTextBase name="comment" placeholder="コメント" />
                    </Box>
                    <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                        <Button type="submit" variant="contained" sx={{ width: 180 }} disabled={processing}>
                            アップロードを実行
                        </Button>
                    </Stack>
                </Paper>
                <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                    <Typography>※実行ボタンは１回だけ押してください。</Typography>
                </Stack>
            </form>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => navigate('../' + ROUTE_PATH.ACEPPT_UPLOAD_LOG)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </FormProvider>
    );
};
