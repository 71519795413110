/*
 *注文ステータス選択
 */
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { JOB_CAT, LOGIN_TYPE } from '../../constants';
import AcceptStatus from '../../data/AcceptStatus.json';
import AuthService from '../../services/auth.service';

export const SelectOrderStatus = ({ name, sx, value, setOrderState }) => {
    const { control } = useFormContext();
    const [selectValue, setSelectValue] = useState(value);
    const statusListSupplier = ['01', '03', '04', '05', '06']; //仕入先選択可能ステータス
    const statusListAdmin = ['03', '06', '07']; //サンベック選択可能ステータス

    //仕入先の選択リスト
    const itemListSupplier = AcceptStatus.filter((item) => {
        if (statusListSupplier.includes(item.id)) {
            return item;
        }
        return null;
    });
    //サンベックの選択リスト
    const itemListAdmin = AcceptStatus.filter((item) => {
        if (statusListAdmin.includes(item.id)) {
            const jobcat = AuthService.getCurrentUser().jobcat;
            if (item.id === '03' || item.id === '06') return item;
            if (item.id === '07' && (jobcat === JOB_CAT.ADMIN || jobcat === JOB_CAT.PAYABLE)) {
                return item;
            }
        }
        return null;
    });

    const handleChange = (event) => {
        setSelectValue(event.target.value);
        setOrderState(event.target.value);
    };

    useEffect(() => {
        // value プロパティが変更されたら selectValue ステートを更新する
        setSelectValue(value);
        setOrderState(value);
    }, [value]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl key={selectValue} variant="standard" sx={{ ...sx, minWidth: 240 }} fullWidth error={errors.select ? true : false}>
                    <InputLabel shrink>注文ステータス</InputLabel>
                    <Select labelId="select-label" id="status" defaultValue={value} value={selectValue} {...field} onChange={handleChange}>
                        {AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER &&
                            itemListSupplier.map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN &&
                            itemListAdmin.map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                    </Select>
                    <FormHelperText>{errors.status?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
