import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioGroupSearchOrderLogin } from '../../components/radio/RadioGroupSearchOrderLogin';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { MESSAGE, PAGE_NAME } from '../../constants';
import LoginMainteService from '../../services/LoginMainte.service';
import { LoginResult } from './LoginMainteResult';

//ヘッダー
export const LoginMainteHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.LOGINMAINTE}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                ログインIDの一覧を表示しています。
                <br />
                変更・削除を行う場合は、一覧表示からログインIDを選択してください。
                <br />
                新規登録の場合は、「新規登録はこちら」を押してください。
            </Typography>
        </>
    );
};

//メインページ
export const LoginMainte = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<LoginMainteHeader />);
    }, []);

    //初期処理
    useEffect(() => {
        //検索結果がパラメータとして渡されている場合は検索結果とともに表示する
        //検索条件設定
        if (location.state !== null && location.state.formData !== null) {
            setValue('loginid', location.state.formData.loginid);
            setValue('name', location.state.formData.name);
            setValue('group', location.state.formData.group);
            setValue('searchorderlogin', location.state.formData.searchorderlogin);
        }
        //検索結果設定　戻るボタン押下時
        if (location.state !== null && location.state.dataList !== null && location.state.type === 'return') {
            //検索前に新規登録ボタンを押下し新規登録画面で戻るボタンを押下した場合は処理を行わない
            if (location.state.dataList !== '') {
                setLoginIDList(location.state.dataList);
                setVisible(true);
            }
        }
        //変更・削除ボタン押下時
        if (location.state !== null) {
            if (location.state.type === 'update' || location.state.type === 'delete') {
                LoginMainteService.search(
                    location.state.formData.loginid,
                    location.state.formData.name,
                    location.state.formData.group,
                    location.state.formData.searchorderlogin,
                ).then(
                    (response) => {
                        //検索結果データ取得成功
                        console.log('getData success');
                        console.log(response);
                        //データなしの場合はメッセージ表示
                        if (response.length === 0) {
                            console.log('データなし');
                            setIsNoData(true);
                        } else {
                            //画面下に検索結果を表示する
                            setLoginIDList(response);
                            setVisible(true);
                        }
                    },
                    (error) => {
                        //検索結果データ取得エラー
                        console.log('getData error');
                    },
                );
            }
        }
    }, []);

    //検索ボタン押下時
    const submit = (data) => {
        setformData(data);
        setIsNoData(false);
        setVisible(false);
        LoginMainteService.search(data.loginid, data.name, data.group, data.searchorderlogin).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                    setIsNoData(true);
                } else {
                    //画面下に検索結果を表示する
                    setLoginIDList(response);
                    setVisible(true);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    };
    const useFormMethods = useForm({
        defaultValues: {
            loginid: '',
            name: '',
            group: '',
            searchorderlogin: '01',
        },
    });
    const { handleSubmit, setValue } = useFormMethods;
    const [formData, setformData] = useState('');
    const [visible, setVisible] = useState(false);
    const [isNoData, setIsNoData] = useState(false);
    const [loginIDList, setLoginIDList] = useState('');

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <InputTextBase placeholder="ログインID" name="loginid" maxLength={13} sx={{ mt: 2, ml: 2, width: 400, minWidth: 150 }} />
                            <InputTextBase placeholder="氏名" name="name" maxLength={13} fullWidth sx={{ mt: 2, mx: 2, minWidth: 150 }} />
                        </Box>

                        <SelectSearchGroup name="group" label="営業所" fullWidth="false" sx={{ m: 2 }} />
                        <Box sx={{ ml: 2 }}>
                            <RadioGroupSearchOrderLogin name="searchorderlogin" label="検索結果表示順" />
                        </Box>
                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button
                                // variant="outlined"
                                onClick={() => {
                                    navigate('regist', {
                                        state: {
                                            formData: formData,
                                            dataList: loginIDList,
                                        },
                                    });
                                }}
                            >
                                新規登録はこちら
                            </Button>
                            <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                検索
                            </Button>
                        </Stack>
                    </Paper>
                    {visible && (
                        <>
                            <Box sx={{ pt: 2 }}>
                                {/* 検索結果 */}
                                <LoginResult rows={loginIDList} formData={formData} />
                            </Box>
                        </>
                    )}
                    {isNoData && (
                        <Stack my={2} mr={2} direction="row" spacing={1}>
                            {/* エラーメッセージ */}
                            <Typography>{MESSAGE.NO_DATA_LOGINID}</Typography>
                        </Stack>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
