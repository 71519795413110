/*
 *検索種別選択（注文日）
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import searchDateType from '../../data/searchDateType.json';

export const RadioSearchTypeDate = ({ name, sx }) => {
    const { control } = useFormContext();
    const [, setValue] = useState('1');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="1"
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                return (
                    <FormControl {...field} error={fieldState.error ? true : false}>
                        <RadioGroup
                            sx={sx}
                            row
                            aria-labelledby="radio-group-label"
                            name={field.name}
                            onChange={handleChange}
                            value={field.value === undefined ? '' : field.value}
                        >
                            {searchDateType.map((data, index) => (
                                <FormControlLabel key={index} value={data.id} control={<Radio />} label={data.name} />
                            ))}
                        </RadioGroup>
                        <FormHelperText>{errorMessage}</FormHelperText>
                    </FormControl>
                );
            }}
        />
    );
};
