import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ja } from 'date-fns/locale';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    // top: 0,
                    // legend: {
                    //     display: 'none',
                    // },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    display: 'block',
                },
            },
        },
    },
});
const validationRules = {
    applicationDate: {
        validate: (val) => {
            if (val === null) return true;
            if (typeof val === 'undefined') {
                return true;
            } else if (Number.isNaN(val.getDate())) {
                return '日付を正しく入力してください。';
            }
            return true;
        },
    },
};

export const DatePickerScheduledDeliveryDate = ({ value, sx, setScheduledDate }) => {
    const { control } = useFormContext();
    const onChangeHandle = (event) => {
        setScheduledDate(event);
    };

    return (
        <Controller
            name="scheduledDeliveryDate"
            control={control}
            rules={validationRules.applicationDate}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                return (
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={ja}
                            localeText={{
                                previousMonth: '前月',
                                nextMonth: '次月',
                            }}
                        >
                            <DatePicker
                                {...field}
                                sx={sx}
                                label="予定納品日"
                                variant="inline"
                                inputVariant="standard"
                                format="yyyy/MM/dd"
                                value={value}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        fullWidth: true,
                                        InputLabelProps: { shrink: true },
                                    },
                                }}
                                onChange={onChangeHandle}
                                error={!!errorMessage}
                                helperText={errorMessage}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                );
            }}
        />
    );
};
