import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TableCell, TableHead } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { SwitchMailReceive } from '../../components/switch/SwitchMailReceive';
import { TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { PAGE_NAME } from '../../constants';
import MailChangeService from '../../services/MailChange.service';
import AuthService from '../../services/auth.service';

//ヘッダー
export const AdminMailChangeHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.CHANGEMAILADDRES}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>連絡先メールアドレスと受信する通知の設定ができます。</Typography>
        </>
    );
};

export const AdminMailChange = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<AdminMailChangeHeader />);
    }, []);

    const useFormMethods = useForm({
        defaultValues: {
            mail: '',
            orderRecive: true,
            uploadRecive: true,
        },
    });
    const { handleSubmit, setValue } = useFormMethods;
    const [digOpen, setDigOpen] = useState(false);
    //ダイアログ内の表示内容
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });

    //画面遷移時確認ダイアログ
    const handleBeforeUnload = (e) => {
        // ページ遷移やリロードのキャンセル
        e.preventDefault();
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const submit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        console.log(data);

        MailChangeService.adminRegist(
            data.mail,
            data.orderRecive,
            data.uploadRecive,
            AuthService.getCurrentUser().loginid,
            AuthService.getCurrentUser().name,
        ).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);

                setDigProp({
                    ...digProp,
                    message: response.message,
                });
                //ダイアログ表示
                setDigOpen(true);
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    //メールアドレスを初期表示する
    useEffect(() => {
        MailChangeService.adminSeach(AuthService.getCurrentUser().loginid).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);

                if (response.mail === '' || response.mail === null) {
                    //メールアドレスが設定されていなかったら
                    //空白を指定
                    setValue('mail', '');
                    //jobcatによってtrue　false　指定
                    if (AuthService.getCurrentUser().jobcat === '1' || AuthService.getCurrentUser().jobcat === '2') {
                        //管理者　営業
                        setValue('orderRecive', false);
                        setValue('uploadRecive', false);
                    } else {
                        //事務　買掛
                        setValue('orderRecive', true);
                        setValue('uploadRecive', true);
                    }
                } else {
                    //メールアドレスが設定していたら
                    //responseの値を登録
                    setValue('mail', response.mail);
                    setValue('orderRecive', response.orderRecive);
                    setValue('uploadRecive', response.uploadRecive);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 2,
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHeaderRow sx={{ display: 'flex' }}>
                                    <TableHead sx={{ width: '70%' }}>メールアドレス</TableHead>
                                    <TableHead sx={{ width: '15%', textAlign: 'left' }}>注文関係の通知</TableHead>
                                    <TableHead sx={{ width: '15%', textAlign: 'left' }}>ファイルアップロード通知</TableHead>
                                </TableHeaderRow>
                                <TableBody>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '70%' }}>
                                            <InputTextBase
                                                name="mail"
                                                pattern={{
                                                    value: /^[\w\-.+_]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: '入力形式がメールアドレスではありません。',
                                                }}
                                                required={'入力必須項目です'}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '15%', textAlign: 'center' }}>
                                            <SwitchMailReceive name="orderRecive" />
                                        </TableCell>
                                        <TableCell sx={{ width: '15%', textAlign: 'center' }}>
                                            <SwitchMailReceive name="uploadRecive" />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <Typography sx={{ mt: 2 }}>※上記以外の通知を解除することはできません。</Typography>
                    <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                        <Button type="submit" variant="contained" sx={{ width: 150 }} disabled={processing}>
                            設定を保存
                        </Button>
                    </Stack>
                </Box>
            </form>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => setDigOpen(false)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </FormProvider>
    );
};
