import React, { useContext, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { headerContext } from '../../components/MainLayout';
import { RadioHeading } from '../../components/radio/RadioHeading';
import { RadioItemSeparator } from '../../components/radio/RadioItemSeparator';
import { RadioState } from '../../components/radio/RadioState';
import { SelectMasterKinds } from '../../components/select/SelectMasterKinds';
import { MESSAGE, PAGE_NAME } from '../../constants';
import MasterDownloadservice from '../../services/MasterDownload.service';

//ヘッダー
export const MasterDownloadHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.MASTERDOWNLOAD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>ダウンロードを行う条件を指定してください。</Typography>
        </>
    );
};

//メインページ
export const MasterDownload = () => {
    const setHeader = useContext(headerContext);
    const [disable, setDisable] = useState(true);
    const [selectValue, setSelectValue] = useState('01');
    const [isNoData, setIsNoData] = useState(false);
    const useFormMethods = useForm({
        defaultValues: {
            msttype: '01',
            status: '9',
            titleheader: '0',
            itemseparator: '0',
        },
    });
    const { handleSubmit } = useFormMethods;

    useLayoutEffect(() => {
        setHeader(<MasterDownloadHeader />);
    }, []);

    const submit = (data) => {
        setIsNoData(false);
        // ファイル名設定
        let fileName = '';
        switch (selectValue) {
            case '01':
                fileName = 'ログイン_';
                break;
            case '02':
                fileName = '仕入先_';
                break;
            case '03':
                fileName = '得意先_';
                break;
            default:
        }
        fileName = fileName + expfnc.datetostr_filename(new Date(), true);

        //マスタデータ取得
        MasterDownloadservice.masterDownload(selectValue, data.status).then(
            (response) => {
                if (response.length > 0) {
                    let csvfile = expfnc.json2csv(response, Number(data.titleheader), Number(data.itemseparator));
                    expfnc.fileDownload(fileName, csvfile);
                } else {
                    setIsNoData(true);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    };

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ mt: 2, mx: 2 }}>
                            <SelectMasterKinds
                                name="msttype"
                                fullWidth
                                label="マスタの種類"
                                setDisable={setDisable}
                                setSelectValue={setSelectValue}
                                selectValue={selectValue}
                            />
                        </Box>

                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    borderBottom: 0,
                                    paddingLeft: 3,
                                    paddingTop: 3,
                                }}
                            >
                                <RadioState name="status" isDisabled={disable} />
                            </Box>
                            <Box sx={{ borderBottom: 0, paddingLeft: 50, paddingTop: 3 }}>
                                <RadioHeading />
                            </Box>
                        </Box>
                        <Box sx={{ borderBottom: 0, paddingLeft: 3, paddingTop: 3 }}>
                            <RadioItemSeparator />
                        </Box>
                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button type="submit" variant="contained" sx={{ width: 150 }}>
                                ダウンロード
                            </Button>
                        </Stack>
                    </Paper>
                    {isNoData && (
                        <Stack my={2} mr={2} direction="row" spacing={1}>
                            <Typography>{MESSAGE.NO_DATA}</Typography>
                        </Stack>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
