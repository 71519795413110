/*
 *見出し行
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioHeading = (props) => {
    const { control } = useFormContext();
    const [selectValue, setSelectValue] = useState('0');
    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    };
    return (
        <Controller
            name="titleheader"
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.titleheader ? true : false}>
                    <FormLabel>見出し行</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="radio-group-label"
                        name="titleheader"
                        defaultValue="1"
                        value={selectValue}
                        onChange={handleSelectChange}
                    >
                        <FormControlLabel value="0" control={<Radio />} label="なし" />
                        <FormControlLabel value="1" control={<Radio />} label="あり" />
                    </RadioGroup>
                    <FormHelperText>{errors.titleheader?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
