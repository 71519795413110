import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Header } from './Header';
import { SideMenu } from './SideMenu';

export const headerContext = React.createContext();

export const totalContext = React.createContext();
export const detailContext = React.createContext();
export const resultContext = React.createContext();
export const orderContext = React.createContext();

export const MainLayout = () => {
    const [header, setHeader] = useState();
    const [total, setTotal] = useState(0); //注文状況詳細用：合計金額
    const [detail, setDetail] = useState([]); //注文状況詳細用
    const [order, setOrder] = useState({ value: '' }); //
    const [result, setRresult] = useState({ orderState: '', scheduledDate: '' });

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <headerContext.Provider value={setHeader}>
            <detailContext.Provider value={[total, setTotal]}>
                <totalContext.Provider value={[detail, setDetail]}>
                    <resultContext.Provider value={[result, setRresult]}>
                        <orderContext.Provider value={[order, setOrder]}>
                            <Box
                                component="main"
                                sx={{
                                    backgroundColor: '#F4F8FE',
                                    // backgroundColor: (theme) =>
                                    //     theme.palette.mode === 'light'
                                    //         ? theme.palette.grey[100]
                                    //         : theme.palette.grey[900],
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                }}
                            >
                                {/* ヘッダー */}
                                <Header open={open} toggleDrawer={toggleDrawer}>
                                    {header}
                                </Header>

                                {/* サイドメニュー */}
                                <SideMenu open={open} toggleDrawer={toggleDrawer} />

                                {/* メインコンテンツ */}
                                <Box sx={{ ml: open === true ? 36 : 6, mr: 4 }}>
                                    <Grid container spacing={2} sx={{ mx: 1, mt: 0.1 }}>
                                        <Grid item xs={12}>
                                            <Outlet />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </orderContext.Provider>
                    </resultContext.Provider>
                </totalContext.Provider>
            </detailContext.Provider>
        </headerContext.Provider>
    );
};
