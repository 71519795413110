import muiTableCell from '@mui/material/TableCell';
import muiTableHead from '@mui/material/TableHead';
import muiTableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

/**
 * 検索結果等で使用するテーブルヘッダー（横並び）
 */
export const TableHeaderRow = styled(muiTableHead)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    background: '#F9F9F9',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'left',
    height: '53px',
}));

/**
 * 新規登録等で使用するテーブルヘッダー（縦並び）
 */
export const TableHead = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    background: '#F9F9F9',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'left',
    height: '53px',
}));

/**
 * テーブル行
 */
export const TableRow = styled(muiTableRow)({
    borderBottom: 'solid',
    borderBottomColor: grey[200],
    borderBottomWidth: '1px',
});

/**
 * テーブルセル（横並び）
 */
export const TableCellRow = styled(muiTableCell)({
    height: '53px',
    borderRight: 'solid',
    borderRightColor: grey[200],
    borderRightWidth: '1px',
});

/**
 * テーブルセル（縦並び）
 */
export const TableCell = styled(muiTableCell)({
    padding: 5,
});

// 注文詳細画面
/**
 * ヘッダ部で使用するTableRow
 */
export const TableRowAccept = styled(muiTableRow)({
    border: 'none',
});

/**
 * 注文合計金額
 */
export const TableRowAcceptTotal = styled(muiTableRow)({
    border: 'solid',
    borderColor: grey[200],
    borderWidth: '1px',
});
