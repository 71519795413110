import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { detailContext, orderContext, totalContext } from '../../components/MainLayout';
import { TableCell, TableHead, TableRow } from '../../components/table/TableStyled';
import { ACCEPT_STATUS, JOB_CAT, LOGIN_TYPE, ROUTE_PATH, UNABLE_EDIT_FLG } from '../../constants';
import acceptPrintStatus from '../../data/AcceptPrintStatus.json';
import acceptStatus from '../../data/AcceptStatus.json';
import AcceptService from '../../services/Accept.service';
import AuthService from '../../services/auth.service';
import { AcceptDelivery } from './AcceptDelivery';
import { AcceptInfo } from './AcceptInfo';

const RowTop = styled(TableRow)({
    border: 'solid',
    borderColor: grey[200],
    borderWidth: '1px',
});

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    backgroundColor: '#F4F8FE',
    position: 'absolute',
    top: '60px',
    left: '80px',
    right: '80px',
    bottom: '60px',
    overflow: 'auto',
};

export const AcceptDetailModal = (props) => {
    const [order] = useContext(orderContext);
    const [total] = useContext(totalContext);
    const [detail] = useContext(detailContext);
    const navigate = useNavigate();
    const [editflg, setEditflg] = useState(false);
    const [fileUrl1, setFileUrl1] = useState(null);
    const [fileUrl2, setFileUrl2] = useState(null);
    const [fileUrl3, setFileUrl3] = useState(null);
    const [fileUrl4, setFileUrl4] = useState(null);
    const [fileUrl5, setFileUrl5] = useState(null);

    const openFile = async () => {
        try {
            const fileUrl1 = await AcceptService.getFileattached(order.order_no, '1', expfnc.getFileName(order.file1));
            const fileUrl2 = await AcceptService.getFileattached(order.order_no, '2', expfnc.getFileName(order.file2));
            const fileUrl3 = await AcceptService.getFileattached(order.order_no, '3', expfnc.getFileName(order.file3));
            const fileUrl4 = await AcceptService.getFileattached(order.order_no, '4', expfnc.getFileName(order.file4));
            const fileUrl5 = await AcceptService.getFileattached(order.order_no, '5', expfnc.getFileName(order.file5));
            setFileUrl1(fileUrl1);
            setFileUrl2(fileUrl2);
            setFileUrl3(fileUrl3);
            setFileUrl4(fileUrl4);
            setFileUrl5(fileUrl5);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        //編集ボタン制御
        let authtype = AuthService.getCurrentUser().logintype;
        let jobcat = AuthService.getCurrentUser().jobcat;
        //編集可能の場合は直接編集画面へ遷移するため、仕入先でモーダル表示した場合はボタン押下不可
        if (props.editflg !== void 0 || props.editflg === false || order.unable_edit_flg === UNABLE_EDIT_FLG.LOCK) {
            //編集フラグが設定されてる場合は強制的に編集不可
            setEditflg(false);
        } else {
            if (
                authtype === LOGIN_TYPE.ADMIN &&
                (((jobcat === JOB_CAT.ADMIN || jobcat === JOB_CAT.PAYABLE) &&
                    (order.order_status === ACCEPT_STATUS.ACCOUNTING || order.order_status === ACCEPT_STATUS.PROXY)) ||
                    (jobcat === JOB_CAT.OFFICE && order.order_status === ACCEPT_STATUS.ACCOUNTING))
            ) {
                //サンベック
                //計上完了と代理更新中
                //代理更新中の場合は買掛、管理者のみ押下可
                setEditflg(true);
            }
        }
        openFile();
    }, []);

    const edit = () => {
        let fieldList = [];
        detail.map((rowitem) =>
            fieldList.push({
                lin_no: rowitem.lin_no,
                product_name: rowitem.product_name,
                standard: rowitem.standard,
                order_quantity: rowitem.order_quantity,
                unit: rowitem.unit,
                unit_price: rowitem.unit_price,
            }),
        );

        navigate('../' + ROUTE_PATH.ACCEPT_DETAIL, {
            state: {
                order: props.orderData,
                detail: detail,
                fields: fieldList,
                total: total,
                formData: props.formData,
            },
        });
    };

    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box component="main" sx={{ m: 5 }}>
                                <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                    {/* 編集ボタン押下で編集画面へ遷移する */}
                                    <Button
                                        variant="outlined"
                                        sx={{ width: 160 }}
                                        disabled={!editflg}
                                        onClick={() => {
                                            edit();
                                        }}
                                    >
                                        編集
                                    </Button>
                                    {/* モーダル画面を閉じる */}
                                    <Button
                                        variant="outlined"
                                        sx={{ width: 160 }}
                                        onClick={() => {
                                            props.setShowModal(false);
                                        }}
                                    >
                                        閉じる
                                    </Button>
                                </Stack>
                                <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={3}>
                                        {/* 注文ステータス */}
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <RowTop>
                                                        <TableHead sx={{ maxWidth: '80px' }}>注文ステータス</TableHead>
                                                        <TableCell>{expfnc.getJsonData(acceptStatus, order.order_status)}</TableCell>
                                                    </RowTop>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* 注文書発行ステータス */}
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <RowTop>
                                                        <TableHead sx={{ maxWidth: '90px' }}>注文書発行ステータス</TableHead>
                                                        <TableCell>{expfnc.getJsonData(acceptPrintStatus, order.order_issue_status)}</TableCell>
                                                    </RowTop>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* 予定納品日 */}
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <RowTop>
                                                        <TableHead
                                                            sx={{
                                                                maxWidth: '80px',
                                                                py: 0,
                                                            }}
                                                        >
                                                            予定納品日
                                                            <br />
                                                            （希望納品日）
                                                        </TableHead>
                                                        <TableCell>
                                                            {expfnc.datetostr(order.scheduled_delivery_date, true)}
                                                            <br />({expfnc.datetostr(order.desired_delivery_date)})
                                                        </TableCell>
                                                    </RowTop>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* 注文合計金額 */}
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <RowTop>
                                                        <TableHead sx={{ maxWidth: '40px' }}>注文合計金額</TableHead>
                                                        <TableCell>￥{total.toLocaleString()}</TableCell>
                                                    </RowTop>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        {/* 注文情報 */}
                                        <AcceptInfo orderData={order} />

                                        {/* 特記事項 */}
                                        <Card variant="outlined" sx={{ mt: 2, height: '246px' }}>
                                            <Typography gutterBottom sx={{ mt: 1, ml: 1 }} fontWeight={700} fontSize="15px">
                                                特記事項
                                            </Typography>
                                            <Typography sx={{ ml: 1 }}>
                                                {order.notices1}
                                                <br />
                                                {order.notices2}
                                                <br />
                                                {order.notices3}
                                                <br />
                                                {order.notices4}
                                                <br />
                                                {order.notices5}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* 納品先情報 */}
                                        <AcceptDelivery orderData={order} />

                                        {/* 添付ファイル */}
                                        <Card variant="outlined" sx={{ mt: 2 }}>
                                            <Typography gutterBottom sx={{ mt: 1, ml: 1 }} fontWeight={700} fontSize="15px">
                                                添付ファイル
                                            </Typography>
                                            <Typography sx={{ ml: 1 }}>
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    href={fileUrl1}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="always"
                                                    download={expfnc.getFileName(order.file1)}
                                                >
                                                    {expfnc.getFileName(order.file1)}
                                                </Link>
                                                <br />
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    href={fileUrl2}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="always"
                                                    download={expfnc.getFileName(order.file2)}
                                                >
                                                    {expfnc.getFileName(order.file2)}
                                                </Link>
                                                <br />
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    href={fileUrl3}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="always"
                                                    download={expfnc.getFileName(order.file3)}
                                                >
                                                    {expfnc.getFileName(order.file3)}
                                                </Link>
                                                <br />
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    href={fileUrl4}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="always"
                                                    download={expfnc.getFileName(order.file4)}
                                                >
                                                    {expfnc.getFileName(order.file4)}
                                                </Link>
                                                <br />
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    href={fileUrl5}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="always"
                                                    download={expfnc.getFileName(order.file5)}
                                                >
                                                    {expfnc.getFileName(order.file5)}
                                                </Link>
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>

                                {/* 注文内容 */}
                                <Box>
                                    注文内容
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {/* <DataGridBase rows={detail} columns={columns} /> */}
                                        <Table sx={{ minWidth: 650 }} aria-label="result table">
                                            <TableRow>
                                                <TableHead sx={{ width: 70, fontSize: '14px' }}>行番</TableHead>
                                                <TableHead sx={{ fontSize: '14px' }}>
                                                    品名
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>規格</Box>
                                                </TableHead>
                                                <TableHead sx={{ width: 150, fontSize: '14px' }}>
                                                    数量
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>(単位)</Box>
                                                </TableHead>
                                                <TableHead sx={{ width: 150, fontSize: '14px' }}>
                                                    単価
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>(金額)</Box>
                                                </TableHead>
                                            </TableRow>

                                            {detail.map((row, index) => (
                                                <TableRow
                                                    data-cancel_kbn={row.cancel_kbn}
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': {
                                                            border: 0,
                                                        },
                                                        backgroundColor: row.cancel_kbn === '1' ? '#ccc' : 'transparent',
                                                    }}
                                                >
                                                    <TableCell sx={{ borderRight: 'none' }}>{('00' + row.lin_no).slice(-2)}</TableCell>
                                                    <TableCell sx={{ borderRight: 'none' }}>
                                                        {row.product_name}
                                                        <br />
                                                        {row.standard}
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: 'none' }}>
                                                        {row.order_quantity.toLocaleString()}
                                                        <br />({row.unit})
                                                    </TableCell>
                                                    <TableCell sx={{ borderRight: 'none' }}>
                                                        ¥{row.unit_price.toLocaleString()}
                                                        <br />
                                                        (¥
                                                        {(row.unit_price * row.order_quantity).toLocaleString()})
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    </Paper>
                                </Box>
                                {/* 連絡事項 */}
                                <Card variant="outlined" sx={{ mt: 2 }}>
                                    <Typography gutterBottom sx={{ mt: 1, ml: 1 }} fontWeight={700} fontSize="15px">
                                        注文確認に関する連絡事項
                                    </Typography>
                                    <Typography sx={{ ml: 1 }} style={{ whiteSpace: 'pre-line' }}>
                                        {order.notifications}
                                    </Typography>
                                </Card>
                            </Box>
                        </Paper>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
