import ApiClient from './ApiClient';

class InvoiceAcceptService {
    //送り状番号登録用
    searchInvoice(
        datetype,
        datefrom,
        dateto,
        group,
        personname,
        orderno,
        propertyname,
        deliveryname,
        customername,
        suppliercd,
        suppliername,
        senderregist,
    ) {
        return ApiClient.get(
            '/api/invoice/searchInvoice',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    datetype: datetype,
                    datefrom: datefrom,
                    dateto: dateto,
                    group: group,
                    personname: personname,
                    orderno: orderno,
                    propertyname: propertyname,
                    deliveryname: deliveryname,
                    customername: customername,
                    suppliercd: suppliercd,
                    suppliername: suppliername,
                    senderregist: senderregist,
                },
            },
        );
    }

    updateInvoice(data) {
        return ApiClient.post(
            '/api/invoice/updateInvoice',
            (response) => {
                console.log(response);
                return response;
            },
            { updateList: data },
        );
    }
}
export default new InvoiceAcceptService();
