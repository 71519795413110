/*
 *ログイン画面用ログインIDテキスト入力
 */
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const InputTextLoginId = () => {
    const { control } = useFormContext();
    return (
        <ThemeProvider theme={theme}>
            <Controller
                name="loginid"
                control={control}
                defaultValue=""
                rules={{
                    required: '入力必須項目です',
                }}
                render={({ field, formState: { errors } }) => (
                    <TextField
                        {...field}
                        inputProps={{ maxLength: 5, pattern: '^[a-zA-Z0-9_]+$' }}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="ログインID"
                        error={!!errors.loginid}
                        helperText={errors.loginid && errors.loginid.message}
                    />
                )}
            />
        </ThemeProvider>
    );
};
