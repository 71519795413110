import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { LOGIN_TYPE, MESSAGE, PAGE_NAME } from '../../constants';
import fileDownloadService from '../../services/FileDownload.service';
import AuthService from '../../services/auth.service';
import { FileDownloadResult } from './FileDownloadResult';

//ヘッダー
export const FileDownloadHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.FILE_DOWNLOAD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                    <>
                        仕入先がアップロードしたファイルを参照します。
                        <br />
                        表示条件を指定して「検索」ボタンを押してください。
                    </>
                )}
                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER && <>ファイルの一覧を表示しています。</>}
            </Typography>
        </>
    );
};

//メインページ
export const FileDownload = () => {
    const logintype = AuthService.getCurrentUser().logintype;
    const [fileDownloadList, setFileDownloadList] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);

    const today = new Date();
    const defaultToDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const defaultFromDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    const [dateform, setDateForm] = useState(defaultFromDate);
    const [dateto, setDateTo] = useState(defaultToDate);
    const useFormMethods = useForm({
        defaultValues: {
            datefrom: defaultFromDate, //対象日付範囲選択from
            dateto: defaultToDate, //対象日付範囲選択to
            //得意先コード
            //得意先名
            //仕入先コード
            //仕入先名
        },
    });
    const { handleSubmit } = useFormMethods;

    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<FileDownloadHeader />);
    }, []);

    useEffect(() => {
        // if (logintype === LOGIN_TYPE.SUPPLIER) {
        // 仕入先の場合は初期表示時点で検索を行う
        const fetchData = async () => {
            try {
                setIsNoData(false);
                setVisible(false);

                // let suppliercd = AuthService.getCurrentUser().edicd;
                let datafrom = null;
                let datato = null;
                let suppliercd = null;
                if (logintype === LOGIN_TYPE.SUPPLIER) {
                    suppliercd = AuthService.getCurrentUser().edicd;
                } else {
                    datafrom = defaultFromDate.toLocaleDateString('ja-JP');
                    datato = defaultToDate.toLocaleDateString('ja-JP');
                }

                const response = await fileDownloadService.search(datafrom, datato, null, suppliercd, null);
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    const newDataPromises = response.map(async (item) => {
                        const fileUrl = await fileDownloadService.getFileCustomer(item.file_path, item.file_name);

                        return {
                            ...item,
                            fileUrl: fileUrl,
                        };
                    });

                    // Promise.all を使って全ての非同期処理が完了するのを待つ
                    const newData = await Promise.all(newDataPromises);

                    setFileDownloadList(newData);
                    setVisible(true);
                }
            } catch (error) {
                // 検索結果データ取得エラー
                console.log('getData error', error);
            }
        };

        fetchData();
        // }
    }, []);

    const submit = (data) => {
        //サンベックの場合は検索機能が表示される
        //仕入先がアップロードしたものの検索
        const fetchData = async () => {
            try {
                setIsNoData(false);
                setVisible(false);

                let datafrom = null;
                if (data.datefrom != null) {
                    datafrom = data.datefrom.toLocaleDateString('ja-JP');
                }
                let datato = null;
                if (data.dateto != null) {
                    datato = data.dateto.toLocaleDateString('ja-JP');
                }

                const response = await fileDownloadService.search(datafrom, datato, data.group, data.suplliercd, data.suplliername);
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    const newDataPromises = response.map(async (item) => {
                        const fileUrl = await fileDownloadService.getFileCustomer(item.file_path, item.file_name);

                        return {
                            ...item,
                            fileUrl: fileUrl,
                        };
                    });

                    // Promise.all を使って全ての非同期処理が完了するのを待つ
                    const newData = await Promise.all(newDataPromises);

                    setFileDownloadList(newData);
                    setVisible(true);
                }
            } catch (error) {
                // 検索結果データ取得エラー
                console.log('getData error', error);
            }
        };
        fetchData();
    };

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                            <>
                                <Typography align="left" sx={{ flexGrow: 1, mx: 3, paddingTop: 2 }}>
                                    アップロード日時
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                                    <DatePickerBase name="datefrom" value={dateform} />
                                    <Typography align="center" sx={{ flexGrow: 1, mx: 2, mt: 1 }}>
                                        ～
                                    </Typography>
                                    <DatePickerBase name="dateto" value={dateto} />
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                    <SelectSearchGroup name="group" label="営業所" fullWidth="true" sx={{ MarginLeft: 30 }} />
                                    <InputTextBase name="suplliercd" placeholder="仕入先コード" sx={{ ml: 1, paddingRight: 1 }} />
                                    <InputTextBase name="suplliername" placeholder="仕入先名称" sx={{ ml: 1 }} />
                                </Box>
                                <Box>
                                    <Stack my={2} mr={3} direction="row" justifyContent="end" spacing={1}>
                                        <Button type="submit" variant="contained" sx={{ width: 125 }}>
                                            検索
                                        </Button>
                                    </Stack>
                                </Box>
                            </>
                        )}
                    </Paper>
                    {visible && (
                        <>
                            <Box sx={{ pt: 2 }}>
                                <FileDownloadResult rows={fileDownloadList} />
                            </Box>
                        </>
                    )}
                    {isNoData && (
                        <Stack my={2} mr={2} direction="row" spacing={1}>
                            {/* エラーメッセージ */}
                            <Typography>{MESSAGE.FILE_UPLOAD_NO_DATA}</Typography>
                        </Stack>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
