import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const SelectMasterKinds = ({ name, label, sx, setDisable, setSelectValue, selectValue }) => {
    const { control } = useFormContext();

    const handleSelectChange = (event) => {
        if (event.target.value === '01') {
            setDisable(true);
        } else {
            setDisable(false);
        }
        //  setSelectValue(event.target.value);
        setSelectValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="01"
            render={({ field, formState: { errors } }) => (
                <FormControl variant="standard" sx={{ ...sx, minWidth: 240 }} error={errors.select ? true : false} fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select labelId="select-label" id="master" label={label} {...field} value={selectValue} onChange={handleSelectChange}>
                        <MenuItem value="01">ログイン</MenuItem>
                        <MenuItem value="02">仕入先</MenuItem>
                        <MenuItem value="03">得意先</MenuItem>
                    </Select>
                </FormControl>
            )}
        />
    );
};
