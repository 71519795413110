import ApiClient from '../services/ApiClient';

class AuthService {
    login(loginid, password) {
        return ApiClient.post(
            'api/auth/login',
            (response) => {
                console.log(response);
                if (response.token) {
                    // sessionStorage.setItem('user', JSON.stringify(response));
                    let cookies = '';
                    let expire = '';
                    let period = '';
                    //Cookieの保存名と値を指定
                    // let str = JSON.stringify(response)
                    //     .replace(/\u2028/g, '\\u2028')
                    //     .replace(/\u2029/g, '\\u2029');
                    //cookies = 'webediuser=' + JSON.stringify(response) + ';';
                    // cookies = 'webediuser=' + str + ';';
                    cookies = 'webediuser=' + encodeURI(JSON.stringify(response)) + ';';
                    //Cookieを保存するパスを指定
                    cookies += 'path=/ ;';
                    //Cookieを保存する期間を指定
                    period = 1; //保存日数
                    expire = new Date();
                    expire.setTime(expire.getTime() + 1000 * 3600 * 24 * period);
                    expire.toUTCString();
                    cookies += 'expires=' + expire + ';';
                    //Cookieを保存する
                    document.cookie = cookies;
                }
                return response;
            },
            { loginid, password },
        );
    }

    logout() {
        //document.cookie = 'webediuser=; max-age=0';
        document.cookie = 'webediuser=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        // sessionStorage.removeItem('user');
    }

    // register(loginid, email, password) {
    //     return axios.post(API_URL + 'auth/signup', {
    //         loginid,
    //         email,
    //         password,
    //     });
    // }

    getCurrentUser() {
        let cookies = '';
        let cookieArray = [];
        let result = null;

        //Cookieを取得する
        cookies = document.cookie;
        //Cookieを配列に分割してJSONに変換する
        if (cookies) {
            cookieArray = cookies.split(';');

            cookieArray.forEach((data) => {
                data = data.split('=');
                if (data[0].trim() === 'webediuser') {
                    result = data[1];
                }
                // result[data[0]] = JSON.parse(data[1]);
            });
        }
        return JSON.parse(decodeURI(result));

        // return JSON.parse(sessionStorage.getItem('user'));
    }
}

export default new AuthService();
