/**
 * ファイルアップロード履歴検索結果
 */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import SelectFileUploadGroup from '../../components/select/SelectFileUploadGroup';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { JOB_CAT, LOGIN_TYPE, MESSAGE } from '../../constants';
import fileUploadType from '../../data/FileUploadType.json';
import groupjson from '../../data/Group.json';
import fileUploadService from '../../services/FileUpload.service';
import AuthService from '../../services/auth.service';
import FileUploadDeleteDialog from './FileUploadDeleteDialog';

export const FileUploadHistoryResult = (props) => {
    const logintype = AuthService.getCurrentUser().logintype;
    const jobcat = AuthService.getCurrentUser().jobcat;
    const [selectedGroup, setSelectedGroup] = useState('');
    const [visibleRows, setVisibleRows] = useState(props.rows);
    const [filteredRows, setFilteredRows] = useState(props.rows);
    const [countRows, setCountRows] = useState(props.rows);
    const isRowDeleted = (row) => row.delete_flag === '1';
    const allGroups = [...new Set(visibleRows.map((row) => row.group_cd))];
    const [selectedRow, setSelectedRow] = useState();

    const handleDeleteClick = (row) => {
        //ダイアログ内容設定
        setSelectedRow(row);
        // setDigProp({
        //     ...digProp,
        //     onAccept: () => handleOk(row),
        // });

        //ダイアログ表示
        setDigOpen(true);
    };

    const handleOk = (row) => {
        // サーバー側の処理
        const nowdate = new Date();

        fileUploadService.delete(row.id, nowdate).then(
            (response) => {
                // console.log(response);
                // クリックされた行を非活性にする
                const updatedRowData = filteredRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setFilteredRows(updatedRowData);

                const updatedcountRowsData = countRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setCountRows(updatedcountRowsData);
                const updatedVisibleRowsData = visibleRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setVisibleRows(updatedVisibleRowsData);
                //ダイアログOK時処理
                setDigOpen(false); //ダイアログを閉じる
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                setDigOpen(false); //ダイアログを閉じる
            },
        );
    };

    const handleGroupChange = (event) => {
        const newSelectedGroup = event.target.value;
        setSelectedGroup(newSelectedGroup);

        const newFilteredRows = newSelectedGroup
            ? visibleRows.filter((row) => row.group_cd === newSelectedGroup || row.group_cd.trim() === '')
            : visibleRows;
        setFilteredRows(newFilteredRows);
        setCountRows(newFilteredRows); //カウント用
    };

    useEffect(() => {
        // 最初のグループを初期選択状態にする
        if (visibleRows.length > 0 && !selectedGroup) {
            const grouplist = visibleRows.filter((row) => row.group_cd.trim() !== '');
            let fselectgroup = '';
            if (grouplist.length > 0) fselectgroup = grouplist[0].group_cd;

            const newSelectedGroup = fselectgroup;
            setSelectedGroup(newSelectedGroup);

            const newFilteredRows = newSelectedGroup
                ? visibleRows.filter((row) => row.group_cd === newSelectedGroup || row.group_cd.trim() === '')
                : visibleRows;
            setFilteredRows(newFilteredRows);
            setCountRows(newFilteredRows); //カウント用
        }
    }, [visibleRows, selectedGroup]);

    const [digOpen, setDigOpen] = useState(false);
    const digProp = {
        title: '',
        message: MESSAGE.FILE_UPLOAD_DELETE,
        onClose: () => ({}),
    };

    return (
        <div>
            <SelectFileUploadGroup selectedGroup={selectedGroup} onGroupChange={handleGroupChange} allGroups={allGroups} />

            <TableSearchResult rows={countRows} setVisibleRows={setFilteredRows}>
                <TableHeaderRow>
                    <TableRow>
                        <TableHead sx={{ width: 200 }}>アップロード日時</TableHead>
                        {logintype === LOGIN_TYPE.ADMIN && <TableHead sx={{ width: 250 }}>共有先</TableHead>}
                        {logintype === LOGIN_TYPE.SUPPLIER && <TableHead sx={{ width: 200 }}>該当営業所</TableHead>}
                        <TableHead sx={{ width: 300 }}>ファイル</TableHead>
                        <TableHead>コメント</TableHead>
                        <TableHead sx={{ width: 150 }}>削除</TableHead>
                    </TableRow>
                </TableHeaderRow>
                <TableBody>
                    {filteredRows.map((row) => (
                        <TableRow
                            key={row.id}
                            style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}
                            className={isRowDeleted(row) ? 'deleted-row' : ''}
                        >
                            <TableCellRow sx={{ padding: 0 }}>
                                {/* アップロード日時 */}
                                <Box sx={{ ml: 1 }}>
                                    <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>
                                        {expfnc.datetostrformatFileUpDate(row.upload_date)}
                                    </Typography>
                                </Box>
                            </TableCellRow>
                            {logintype === LOGIN_TYPE.ADMIN && (
                                <TableCellRow sx={{ padding: 0 }}>
                                    {/* 共有先 */}
                                    <Box sx={{ ml: 1 }}>
                                        {row.all_customer_flg === '1' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>すべての得意先</Typography>
                                        )}
                                        {row.all_supplier_flg === '1' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>すべての仕入先</Typography>
                                        )}
                                        {row.customer_name !== '' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.customer_name}</Typography>
                                        )}
                                        {row.supplier_name !== '' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.supplier_name}</Typography>
                                        )}
                                    </Box>
                                </TableCellRow>
                            )}
                            {logintype === LOGIN_TYPE.SUPPLIER && (
                                <TableCellRow sx={{ padding: 0 }}>
                                    <Box sx={{ ml: 1 }}>
                                        {row.supplier_name !== '' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>
                                                {expfnc.getJsonData(groupjson, row.group_cd)}
                                            </Typography>
                                        )}
                                        {row.all_supplier_flg === '1' && (
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>すべての仕入先</Typography>
                                        )}
                                    </Box>
                                </TableCellRow>
                            )}
                            <TableCellRow sx={{ padding: 0 }}>
                                {/* ファイル */}
                                <Box sx={{ ml: 1 }}>
                                    {isRowDeleted(row) ? (
                                        <Box display="flex" flexDirection="row">
                                            <Typography style={{ width: '100px', display: 'inline' }}>
                                                {expfnc.getJsonData(fileUploadType, row.file_type) + '：'}
                                            </Typography>
                                            <Typography style={{ color: 'gray' }}>{row.file_name}</Typography>
                                        </Box>
                                    ) : (
                                        <Box display="flex" flexDirection="row">
                                            <Typography style={{ width: '100px', display: 'inline' }}>
                                                {expfnc.getJsonData(fileUploadType, row.file_type)}：
                                            </Typography>

                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'blue' }}>
                                                <Link
                                                    underline="always"
                                                    target="_blank"
                                                    rel="noopener"
                                                    // href={row.file_path + '/' + row.file_name}
                                                    href={row.fileUrl}
                                                    download={row.file_name}
                                                    disabled={isRowDeleted(row)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textAlign: 'left',
                                                        overflowWrap: 'break-word',
                                                        textDecoration: isRowDeleted(row) ? 'none' : 'underline',
                                                    }}
                                                >
                                                    {row.file_name}
                                                </Link>
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TableCellRow>
                            <TableCellRow sx={{ padding: 0 }}>
                                {/* コメント */}
                                <Box sx={{ ml: 1 }}>
                                    <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.comment}</Typography>
                                </Box>
                            </TableCellRow>
                            <TableCellRow align="center" valign="middle" sx={{ padding: 0 }}>
                                {/* 削除 */}
                                {isRowDeleted(row) ? (
                                    <>
                                        <Typography style={{ color: 'gray' }}>削除済み</Typography>
                                        <Typography style={{ color: 'gray', fontSize: '0.8rem' }}>
                                            {new Date(row.update_dt).toLocaleString()}
                                        </Typography>
                                    </>
                                ) : (
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{ width: 95 }}
                                        onClick={() => handleDeleteClick(row)}
                                        disabled={(logintype === LOGIN_TYPE.SUPPLIER && row.all_supplier_flg === '1') || jobcat === JOB_CAT.SALES}
                                    >
                                        削除
                                    </Button>
                                )}
                            </TableCellRow>
                        </TableRow>
                    ))}
                </TableBody>
            </TableSearchResult>
            <FileUploadDeleteDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => handleOk(selectedRow)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </div>
    );
};
