import ApiClient from '../services/ApiClient';

class MasterDownloadservice {
    masterDownload(mst_type, state) {
        return ApiClient.get(
            '/api/MasterDownload/getcsv',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    mst_type: mst_type,
                    state: state,
                },
            },
        );
    }
}
export default new MasterDownloadservice();
