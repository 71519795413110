import React, { useState } from 'react';

import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import * as expfnc from '../../common/commonFunction';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import update from '../../data/updateCategory.json';
import CustomerMstLogModal from './CustomerMstLogModal';
import LoginMstLogModal from './LoginMstLogModal';
import SupplierMstLogModal from './SupplierMstLogModal';

export const MstLogResult = (props) => {
    const [visibleRows, setVisibleRows] = useState(props.data);
    const [resultData, setResultData] = useState();
    const [showLoginModal, setLoginModal] = useState(false);
    const [showCustomerModal, setCustomerModal] = useState(false);
    const [showSupplierModal, setSupplierModal] = useState(false);

    const OpenModalDetail = (row) => {
        setResultData(row);
        if (row.mst_name === 'ログイン') {
            setLoginModal(true);
        } else if (row.mst_name === '得意先') {
            setCustomerModal(true);
        } else if (row.mst_name === '仕入先') {
            setSupplierModal(true);
        }
    };
    return (
        <>
            <div>
                <TableSearchResult rows={props.data} setVisibleRows={setVisibleRows}>
                    <TableHeaderRow>
                        <TableRow>
                            <TableHead sx={{ width: 200 }}>更新日付</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>更新者ID</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>更新者名</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>マスタ名</TableHead>
                            <TableHead sx={{ textAlign: 'left', width: 200 }}>コード</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>名称</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>処理区分</TableHead>
                        </TableRow>
                    </TableHeaderRow>
                    <TableBody>
                        {visibleRows.map((row) => (
                            <TableRow key={[row.update_dt, row.update_id]}>
                                <TableCellRow sx={{ textAlign: 'center' }}>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        underline="always"
                                        onClick={() => {
                                            // モーダル画面表示
                                            //function内でmst_nameによって表示するモーダルを分ける
                                            OpenModalDetail(row);
                                        }}
                                    >
                                        {row.update_dt}
                                    </Link>
                                </TableCellRow>
                                <TableCellRow>{row.update_id}</TableCellRow>
                                <TableCellRow>{row.update_name}</TableCellRow>
                                <TableCellRow>{row.mst_name}</TableCellRow>
                                <TableCellRow>{row.key_code}</TableCellRow>
                                <TableCellRow>{row.key_name}</TableCellRow>
                                <TableCellRow>{expfnc.getJsonData(update, row.update_category)}</TableCellRow>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableSearchResult>
            </div>
            {showLoginModal ? <LoginMstLogModal showModal={showLoginModal} datas={resultData} setShowModal={setLoginModal} /> : <></>}
            {showCustomerModal ? <CustomerMstLogModal showModal={showCustomerModal} datas={resultData} setShowModal={setCustomerModal} /> : <></>}
            {showSupplierModal ? <SupplierMstLogModal showModal={showSupplierModal} datas={resultData} setShowModal={setSupplierModal} /> : <></>}
        </>
    );
};
