/*
 *仕入先選択
 */
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import supplierService from '../../services/supplier.service';

export const SelectSupplier = ({ name, label, fullWidth, sx }) => {
    const { control } = useFormContext();
    const [supplierList, setSupplierList] = useState([]);
    useEffect(() => {
        supplierService.searchAll().then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                setSupplierList(response);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
                <FormControl
                    variant="standard"
                    sx={{ ...sx, minWidth: 240 }}
                    error={errors.select ? true : false}
                    fullWidth={JSON.parse(fullWidth.toLowerCase())}
                    rules={{
                        required: '入力必須項目です',
                    }}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select labelId="select-label" id="supplier" label={label} {...field}>
                        <MenuItem value={'00'}>選択してください。</MenuItem>
                        {supplierList.map((row) => (
                            <MenuItem key={row.supplier_cd} value={row.supplier_cd}>
                                {row.supplier_cd} : {row.supplier_name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors.supplier?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
