/*
 *職種区分選択
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioJobCategory = ({ name }) => {
    const { control } = useFormContext();
    const [, setValue] = useState('1');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="1"
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                return (
                    <FormControl {...field} error={!!errorMessage}>
                        <RadioGroup
                            row
                            aria-labelledby="radio-group-label"
                            name={field.name}
                            onChange={handleChange}
                            value={field.value === undefined ? '1' : field.value}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="管理者" />
                            <FormControlLabel value="2" control={<Radio />} label="営業" />
                            <FormControlLabel value="3" control={<Radio />} label="事務" />
                            <FormControlLabel value="4" control={<Radio />} label="買掛" />
                        </RadioGroup>
                        <FormHelperText>{errorMessage}</FormHelperText>
                    </FormControl>
                );
            }}
        />
    );
};
