import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const SelectMaster = ({ name, label, sx }) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
                <FormControl variant="standard" sx={{ ...sx, minWidth: 240 }} error={errors.select ? true : false} fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select labelId="select-label" id="master" label={label} {...field}>
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'01'}>ログイン</MenuItem>
                        <MenuItem value={'02'}>仕入先</MenuItem>
                        <MenuItem value={'03'}>得意先</MenuItem>
                    </Select>
                    <FormHelperText>{errors.group?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
