import ApiClient from './ApiClient';

class CustomerMainteService {
    search(customer_cd, customer_name) {
        return ApiClient.get(
            'api/CustomerMaint/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    customer_cd: customer_cd,
                    customer_name: customer_name,
                },
            },
        );
    }
    regist(customer_cd, login_id, customer_name, customer_mail1, customer_mail2, status) {
        return ApiClient.post(
            'api/CustomerMaint/regist',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                customer_cd,
                login_id,
                customer_name,
                customer_mail1,
                customer_mail2,
                status,
            },
        );
    }
    update(
        customer_cd,
        login_id,
        password,
        customer_name,
        customer_mail1,
        customer_mail2_type,
        customer_mail2,
        post,
        state,
        city,
        street,
        address,
        building,
        floor,
        tel,
        fax,
        status,
    ) {
        return ApiClient.post(
            'api/CustomerMaint/update',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                customer_cd,
                login_id,
                password,
                customer_name,
                customer_mail1,
                customer_mail2_type,
                customer_mail2,
                post,
                state,
                city,
                street,
                address,
                building,
                floor,
                tel,
                fax,
                status,
            },
        );
    }
    delete(customer_cd) {
        return ApiClient.post(
            'api/CustomerMaint/delete',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                customer_cd,
            },
        );
    }
    mailSeach(customer_cd) {
        return ApiClient.get(
            '/api/CustomerMaint/mailGet',
            (response) => {
                return response;
            },
            {
                params: {
                    customer_cd: customer_cd,
                },
            },
        );
    }

    initpass(customer_cd, password) {
        return ApiClient.post(
            '/api/CustomerMaint/initpass',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                customer_cd,
                password,
            },
        );
    }
}

export default new CustomerMainteService();
