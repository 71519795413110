/**
 * 該当件数表示しないテーブル
 */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { tablePaginationClasses as classes } from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';

import { ROWS_PER_PAGE, ROW_PER_PAGE_OPTIONS } from '../../constants';

const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  `,
);

export const TableResultNotCount = (props) => {
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        const rows = props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        props.setVisibleRows(rows);
    }, [page, rowsPerPage]);

    return (
        <>
            <Box component="main">
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="result table">
                            {props.children}
                        </Table>
                    </TableContainer>
                    <CustomTablePagination
                        labelRowsPerPage="ページごとの表示行数"
                        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                        component="div"
                        count={props.rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            borderTop: 'solid 1px rgba(224, 224, 224, 1)',
                        }}
                    />
                </Paper>
            </Box>
        </>
    );
};
