import ApiClient from '../services/ApiClient';

class LoginMainteService {
    search(login_id, name, group_cd, search_order) {
        return ApiClient.get(
            'api/loginMainte/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    login_id: login_id,
                    name: name,
                    group_cd: group_cd,
                    search_order: search_order,
                },
            },
        );
    }
    regist(login_id, password, name, name_kana, group_cd, tel, fax, email, job_cat, update_id) {
        return ApiClient.post(
            'api/loginMainte/regist',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                login_id,
                password,
                name,
                name_kana,
                group_cd,
                tel,
                fax,
                email,
                job_cat,
                update_id,
            },
        );
    }
    update(login_id, name, name_kana, group_cd, tel, fax, email, job_cat, update_id) {
        return ApiClient.post(
            'api/loginMainte/update',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                login_id,
                name,
                name_kana,
                group_cd,
                tel,
                fax,
                email,
                job_cat,
                update_id,
            },
        );
    }
    delete(login_id, update_id) {
        return ApiClient.post(
            'api/loginMainte/delete',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                login_id,
                update_id,
            },
        );
    }
}

export default new LoginMainteService();
