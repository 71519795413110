import ApiClient from '../services/ApiClient';

class MailChangeService {
    //得意先メールアドレス検索
    customerSeach(customer_cd) {
        return ApiClient.get(
            'api/mailchange/customerSeach',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    customer_cd: customer_cd,
                },
            },
        );
    }

    //得意先メールアドレス登録
    customerRegist(customer_cd, mailList, login_id, name) {
        return ApiClient.post(
            'api/mailchange/customerRegist',
            (response) => {
                console.log(response);

                return response;
            },
            {
                customer_cd: customer_cd,
                mailList: mailList,
                login_id: login_id,
                name: name,
            },
        );
    }

    //仕入先メールアドレス検索
    supplierSeach(supplier_cd) {
        return ApiClient.get(
            'api/mailchange/supplierSeach',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    supplier_cd: supplier_cd,
                },
            },
        );
    }

    //仕入れ先メールアドレス登録
    supplierRegist(supplier_cd, supMailList, login_id, name) {
        return ApiClient.post(
            'api/mailchange/supplierRegist',
            (response) => {
                console.log(response);

                return response;
            },
            {
                supplier_cd: supplier_cd,
                supMailList: supMailList,
                login_id: login_id,
                name: name,
            },
        );
    }

    //サンベックメールアドレス検索
    adminSeach(login_id) {
        return ApiClient.get(
            'api/mailchange/adminSeach',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    login_id: login_id,
                },
            },
        );
    }
    //サンベックメールアドレス登録
    adminRegist(mail, orderRecive, uploadRecive, login_id, name) {
        return ApiClient.post(
            'api/mailchange/adminRegist',
            (response) => {
                console.log(response);

                return response;
            },
            {
                mail: mail,
                orderRecive: orderRecive,
                uploadRecive: uploadRecive,
                login_id: login_id,
                name: name,
            },
        );
    }
}
export default new MailChangeService();
