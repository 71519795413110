import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

function FileUploadDeleteDialog(props) {
    // プロパティの受け取り
    const { title, message, onAccept, onClose, open } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    // 承諾（OK または YES ボタンをクリック）した時
    const handleAccept = () => {
        handleClose();
        onAccept();
    };

    // ダイアログクローズ
    const handleClose = () => {
        setDialogOpen(false);
        onClose();
    };

    // openの値が変化した時
    useEffect(() => setDialogOpen(open), [open]);

    //改行コードで改行をできるようにする
    const Msg = ({ message }) => {
        const texts = message.split(/(\n)/).map((item, index) => {
            return <React.Fragment key={index}>{item.match(/\n/) ? <br /> : item}</React.Fragment>;
        });
        return <div>{texts}</div>;
    };

    return (
        <Dialog open={dialogOpen}>
            <DialogTitle>
                <span>{title}</span>
            </DialogTitle>
            <DialogContent>
                <Msg message={message} />
            </DialogContent>
            <DialogActions>
                <>
                    <Button variant="contained" onClick={handleAccept}>
                        削除を実行
                    </Button>
                    <Button onClick={handleClose}>キャンセル</Button>
                </>
            </DialogActions>
        </Dialog>
    );
}

// プロパティ
FileUploadDeleteDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default FileUploadDeleteDialog;
