import axios from 'axios';

import { API_URL } from '../constants';
import AuthService from './auth.service';

class ApiClient {
    static createInstance() {
        const user = AuthService.getCurrentUser();
        // const user = JSON.parse(sessionStorage.getItem('user'));
        const AuthHeader = user && user.token ? 'Bearer ' + user.token : '';

        const instance = axios.create({
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
                Authorization: AuthHeader,
            },
            // timeout: 20000,
        });
        instance.interceptors.response.use(
            (config) => this.requestSuccess(config),
            (error) => this.requestFailure(error),
        );
        return instance;
    }

    static requestFailure(error) {
        //request失敗した時の処理
        console.log('// REQUEST FAILURE', error);
        switch (error.response?.status) {
            case 401:
                return Promise.reject(error.response?.data);
            case 404:
                return Promise.reject(error.response?.data);
            default:
                return Promise.reject(error.response?.data);
        }
    }
    static requestSuccess(config) {
        //request成功した時の処理
        console.log('// REQUEST SUCCESS', config);
        return config;
    }

    static async get(url, callback, data) {
        const instance = this.createInstance();
        return await instance
            .get(url, data)
            .then((response) => {
                return callback(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    static async getFileAttached(orderno, index, fileName) {
        if (fileName === void 0 || fileName === null || fileName === '') {
            return '';
        }
        try {
            const instance = this.createInstance();
            const response = await instance.get(`api/files/attached/${orderno}/${index}/${fileName}`, { responseType: 'blob' });
            const blob = new Blob([response.data]);
            const url = URL.createObjectURL(blob);
            console.log(url);
            return url;
        } catch (error) {
            console.error('Error fetching file:', error);
            return null;
        }
    }

    static async getFileDownLoad(filePath, fileName) {
        if (filePath === void 0 || filePath === null || filePath === '') {
            return '';
        }
        try {
            const instance = this.createInstance();
            // ファイルパスとファイル名をエンコードする
            const encodedFilePath = encodeURIComponent(filePath);
            const encodedFileName = encodeURIComponent(fileName);
            const response = await instance.get(`api/files/download?filePath=${encodedFilePath}&fileName=${encodedFileName}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data]);
            const url = URL.createObjectURL(blob);
            console.log(url);
            return url;
        } catch (error) {
            console.error('Error fetching file:', error);
            return null;
        }
    }

    static async post(url, callback, data) {
        const instance = this.createInstance();
        return await instance
            .post(url, data)
            .then((response) => {
                //returnして呼び出し元で使えるようにする
                return callback(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    static async getFileOrder(orderno) {
        if (orderno === void 0 || orderno === null || orderno === '') {
            return '';
        }
        try {
            const instance = this.createInstance();
            const response = await instance.get(`api/files/order/${orderno}`, { responseType: 'blob' });
            const blob = new Blob([response.data]);
            const url = URL.createObjectURL(blob);
            return url;
        } catch (error) {
            console.error('Error fetching file:', error);
            return null;
        }
    }
}

export default ApiClient;
