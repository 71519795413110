/*
 *検索画面用ステータスチェックボックス
 */
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, Typography, createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import AcceptPrintStatus from '../../data/AcceptPrintStatus.json';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const CheckBoxSearchPrintStatus = ({ name, sx }) => {
    const { control } = useFormContext();

    const handleCheck = (option, event) => {
        let checklist = [];
        if (checkedValues.includes(event.target.value)) {
            //選択したものがリストにすでにある場合は削除
            checklist = checkedValues.filter((checkedValue) => checkedValue !== event.target.value);
            setCheckedValues(checklist);
        } else {
            //選択したものがリストになければ追加
            checklist = [...checkedValues, event.target.value];
            setCheckedValues(checklist);
        }
        return checklist.join(',');
    };

    const [checkedValues, setCheckedValues] = useState([]);
    const allOnCheck = (event, field) => {
        //すべてを選択する押下時の処理
        let chkList = [];
        for (const key in AcceptPrintStatus) {
            if (!checkedValues.includes(AcceptPrintStatus[key].id)) {
                chkList.push(AcceptPrintStatus[key].id);
            }
        }
        setCheckedValues([...checkedValues, ...chkList]);
        field.onChange([...checkedValues, ...chkList].join(','));
    };
    const allOffCheck = (event, field) => {
        //すべての選択をはずす押下時の処理
        let addList = ['08'];
        if (!checkedValues.includes('08')) {
            setCheckedValues([]);
            field.onChange('');
        } else {
            setCheckedValues(addList);
            field.onChange('08');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <FormGroup sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => {
                        const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography>状態</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                    <FormGroup sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            sx={{ width: 150 }}
                                            onClick={(event) => {
                                                //すべてONにする
                                                allOnCheck(event, field);
                                            }}
                                        >
                                            すべてを選択する
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            sx={{ width: 180, ml: 2 }}
                                            onClick={(event) => {
                                                //すべてOFFにする
                                                allOffCheck(event, field);
                                            }}
                                        >
                                            すべての選択をはずす
                                        </Button>
                                        <FormControlLabel
                                            {...field}
                                            key={99}
                                            control={<Checkbox name={name} />}
                                            label="キャンセルを含む"
                                            value={'08'}
                                            checked={checkedValues.includes('08')}
                                            onChange={(event) => field.onChange(handleCheck({ id: '08' }, event))}
                                            sx={{ ml: 2 }}
                                        />
                                    </FormGroup>
                                    <Box>
                                        {AcceptPrintStatus.map((data, index) => (
                                            <FormControlLabel
                                                {...field}
                                                key={index}
                                                control={<Checkbox name={name} />}
                                                label={data.name}
                                                value={data.id}
                                                checked={checkedValues.includes(data.id)}
                                                onChange={(event) => field.onChange(handleCheck(data, event))}
                                            />
                                        ))}
                                    </Box>
                                    <FormHelperText>{errorMessage}</FormHelperText>
                                </Box>
                            </Box>
                        );
                    }}
                />
            </FormGroup>
        </ThemeProvider>
    );
};
