/**
 * 注文状況検索結果
 */
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import muiTableHead from '@mui/material/TableHead';
import muiTableRow from '@mui/material/TableRow';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { detailContext, orderContext, totalContext } from '../../components/MainLayout';
import { SelectOrderDisp } from '../../components/select/SelectOrderDisp';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { ACCEPT_STATUS, LOGIN_TYPE, ROUTE_PATH, UNABLE_EDIT_FLG } from '../../constants';
import acceptStatus from '../../data/AcceptStatus.json';
import acceptViewColumn from '../../data/AcceptViewColumn.json';
import group from '../../data/Group.json';
import shippingCompanyCode from '../../data/shippingCompanyCode.json';
import AcceptService from '../../services/Accept.service';
import AuthService from '../../services/auth.service';
import { acceptDataListContext } from './Accept';
import { AcceptDetailModal } from './AcceptDetailModal';
import { AcceptStatusHistory } from './AcceptStatusHistory';
import ColumnViewDialog from './ColumnViewDialog';

const TableHead = styled(muiTableHead)(() => ({
    borderBottom: 'solid',
    borderBottomColor: grey[200],
    borderBottomWidth: '2px',
}));
const TableHeadCellTop = styled(Typography)(() => ({
    fontWeight: 700,
    fontFamily: ['Roboto'].join(','),
    fontSize: '14px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableHeadCell = styled(Typography)(() => ({
    //fontWeight: 700,
    fontFamily: ['Roboto'].join(','),
    fontSize: '14px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableRowHeader = styled(muiTableRow)({
    background: '#F9F9F9',
    minHeight: 100,
});

export const AcceptResult = (props) => {
    const [showModalHistory, setShowModalHistory] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [statusData, setStatusData] = useState('');
    const [, setOrder] = useContext(orderContext);
    const [, setTotal] = useContext(totalContext);
    const [, setDetail] = useContext(detailContext);
    const [acceptDataList, setAcceptDataList] = useContext(acceptDataListContext);
    const [visibleRows, setVisibleRows] = useState(acceptDataList);
    const [selectedOrder, setSelectedOrder] = useState('delivery_date');

    const logintype = AuthService.getCurrentUser().logintype;
    const ViewDetail = async (row) => {
        //注文番号リンク押下処理
        try {
            setOrder(row); //注文情報を設定

            //明細情報取得、設定
            const response = await AcceptService.getDetail(row.order_no);
            setDetail(response);

            // 合計金額計算
            let totalAmount = 0;
            response.forEach((row) => {
                if (row.cancel_kbn === '1') return; //キャンセルされたものは除く
                totalAmount += row.unit_price * row.order_quantity;
            });
            setTotal(totalAmount);

            //■仕入先
            //新規注文、確定待ち、納期確定、金額確定、計上完了
            //■サンベック
            //計上完了、確定待ち、代理更新中
            //※サンベックの場合はフロート画面へ遷移してから編集画面へ遷移する
            //　仕入先の場合は上記ステータスであった場合は編集画面
            //　以外ステータスはフロート表示で編集不可
            //※編集不可フラグがONの場合はモーダル
            if (row.unable_edit_flg === UNABLE_EDIT_FLG.LOCK || logintype === LOGIN_TYPE.ADMIN) {
                //サンベックの場合はモーダル表示
                setShowModalDetail(true); // モーダル表示
            } else if (logintype === LOGIN_TYPE.SUPPLIER) {
                if ([ACCEPT_STATUS.NEW, ACCEPT_STATUS.CHANGE].includes(row.order_status)) {
                    //仕入先で新規注文、変更ありの場合はステータスを確定待ちとして編集画面へ遷移
                    const updatedOrder = await AcceptService.updateStatus(row.order_no, ACCEPT_STATUS.WAIT, AuthService.getCurrentUser().loginid);
                    setOrder(updatedOrder);
                    navigate('../' + ROUTE_PATH.ACCEPT_DETAIL, { state: { formData: props.formData } });
                } else {
                    //確定待ち、納期確定、金額確定は編集画面遷移
                    if ([ACCEPT_STATUS.WAIT, ACCEPT_STATUS.DELIVERY, ACCEPT_STATUS.AMOUNT].includes(row.order_status)) {
                        navigate('../' + ROUTE_PATH.ACCEPT_DETAIL, { state: { formData: props.formData } });
                    } else {
                        setShowModalDetail(true); //モーダル表示
                    }
                }
            }
        } catch (error) {
            // エラーハンドリング
            console.error('Error:', error);
        }
    };

    //ステータス履歴表示
    const ModalStatusHistory = (row) => {
        setOrder(row); //注文情報を設定
        //ステータス履歴取得
        AcceptService.getStatusLog(row.order_no).then(
            (response) => {
                //ステータス履歴データ取得成功
                // フロート画面表示用
                setShowModalHistory(true);
                // setResultData(row);
                setStatusData(response);
            },
            (error) => {
                //ステータス履歴データ取得エラー
            },
        );
    };

    // ソート順変更
    const handleOrderChange = (event) => {
        const newSelectedOrder = event.target.value;
        setSelectedOrder(newSelectedOrder);

        const sortedData = [...acceptDataList].sort((a, b) => {
            if (newSelectedOrder === 'order_no') {
                return b[newSelectedOrder] - a[newSelectedOrder];
            } else {
                const dateAValue = a.scheduled_delivery_date ? a.scheduled_delivery_date : a.desired_delivery_date;
                const dateBValue = b.scheduled_delivery_date ? b.scheduled_delivery_date : b.desired_delivery_date;
                return new Date(dateBValue) - new Date(dateAValue);
            }
        });
        setAcceptDataList(sortedData);
        //setAcceptDataList((prevData) => prevData);
        setVisibleRows(sortedData);
    };

    // 項目表示非表示設定
    const [open, setOpen] = useState(false);
    // ダイアログ内での設定
    const [tempShowItems, setTempShowItems] = useState({
        item1: true,
        item2: true,
        item3: true,
        item4: true,
        item5: true,
        item6: true,
        item7: true,
        item8: true,
        item9: true,
        item10: true,
        item11: true,
    });
    // 実際に表示設定を行うフラグ
    const [showItems, setShowItems] = useState({
        item1: true,
        item2: true,
        item3: true,
        item4: true,
        item5: true,
        item6: true,
        item7: true,
        item8: true,
        item9: true,
        item10: true,
        item11: true,
    });
    // 各イベントハンドラ
    const handleOpen = () => {
        setTempShowItems({ ...showItems });
        setOpen(true);
    };
    const handleClose = () => {
        setTempShowItems({ ...showItems });
        setOpen(false);
    };
    const handleCheckboxChange = (itemName) => {
        setTempShowItems((prevTempShowItems) => ({
            ...prevTempShowItems,
            [itemName]: !prevTempShowItems[itemName],
        }));
    };
    const handleApplyChanges = () => {
        setShowItems({ ...tempShowItems });
        handleClose();
    };

    const navigate = useNavigate();
    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <SelectOrderDisp selectedOrder={selectedOrder} onOrderChange={handleOrderChange} />
                {logintype === LOGIN_TYPE.ADMIN && (
                    <Link style={{ cursor: 'pointer' }} sx={{ mb: 1 }} onClick={handleOpen}>
                        表示項目を設定
                    </Link>
                )}
            </Box>

            <TableSearchResult rows={acceptDataList} setVisibleRows={setVisibleRows}>
                <TableHead>
                    <TableRowHeader>
                        {(showItems.item1 || showItems.item2 || showItems.item3) && (
                            <TableCell sx={{ padding: 0 }}>
                                <TableHeadCellTop sx={{ mt: 2, ml: 2, width: 180 }}>{showItems.item1 && '注文番号'}</TableHeadCellTop>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item2 && '営業所名'}</TableHeadCell>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item3 && '担当者名'}</TableHeadCell>
                            </TableCell>
                        )}
                        {(showItems.item4 || showItems.item5 || showItems.item6) && (
                            <TableCell sx={{ padding: 0 }}>
                                <TableHeadCellTop sx={{ mt: 2, ml: 2 }}>{showItems.item4 && '注文日時'}</TableHeadCellTop>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item5 && '希望納品日'}</TableHeadCell>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item6 && '予定納品日'}</TableHeadCell>
                            </TableCell>
                        )}
                        {(showItems.item7 || showItems.item8 || showItems.item9) && (
                            <TableCell sx={{ padding: 0 }}>
                                <TableHeadCellTop sx={{ mt: 2, ml: 2 }}>{showItems.item7 && '物件名・客先'}</TableHeadCellTop>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item8 && '住所'}</TableHeadCell>
                                <TableHeadCell sx={{ ml: 2 }}>{showItems.item9 && '運送会社・個口・送り状番号'}</TableHeadCell>
                            </TableCell>
                        )}
                        {logintype === LOGIN_TYPE.ADMIN && showItems.item10 && (
                            <TableCell sx={{ padding: 0 }}>
                                <TableHeadCellTop sx={{ ml: 2 }}>仕入先</TableHeadCellTop>
                            </TableCell>
                        )}
                        {showItems.item11 && (
                            <TableCell sx={{ padding: 0, width: 150 }}>
                                <TableHeadCellTop sx={{ ml: 2 }}>注文ステータス</TableHeadCellTop>
                            </TableCell>
                        )}
                    </TableRowHeader>
                </TableHead>
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow key={row.order_no} style={{ height: '100px' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {(showItems.item1 || showItems.item2 || showItems.item3) && (
                                <TableCell sx={{ padding: 0 }}>
                                    {showItems.item1 && (
                                        <Typography sx={{ mt: 1, ml: 2 }}>
                                            {/* 注文番号 */}
                                            <Link style={{ cursor: 'pointer' }} onClick={() => ViewDetail(row)}>
                                                {row.order_no}
                                            </Link>
                                            {row.file1 !== null && row.file1 !== '' && <AttachFileIcon fontSize="small" color="disabled" />}
                                        </Typography>
                                    )}
                                    {showItems.item2 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>
                                            {/* 営業所名 */}
                                            {expfnc.getJsonData(group, row.group_cd)}
                                        </Typography>
                                    )}
                                    {showItems.item3 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>
                                            {/* 担当者名 */}
                                            {row.person_name}
                                        </Typography>
                                    )}
                                </TableCell>
                            )}
                            {(showItems.item4 || showItems.item5 || showItems.item6) && (
                                <TableCell sx={{ padding: 0 }}>
                                    {/* 注文日時 */}
                                    {showItems.item4 && (
                                        <Typography sx={{ mt: 1, ml: 2, minHeight: 24 }}>{expfnc.datetostr(row.order_date, false, true)}</Typography>
                                    )}
                                    {/* 希望納品日 */}
                                    {showItems.item5 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>{expfnc.datetostr(row.desired_delivery_date)}</Typography>
                                    )}
                                    {/* 予定納品日 */}
                                    {showItems.item6 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>{expfnc.datetostr(row.scheduled_delivery_date)}</Typography>
                                    )}
                                </TableCell>
                            )}
                            {(showItems.item7 || showItems.item8 || showItems.item9) && (
                                <TableCell sx={{ padding: 0 }}>
                                    {/* 物件名・客先 */}
                                    {showItems.item7 && (
                                        <Typography sx={{ mt: 1, ml: 2, minHeight: 24 }}>
                                            {row.property_name}
                                            {row.customer_name != null && <>・{row.customer_name}</>}
                                        </Typography>
                                    )}
                                    {/* 住所 */}
                                    {showItems.item8 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>
                                            {row.delivery_add1}
                                            {row.delivery_add2}
                                        </Typography>
                                    )}
                                    {/* 運送会社・個口・送り状番号 */}
                                    {showItems.item9 && (
                                        <Typography sx={{ ml: 2, minHeight: 24 }}>
                                            {expfnc.getJsonData(shippingCompanyCode, row.shipping_company_code)}
                                            {row.number_of_pieces !== null && <>・{String(row.number_of_pieces)}個口</>}

                                            {row.destination_number !== null && (
                                                <>
                                                    ・
                                                    {row.shipping_company_code === '01' ? (
                                                        <Link
                                                            href={`https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${row.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {row.destination_number}
                                                        </Link>
                                                    ) : row.shipping_company_code === '02' ? (
                                                        <Link
                                                            href={`https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${row.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {row.destination_number}
                                                        </Link>
                                                    ) : row.shipping_company_code === '03' ? (
                                                        <Link
                                                            href={`https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S003&locale=ja&SVID=023&reqCodeNo1=${row.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {row.destination_number}
                                                        </Link>
                                                    ) : row.shipping_company_code === '04' ? (
                                                        <Link
                                                            href={`https://track.seino.co.jp/cgi-bin/gnpquery.pgm?GNPNO1=${row.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {row.destination_number}
                                                        </Link>
                                                    ) : row.shipping_company_code === '05' ? (
                                                        <Link
                                                            href={`https://lp-trace.nittsu.co.jp/web/webarpaa702.srv?denpyoNo1=${row.destination_number}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {row.destination_number}
                                                        </Link>
                                                    ) : (
                                                        row.destination_number
                                                    )}
                                                </>
                                            )}
                                        </Typography>
                                    )}
                                </TableCell>
                            )}

                            {/* 仕入先 */}
                            {logintype === LOGIN_TYPE.ADMIN && showItems.item10 && (
                                <TableCell sx={{ padding: 0 }}>
                                    <Tooltip title={row.supplier_name}>
                                        <Typography sx={{ ml: 2 }}>{row.supplier_name}</Typography>
                                    </Tooltip>
                                </TableCell>
                            )}
                            {/* 注文ステータス */}
                            {showItems.item11 && (
                                <TableCell sx={{ padding: 0 }}>
                                    <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ModalStatusHistory(row)}>
                                        <Typography sx={{ ml: 2 }}>{expfnc.getJsonData(acceptStatus, row.order_status)}</Typography>
                                    </Link>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </TableSearchResult>

            {showModalHistory ? (
                <AcceptStatusHistory
                    showModal={showModalHistory}
                    // orderData={resultData}
                    statusData={statusData}
                    setShowModal={setShowModalHistory}
                />
            ) : (
                <></>
            )}
            {showModalDetail ? (
                <AcceptDetailModal
                    showModal={showModalDetail}
                    // orderData={resultData}
                    setShowModal={setShowModalDetail}
                    formData={props.formData}
                />
            ) : (
                <></>
            )}

            <ColumnViewDialog
                open={open}
                handleClose={handleClose}
                handleApplyChanges={handleApplyChanges}
                tempShowItems={tempShowItems}
                handleCheckboxChange={handleCheckboxChange}
                checkboxes={acceptViewColumn}
            />
        </div>
    );
};
