import React from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import acceptStatus from '../../data/AcceptStatus.json';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    // backgroundColor: '#F4F8FE',
    backgroundColor: 'white',
    position: 'absolute',
    width: '817px',
    // height: '427px',
    overflow: 'auto',
};

export const AcceptReloadModal = (props) => {
    useEffect(() => {}, []);
    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ mt: 3, ml: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                下記の変更がありました。画面を再読み込みしてください。
                            </Typography>
                            <Typography color="error">※下記の変更内容が優先されます。お手数ですが、再度修正をお願いします。</Typography>
                            <Box sx={{ mt: 3 }}>
                                注文ステータス　：　
                                {expfnc.getJsonData(acceptStatus, props.beforeData.state)}
                                　→　
                                {expfnc.getJsonData(acceptStatus, props.resultData.state)}
                                <br />
                                注文合計金額　　：　￥
                                {props.beforeData.total.toLocaleString()}　→　 ￥{props.resultData.total.toLocaleString()}
                                <br />
                                納品予定日　　　：　{props.beforeData.orderDate}　→　
                                {props.resultData.orderDate}
                            </Box>
                            <Box sx={{ mt: 2 }}></Box>
                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button
                                    variant="contained"
                                    sx={{ width: 176 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                        props.pageReload();
                                    }}
                                >
                                    画面を再読み込みする
                                </Button>
                            </Stack>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
