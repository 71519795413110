import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import ErrorBoundary from './ErrorBoundary';
import { NotFound } from './NotFound';
import EventBus from './common/EventBus';
import { MainLayout } from './components/MainLayout';
import { ROUTE_PATH } from './constants';
import { Accept } from './pages/accept/Accept';
import { AcceptCustomer } from './pages/accept/AcceptCustomer';
import { AcceptDetail } from './pages/accept/AcceptDetail';
import { SenderRegist } from './pages/accept/SenderRegist';
import { AcceptPrint } from './pages/acceptprint/AcceptPrint';
import { DeliveryslipInvoiceDownload } from './pages/download/DeliveryslipInvoiceDownload';
import { FileDownload } from './pages/download/FileDownload';
import { MasterDownload } from './pages/download/MasterDownload';
import { ResultDataDownload } from './pages/download/ResultDataDownload';
import { ChangePassword } from './pages/login/ChangePassword';
import { LoginAdmin } from './pages/login/LoginAdmin';
import { LoginCustomer } from './pages/login/LoginCustomer';
import { LoginSupplier } from './pages/login/LoginSupplier';
import { AdminMailChange } from './pages/maintenance/AdminMailChange';
import { CustomerMailChange } from './pages/maintenance/CustomerMailChange';
import { CustomerMainte } from './pages/maintenance/CustomerMainte';
import { LoginMainte } from './pages/maintenance/LoginMainte';
import { LoginMainteRegist } from './pages/maintenance/LoginMainteRegist';
import { LoginMainteUpdate } from './pages/maintenance/LoginMainteUpdate';
import { MstLog } from './pages/maintenance/MstLog';
import { Returns } from './pages/maintenance/Returns';
import { SupplierMailChange } from './pages/maintenance/SupplierMailChange';
import { SupplierMainte } from './pages/maintenance/SupplierMainte';
import { AcceptDataUpload } from './pages/upload/AcceptDataUpload';
import { FileUpload } from './pages/upload/FileUpload';
import { FileUploadHistory } from './pages/upload/FileUploadHistory';
import { OrderUploadHis } from './pages/upload/OrderUploadHistory';
import AuthService from './services/auth.service';

class App extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            currentUser: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                currentUser: user,
            });
        }

        EventBus.on(ROUTE_PATH.logout, () => {
            this.logOut();
        });
    }

    componentWillUnmount() {
        EventBus.remove(ROUTE_PATH.logout);
    }

    logOut() {
        AuthService.logout();
        this.setState({
            currentUser: undefined,
        });
    }

    render() {
        // 認証確認メソッド
        // → 認証されていない場合、ログインページにリダイレクト
        // 認証確認メソッド
        const RequireAuth = (props) => {
            const user = AuthService.getCurrentUser();

            if (user != null) {
                //遷移先とログイン種類のチェック
                let logintype = user.logintype;
                if (
                    (logintype === '01' && props.type === ROUTE_PATH.MAIN_CUSTOMER) ||
                    (logintype === '02' && props.type === ROUTE_PATH.MAIN_SUPPLIER) ||
                    (logintype === '03' && props.type === ROUTE_PATH.MAIN_ADMIN)
                ) {
                    return props.component;
                }
                // return props.component;
            }

            switch (props.type) {
                case ROUTE_PATH.MAIN_CUSTOMER:
                    document.location = ROUTE_PATH.CUSTOMER;
                    break;
                case ROUTE_PATH.MAIN_SUPPLIER:
                    document.location = ROUTE_PATH.SUPPLIER;
                    break;
                case ROUTE_PATH.MAIN_ADMIN:
                    document.location = ROUTE_PATH.ADMIN;
                    break;
            }
        };
        document.title = 'WebEDIシステム';
        return (
            <>
                <ErrorBoundary>
                    <Routes>
                        {/* 404ページ */}
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={<NotFound />} />
                        <Route path="/error" element={<NotFound />} />

                        {/* ********************得意先******************** */}
                        <Route path={ROUTE_PATH.CUSTOMER} element={<LoginCustomer />} />
                        <Route path={ROUTE_PATH.MAIN_CUSTOMER + '/download'} element={<LoginCustomer />} />
                        {/* メインページ */}
                        <Route path={ROUTE_PATH.MAIN_CUSTOMER} element={<RequireAuth component={<MainLayout />} type={ROUTE_PATH.MAIN_CUSTOMER} />}>
                            {/* 注文状況検索 */}
                            <Route
                                index
                                path={ROUTE_PATH.ACCEPT}
                                element={<RequireAuth component={<AcceptCustomer />} type={ROUTE_PATH.MAIN_CUSTOMER} />}
                            />
                            {/* 注文状況詳細 */}
                            <Route
                                path={ROUTE_PATH.ACCEPT_DETAIL}
                                element={<RequireAuth component={<AcceptDetail />} type={ROUTE_PATH.MAIN_CUSTOMER} />}
                            />
                            {/* メールアドレス変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_MAIL}
                                element={<RequireAuth component={<CustomerMailChange />} type={ROUTE_PATH.MAIN_CUSTOMER} />}
                            />
                            {/* パスワード変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_PASSWORD}
                                element={<RequireAuth component={<ChangePassword />} type={ROUTE_PATH.MAIN_CUSTOMER} />}
                            />
                            {/* 請求書・納品書等ダウンロード */}
                            <Route
                                path={ROUTE_PATH.DELIVERYSLIP_INVOICEDOWNLOAD}
                                element={<RequireAuth component={<DeliveryslipInvoiceDownload />} type={ROUTE_PATH.MAIN_CUSTOMER} />}
                            />
                        </Route>

                        {/* ********************仕入先******************** */}
                        <Route path={ROUTE_PATH.SUPPLIER} element={<LoginSupplier />} />
                        <Route path={ROUTE_PATH.MAIN_SUPPLIER + '/:order'} element={<LoginSupplier />} />
                        <Route path={ROUTE_PATH.MAIN_SUPPLIER + '/download'} element={<LoginSupplier />} />
                        <Route path={ROUTE_PATH.MAIN_SUPPLIER + '/mod'} element={<LoginSupplier />} />
                        {/* メインページ */}
                        <Route path={ROUTE_PATH.MAIN_SUPPLIER} element={<RequireAuth component={<MainLayout />} type={ROUTE_PATH.MAIN_SUPPLIER} />}>
                            {/* 注文状況検索 */}
                            <Route index path={ROUTE_PATH.ACCEPT} element={<RequireAuth component={<Accept />} type={ROUTE_PATH.MAIN_SUPPLIER} />} />
                            <Route
                                index
                                path={ROUTE_PATH.ACCEPT + '/:order'}
                                element={<RequireAuth component={<Accept />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 注文状況詳細 */}
                            <Route
                                path={ROUTE_PATH.ACCEPT_DETAIL}
                                element={<RequireAuth component={<AcceptDetail />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            <Route
                                path={ROUTE_PATH.ACCEPT_DETAIL + '/:order'}
                                element={<RequireAuth component={<AcceptDetail />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 注文書印刷 */}
                            <Route
                                path={ROUTE_PATH.ACCEPT_PRINT}
                                element={<RequireAuth component={<AcceptPrint />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 送り状番号登録 */}
                            <Route
                                path={ROUTE_PATH.INVOICE_REGIST}
                                element={<RequireAuth component={<SenderRegist />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 実績データダウンロード */}
                            <Route
                                path={ROUTE_PATH.ACTUAL_DOWNLOAD}
                                element={<RequireAuth component={<ResultDataDownload />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 修正伝票 */}
                            <Route
                                path={ROUTE_PATH.CORRECTION_SLIP}
                                element={<RequireAuth component={<Returns />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 注文確認データアップロード*/}
                            <Route
                                path={ROUTE_PATH.ACEPPT_UPLOAD}
                                element={<RequireAuth component={<AcceptDataUpload />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* 注文確認データ履歴照会 */}
                            <Route
                                path={ROUTE_PATH.ACEPPT_UPLOAD_LOG}
                                element={<RequireAuth component={<OrderUploadHis />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* ファイルアップロード */}
                            <Route
                                path={ROUTE_PATH.FILE_UPLOAD}
                                element={<RequireAuth component={<FileUpload />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* ファイルアップロード履歴 */}
                            <Route
                                path={ROUTE_PATH.FILE_UPLOAD_HISTORY}
                                element={<RequireAuth component={<FileUploadHistory />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* ファイルダウンロード */}
                            <Route
                                path={ROUTE_PATH.FILE_DOWNLOAD}
                                element={<RequireAuth component={<FileDownload />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* メールアドレス変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_MAIL}
                                element={<RequireAuth component={<SupplierMailChange />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                            {/* パスワード変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_PASSWORD}
                                element={<RequireAuth component={<ChangePassword />} type={ROUTE_PATH.MAIN_SUPPLIER} />}
                            />
                        </Route>
                        {/* ********************管理者******************** */}
                        <Route path={ROUTE_PATH.ADMIN} element={<LoginAdmin />} />
                        <Route path={ROUTE_PATH.MAIN_ADMIN + '/:order'} element={<LoginAdmin />} />
                        <Route path={ROUTE_PATH.MAIN_ADMIN + '/download'} element={<LoginAdmin />} />
                        {/* メインページ */}
                        <Route path={ROUTE_PATH.MAIN_ADMIN} element={<RequireAuth component={<MainLayout />} type={ROUTE_PATH.MAIN_ADMIN} />}>
                            {/* 注文状況検索 */}
                            <Route index path={ROUTE_PATH.ACCEPT} element={<RequireAuth component={<Accept />} type={ROUTE_PATH.MAIN_ADMIN} />} />
                            <Route
                                index
                                path={ROUTE_PATH.ACCEPT + '/:order'}
                                element={<RequireAuth component={<Accept />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 注文状況詳細 */}
                            <Route
                                path={ROUTE_PATH.ACCEPT_DETAIL}
                                element={<RequireAuth component={<AcceptDetail />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            <Route
                                path={ROUTE_PATH.ACCEPT_DETAIL + '/:order'}
                                element={<RequireAuth component={<AcceptDetail />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 注文書印刷 */}
                            <Route
                                path={ROUTE_PATH.ACCEPT_PRINT}
                                element={<RequireAuth component={<AcceptPrint />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 送り状番号登録 */}
                            <Route
                                path={ROUTE_PATH.INVOICE_REGIST}
                                element={<RequireAuth component={<SenderRegist />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 実績データダウンロード */}
                            <Route
                                path={ROUTE_PATH.ACTUAL_DOWNLOAD}
                                element={<RequireAuth component={<ResultDataDownload />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 修正伝票 */}
                            <Route path={ROUTE_PATH.CORRECTION_SLIP} element={<RequireAuth component={<Returns />} type={ROUTE_PATH.MAIN_ADMIN} />} />
                            {/* ファイルアップロード */}
                            <Route path={ROUTE_PATH.FILE_UPLOAD} element={<RequireAuth component={<FileUpload />} type={ROUTE_PATH.MAIN_ADMIN} />} />
                            {/* ファイルアップロード履歴 */}
                            <Route
                                path={ROUTE_PATH.FILE_UPLOAD_HISTORY}
                                element={<RequireAuth component={<FileUploadHistory />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* ファイルダウンロード */}
                            <Route
                                path={ROUTE_PATH.FILE_DOWNLOAD}
                                element={<RequireAuth component={<FileDownload />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* マスタ更新履歴 */}
                            <Route path={ROUTE_PATH.MASTERLOG} element={<RequireAuth component={<MstLog />} type={ROUTE_PATH.MAIN_ADMIN} />} />
                            {/* マスタダウンロード */}
                            <Route
                                path={ROUTE_PATH.MASTER_DOWNLOAD}
                                element={<RequireAuth component={<MasterDownload />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* ログインIDマスタ */}
                            <Route path={ROUTE_PATH.LOGINMAINTE} element={<RequireAuth component={<LoginMainte />} type={ROUTE_PATH.MAIN_ADMIN} />} />
                            {/* ログインIDマスタ登録 */}
                            <Route
                                path={ROUTE_PATH.LOGINMAINTE_REGIST}
                                element={<RequireAuth component={<LoginMainteRegist />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* ログインIDマスタ更新 */}
                            <Route
                                path={ROUTE_PATH.LOGINMAINTE_UPDATE}
                                element={<RequireAuth component={<LoginMainteUpdate />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 得意先マスタ */}
                            <Route
                                path={ROUTE_PATH.CUSTOMERMAINTE}
                                element={<RequireAuth component={<CustomerMainte />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* 仕入先マスタ */}
                            <Route
                                path={ROUTE_PATH.SUPPLIERMAINTE}
                                element={<RequireAuth component={<SupplierMainte />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* メールアドレス変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_MAIL}
                                element={<RequireAuth component={<AdminMailChange />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* パスワード変更 */}
                            <Route
                                path={ROUTE_PATH.CHANGE_PASSWORD}
                                element={<RequireAuth component={<ChangePassword />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                            {/* マスターダウンロード */}
                            <Route
                                path={ROUTE_PATH.MASTER_DOWNLOAD}
                                element={<RequireAuth component={<MasterDownload />} type={ROUTE_PATH.MAIN_ADMIN} />}
                            />
                        </Route>
                    </Routes>
                </ErrorBoundary>
            </>
        );
    }
}

export default App;
