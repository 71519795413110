import ApiClient from '../services/ApiClient';

class AcceptUploadService {
    upload(uploadstatus, uploadtype, filedata, comment, updateid, count) {
        return ApiClient.post(
            'api/acceptDataUpload/upload',
            (response) => {
                console.log(response);

                return response;
            },
            {
                uploadstatus: uploadstatus,
                uploadtype: uploadtype,
                filedata: filedata,
                comment: comment,
                updateid: updateid,
                count: count,
            },
        );
    }
    uploadLog() {
        return ApiClient.get(
            'api/acceptDataUpload/uploadLog',
            (response) => {
                console.log(response);

                return response;
            },
            {},
        );
    }

    detailSeach(upload_date) {
        return ApiClient.get(
            'api/acceptDataUpload/detailSeach',
            (response) => {
                console.log(response);

                return response;
            },
            {
                params: {
                    upload_date: upload_date,
                },
            },
        );
    }

    selectError(order_no, upload_date) {
        return ApiClient.get(
            'api/acceptDataUpload/errorDetail',
            (response) => {
                console.log(response);

                return response;
            },
            {
                params: {
                    order_no: order_no,
                    upload_date: upload_date,
                },
            },
        );
    }
}
export default new AcceptUploadService();
