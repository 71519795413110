import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { InputTextPassword } from '../../components/input/InputTextPassword';
import { TableCell, TableRow } from '../../components/table/TableStyled';
import { MESSAGE, PAGE_NAME } from '../../constants';
import ChangePasswordService from '../../services/ChangePassword.service';
import AuthService from '../../services/auth.service';

//ヘッダー
export const ChangePasswordHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.CHANGEPASSWORD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>パスワードを入力して、「確定」ボタンを押してください。</Typography>
        </>
    );
};

//メインページ
export const ChangePassword = () => {
    // ヘッダーセット
    const setHeader = useContext(headerContext);

    useLayoutEffect(() => {
        // 初期処理にてヘッダーを設定
        setHeader(<ChangePasswordHeader />);
    }, []);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const submit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        // 確定ボタン押下
        setIsError(false);
        setDigOpen(false);

        //相関チェック
        //・現在のパスワードと新しいパスワードが同じ
        if (data.currentpassword === data.newpassword) {
            //新しいパスワードは現在のパスワードから変更してください
            setErrorMessage(MESSAGE.PASS_CHANGE_ERR_NO_CNG);
            setIsError(true);
            return;
        }

        //・新しいパスワードと新しいパスワード（確認用）が相違
        if (data.newpassword !== data.confirmationpassword) {
            //新しいパスワードが確認用のパスワードと相違しています
            setErrorMessage(MESSAGE.PASS_CHANGE_ERR_CNF);
            setIsError(true);
            return;
        }

        //データチェック
        const user = AuthService.getCurrentUser();
        ChangePasswordService.changepass(user.loginid, user.logintype, data.currentpassword, data.newpassword).then(
            (response) => {
                //成功
                //ダイアログ内容設定
                setDigProp({
                    ...digProp,
                    message: MESSAGE.PASS_CHANGE_OK,
                    onClose: () => reset(),
                });

                //ダイアログ表示
                setDigOpen(true);

                //項目初期化
                reset({
                    currentpassword: '',
                    newpassword: '',
                    confirmationpassword: '',
                });
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //エラー
                if (error.message !== void 0) {
                    setErrorMessage(error.message);
                    setIsError(true);
                }
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    // 画面項目の初期値設定
    const useFormMethods = useForm({
        defaultValues: {
            currentpassword: '',
            newpassword: '',
            confirmationpassword: '',
        },
    });
    const { handleSubmit, reset } = useFormMethods;
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
        onClose: () => ({}),
    });

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableContainer
                            sx={{
                                padding: 3,
                            }}
                        >
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                paddingBottom: 2,
                                            }}
                                        >
                                            <InputTextPassword name="currentpassword" maxLength={13} placeholder="現在のパスワード" />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            border: 0,
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                paddingBottom: 1,
                                                paddingTop: 2,
                                                border: 0,
                                            }}
                                        >
                                            <InputTextPassword name="newpassword" maxLength={20} placeholder="新しいパスワード" />
                                            {/* <br /> */}
                                            {/* ５桁以上 */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                paddingBottom: 2,
                                            }}
                                        >
                                            <InputTextPassword name="confirmationpassword" maxLength={20} placeholder="新しいパスワード(確認用)" />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {isError && (
                                <Stack my={2} mr={2} direction="row" spacing={1}>
                                    <Typography color="error">{errorMessage}</Typography>
                                </Stack>
                            )}
                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button type="submit" variant="contained" sx={{ width: 150 }} disabled={processing}>
                                    確定
                                </Button>
                            </Stack>
                        </TableContainer>
                    </Paper>
                </Box>
            </form>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => ''} //OK時の処理など
                onClose={digProp.onClose}
            />
        </FormProvider>
    );
};
