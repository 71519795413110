import React from 'react';
import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { orderContext } from '../../components/MainLayout';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import acceptStatus from '../../data/AcceptStatus.json';
import group from '../../data/Group.json';
import AcceptService from '../../services/Accept.service';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    backgroundColor: '#F4F8FE',
    // backgroundColor: 'white',
    position: 'absolute',
    top: '60px',
    left: '80px',
    right: '80px',
    bottom: '60px',
    overflow: 'auto',
};

export const AcceptStatusHistory = (props) => {
    const [visibleRows, setVisibleRows] = useState([
        {
            update_dt: '',
            order_status: '',
            notifications: '',
            person_name: '',
        },
    ]);
    const [order] = useContext(orderContext);

    useEffect(() => {
        //初期処理
        AcceptService.getStatusLog(order.order_no).then(
            (response) => {
                setVisibleRows(response);
            },
            (error) => {
                //ステータス履歴データ取得エラー
            },
        );
    }, []);

    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            {/* ボタンは右上に固定表示する */}
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    position: 'fixed',
                                    top: '70px',
                                    right: '110px',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>
                            <Typography>注文情報</Typography>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableHead sx={{ width: '200px' }}>注文ステータス</TableHead>
                                                <TableCell>{expfnc.getJsonData(acceptStatus, order.order_status)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>注文番号</TableHead>
                                                <TableCell>{order.order_no}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>注文日時</TableHead>
                                                <TableCell>{expfnc.datetostr(order.order_date)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>営業所名</TableHead>
                                                <TableCell>{expfnc.getJsonData(group, order.group_cd)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>担当者名</TableHead>
                                                <TableCell>{order.person_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>客先</TableHead>
                                                <TableCell>{order.property_name}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Box sx={{ mt: 2 }}>
                                <Typography>注文ステータス履歴</Typography>
                            </Box>
                            <TableSearchResult rows={props.statusData} setVisibleRows={setVisibleRows}>
                                <TableHeaderRow>
                                    <TableRow>
                                        <TableHead>変更日付</TableHead>
                                        <TableHead sx={{ textAlign: 'left' }}>ステータス</TableHead>
                                        <TableHead sx={{ textAlign: 'left' }}>担当者名</TableHead>
                                    </TableRow>
                                </TableHeaderRow>
                                <TableBody>
                                    {visibleRows.map((row) => (
                                        <TableRow>
                                            <TableCellRow>{expfnc.datetostr(row.update_dt)}</TableCellRow>
                                            <TableCellRow>
                                                {expfnc.getJsonData(acceptStatus, row.order_status)}

                                                {row.notifications !== null && row.notifications.trim() !== '' && <>/ {row.notifications}</>}
                                            </TableCellRow>
                                            <TableCellRow>{row.person_name}</TableCellRow>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </TableSearchResult>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
