import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { CheckBoxSearchPrintStatus } from '../../components/checkbox/CheckBoxSearchPrintStatus';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioSearchTypeDate } from '../../components/radio/RadioSearchTypeDate';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { ACCEPT_STATUS, LOGIN_TYPE, MESSAGE } from '../../constants';
import { PAGE_NAME } from '../../constants';
import acceptPrintStatus from '../../data/AcceptPrintStatus.json';
import acceptStatus from '../../data/AcceptStatus.json';
import AcceptPrintService from '../../services/AcceptPrint.service';
import AuthService from '../../services/auth.service';
import { AcceptPrintResult } from './AcceptPrintResult';

export const acceptDataListContext = React.createContext();

//ヘッダー
export const AcceptPrintHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.ACCEPT_PRINT}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                注文書のPDFを表示します。検索後、注文一覧の「発行」ボタンを押してください。
                <br />
                ※注文書発行機能をご利用いただくには、
                <Link href="https://www.adobe.com/jp/acrobat/pdf-reader.html" target="_blank" rel="noopener">
                    Adobe Reader
                </Link>
                が必要となります。
            </Typography>
        </>
    );
};

//メインページ
export const AcceptPrint = () => {
    const setHeader = useContext(headerContext);
    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    useLayoutEffect(() => {
        setHeader(<AcceptPrintHeader />);
    }, []);

    const submit = (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        console.log(data);
        setIsNoData(false);
        setVisible(false);

        let datafrom = null;
        if (data.datefrom != null) {
            datafrom = data.datefrom.toLocaleDateString('ja-JP');
        }
        let datato = null;
        if (data.dateto != null) {
            datato = data.dateto.toLocaleDateString('ja-JP');
        }

        //ログインが仕入先の場合は自身の仕入先コードを検索条件とする
        let scd = data.suppliercd;
        if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
            scd = AuthService.getCurrentUser().edicd;
        }

        //ステータスを取得、分割
        let setstate = '';
        let stateArray = data.status.split(',');
        acceptStatus.forEach((st) => {
            if (st.id === ACCEPT_STATUS.CANCEL) {
                if (stateArray.includes(ACCEPT_STATUS.CANCEL)) {
                    //キャンセルが選択されている場合は含める
                    if (setstate !== '') setstate = setstate + ',';
                    setstate = setstate + st.id;
                }
            } else {
                if (setstate !== '') setstate = setstate + ',';
                setstate = setstate + st.id;
            }
        });

        //発行ステータスの設定
        let setprintstate = '';
        if (stateArray.length === 1 && (stateArray[0] === '' || stateArray[0] === '08')) {
            //チェックなしかキャンセルのみの場合は発行ステータスをすべてにする
            acceptPrintStatus.forEach((st) => {
                if (setprintstate !== '') setprintstate = setprintstate + ',';
                setprintstate = setprintstate + st.id;
            });
        } else {
            stateArray.forEach((st) => {
                if (st !== ACCEPT_STATUS.CANCEL) {
                    //キャンセル以外
                    if (setprintstate !== '') setprintstate = setprintstate + ',';
                    setprintstate = setprintstate + st;
                }
            });
        }

        AcceptPrintService.search(
            data.datetype,
            datafrom,
            datato,
            data.orderno,
            data.group,
            data.personname,
            data.propertyname,
            data.deliveryaddress,
            data.customername,
            scd,
            data.suppliername,
            setstate,
            setprintstate,
        ).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    setVisible(true);
                    setAcceptDataList(response);
                }
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    const useFormMethods = useForm({
        defaultValues: {
            datetype: '02',
        },
    });
    const { handleSubmit } = useFormMethods;
    const [acceptDataList, setAcceptDataList] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);

    return (
        <acceptDataListContext.Provider value={[acceptDataList, setAcceptDataList]}>
            <FormProvider {...useFormMethods}>
                <form onSubmit={handleSubmit(submit)}>
                    <Box component="main">
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box sx={{ mx: 3, mt: 2 }}>
                                <RadioSearchTypeDate name="datetype" />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 2 }}>
                                <DatePickerBase name="datefrom" />
                                <Typography align="center" sx={{ flexGrow: 1, mx: 2, mt: 1 }}>
                                    ～
                                </Typography>
                                <DatePickerBase name="dateto" />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 1, mt: 2 }}>
                                <InputTextBase name="orderno" placeholder="注文番号" sx={{ ml: 1, paddingRight: 1 }} />

                                <SelectSearchGroup name="group" label="営業所" fullWidth="true" sx={{ MarginLeft: 30 }} />

                                <InputTextBase name="personname" placeholder="担当者名" sx={{ ml: 1, paddingRight: 1 }} />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 1, mt: 2 }}>
                                <InputTextBase name="propertyname" placeholder="物件名" sx={{ ml: 1 }} />
                                <InputTextBase name="deliveryaddress" placeholder="納品先" sx={{ ml: 1 }} />
                                <InputTextBase name="customername" placeholder="客先" sx={{ ml: 1, paddingRight: 1 }} />
                            </Box>

                            <Box sx={{ mx: 4, mt: 2 }}>
                                <CheckBoxSearchPrintStatus name="status" />
                            </Box>

                            <Stack my={2} mr={4} direction="row" justifyContent="end" spacing={1}>
                                <Button type="submit" variant="contained" sx={{ width: 115 }} disabled={processing}>
                                    検索
                                </Button>
                            </Stack>
                        </Paper>

                        {visible && (
                            <>
                                <Box sx={{ pt: 2 }}>
                                    <AcceptPrintResult rows={acceptDataList} />
                                </Box>
                            </>
                        )}
                        {isNoData && (
                            <Stack my={2} mr={2} direction="row" spacing={1}>
                                <Typography>{MESSAGE.NO_DATA_ACCEPT}</Typography>
                            </Stack>
                        )}
                    </Box>
                </form>
            </FormProvider>
        </acceptDataListContext.Provider>
    );
};
