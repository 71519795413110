import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Link } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import * as expfnc from '../../common/commonFunction';
import CommonDialog from '../../components/CommonDialog';
import { detailContext, headerContext, orderContext, resultContext, totalContext } from '../../components/MainLayout';
import { DatePickerScheduledDeliveryDate } from '../../components/datepicker/DatePickerScheduledDeliveryDate';
import { TextDesiredDeliveryDate } from '../../components/input/TextDesiredDeliveryDate';
import { TextOrderIssueStatus } from '../../components/input/TextOrderIssueStatus';
import { SelectOrderStatus } from '../../components/select/SelectOrderStatus';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow, TableRowAcceptTotal } from '../../components/table/TableStyled';
import { ACCEPT_STATUS, LOGIN_TYPE, PAGE_NAME, ROUTE_PATH } from '../../constants';
import AcceptService from '../../services/Accept.service';
import FileDownloadService from '../../services/FileDownload.service';
import ResultDataDownloadServise from '../../services/ResultDataDownload.servise';
import AuthService from '../../services/auth.service';
import { AcceptDelivery } from './AcceptDelivery';
import { AcceptInfo } from './AcceptInfo';
import { AcceptRegistModal } from './AcceptRegistModal';
import { AcceptReloadModal } from './AcceptReloadModal';
import { AcceptStatusHistory } from './AcceptStatusHistory';

const priceInputTheme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    const changeCount = useRef(0);
    const preVal = useRef(props.defaultValue);

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onKeyDown={(e) => {
                changeCount.current = 0;
                //IMEONの場合onKeyDownは一度しか発生しない
            }}
            isAllowed={(values) => {
                //二度目の検証が発生するようであればキャンセルする
                const vallen = String(values.value).length;
                const prevallen = String(preVal.current).length;

                if (changeCount.current !== 0 || (vallen - prevallen > 0 && vallen - prevallen !== 1)) {
                    return false;
                }
                return true;
            }}
            onValueChange={(values) => {
                changeCount.current = changeCount.current + 1; //変更時点でカウントする
                preVal.current = values.value;
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
            decimalScale={0}
            maxLength={11}
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

//ヘッダー
export const AcceptDetailHeader = (props) => {
    const useFormMethods = useForm({
        defaultValues: {},
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [order] = useContext(orderContext);
    const [total] = useContext(totalContext);
    const [, setResult] = useContext(resultContext);

    const [orderState, setOrderState] = useState(order.order_status || location.state.orderData.order_status);
    //const [scheduledDate, setScheduledDate] = useState(order.scheduled_delivery_date);
    const [scheduledDate, setScheduledDate] = useState(
        order ? order.scheduled_delivery_date : location.state.orderData ? location.state.orderData.scheduled_delivery_date : null,
    );
    const [orderIssueStatus, setOrderIssueStatus] = useState(order.order_issue_status || location.state.orderData.order_issue_status);

    const orderDownload = () => {
        //注文データダウンロード
        ResultDataDownloadServise.download(order.order_no).then(
            (response) => {
                //ファイル名
                let nowdate = expfnc.datetostrformat(new Date(), true, true); //時間
                let failename = '注文データ_' + order.order_no + '_' + nowdate + '.csv';
                expfnc.fileDownloadDialog(failename, expfnc.json2csv(response, 1, 0));
            },
            (error) => {},
        );
    };

    const printOrderIssue = async () => {
        //注文書発行
        //サンベックアカウントの場合はステータスを発行済みにしない

        try {
            const fileUrl = await FileDownloadService.getFileOrder(order.order_no);

            // window.open(fileUrl, '_blank'); ※ブラウザに直接表示はボタン押下ではできない
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.download = `${order.order_no}.pdf`; // ダウンロード時のファイル名を指定

            // リンクをクリックしてダウンロードを開始
            link.click();

            if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
                //仕入先の場合は注文書発行ステータスを更新する
                AcceptService.updateOrderIssue(order.order_no).then(
                    (response) => {
                        //更新後のステータスに変更する
                        setOrderIssueStatus('03');
                    },
                    (error) => {},
                );
            }
        } catch (error) {
            console.error('Error fetching file order:', error);
            // エラーハンドリングを行う
        }
    };

    const handleButtonClick = () => {
        let sData = null;
        let cData = new Date(scheduledDate);

        if (!isNaN(cData.getDate())) {
            sData = expfnc.datetostr(scheduledDate);
        }

        setResult({
            orderState: orderState,
            scheduledDate: sData,
        });
    };

    useEffect(() => {
        setOrderState(order.order_status);
        setScheduledDate(order.scheduled_delivery_date);
    }, [order]);

    return (
        <FormProvider {...useFormMethods}>
            <Stack direction="row" spacing={1}>
                {/* タイトル表示 */}
                <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                    {PAGE_NAME.ACCEPT_DETAIL}
                </Typography>

                {/* 戻るボタン */}
                <Button
                    type="button"
                    variant="outlined"
                    sx={{ width: 115 }}
                    onClick={() => {
                        navigate(ROUTE_PATH.ACCEPT, { state: { ...location.state } });
                    }}
                >
                    戻る
                </Button>
            </Stack>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                ※注文発行機能をご利用いただくには、
                <Link href="https://www.adobe.com/jp/acrobat/pdf-reader.html" target="_blank" rel="noopener">
                    Adobe Reader
                </Link>
                が必要となります。
            </Typography>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    {/* 注文合計金額 */}
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRowAcceptTotal>
                                    <TableHead style={{ width: 120 }}>注文合計金額</TableHead>
                                    <TableCell>
                                        <Stack spacing={1} direction="row" justifyContent="end">
                                            <Typography>¥{total.toLocaleString()}</Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRowAcceptTotal>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            {/* 注文ステータス */}
                            <SelectOrderStatus name="orderstatus" sx={{ mt: 1 }} value={orderState} setOrderState={setOrderState} />
                        </Grid>
                        <Grid item xs={6}>
                            {/* 希望納品日 */}
                            <TextDesiredDeliveryDate value={order.desired_delivery_date} sx={{ mt: 1 }} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            {/* ステータス履歴 */}
                            <Stack direction="row" justifyContent="end" sx={{ mt: 1 }} onClick={() => props.modalStatusHistory()}>
                                <Link style={{ cursor: 'pointer' }}>ステータス履歴</Link>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            {/* 予定納品日 */}
                            <FormControl variant="standard" fullWidth>
                                <DatePickerScheduledDeliveryDate value={expfnc.strtodate(scheduledDate)} setScheduledDate={setScheduledDate} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ ml: 1 }} orientation="vertical" variant="middle" flexItem />

                <Grid item xs={2}>
                    {/* 注文書発行ステータス */}
                    <TextOrderIssueStatus value={orderIssueStatus} />

                    {/* 注文書発行 */}
                    <Button fullWidth variant="outlined" sx={{ mt: 3 }} onClick={() => printOrderIssue()}>
                        注文書発行
                    </Button>

                    {/* 注文データをダウンロード */}
                    <Box sx={{ mt: 3 }}>
                        <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => orderDownload()}>
                            注文データをダウンロード
                        </Link>
                    </Box>
                </Grid>

                <Divider sx={{ ml: 1 }} orientation="vertical" variant="middle" flexItem />

                <Grid item xs={2}>
                    <Button form="mainform" type="submit" sx={{ m: 2, width: 150 }} variant="contained" onClick={handleButtonClick}>
                        変更を保存
                    </Button>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

//メインページ
export const AcceptDetail = (props) => {
    const setHeader = useContext(headerContext);
    const [order, setOrder] = useContext(orderContext);
    const [total, setTotal] = useContext(totalContext);
    const [detail, setDetail] = useContext(detailContext);
    const [result] = useContext(resultContext);
    const [showModalHistory, setShowModalHistory] = useState(false);
    const [showModalRegist, setShowModalRegist] = useState(false);
    const [showModalReload, setShowModalReload] = useState(false);

    const [orderData] = useState([]);
    const [statusData, setStatusData] = useState([]);

    //更新チェック用
    const [newOrder, setNewOrder] = useState([]);
    const [newTotal, setNewTotal] = useState(0);
    const [newDetail, setNewDetail] = useState([{}]);

    //連絡事項の入力制御
    const [notiFlg, setNotiFlg] = useState(true);

    const [fileUrl1, setFileUrl1] = useState(null);
    const [fileUrl2, setFileUrl2] = useState(null);
    const [fileUrl3, setFileUrl3] = useState(null);
    const [fileUrl4, setFileUrl4] = useState(null);
    const [fileUrl5, setFileUrl5] = useState(null);

    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: '',
        //message: '予定納品日を入力して下さい。',
        buttonType: 'OkOnly',
        onClose: () => ({}),
    });

    const { register, control, handleSubmit } = useForm({
        defaultValues: {
            items: [
                // {
                //     lin_no: 0,
                //     product_name: '',
                //     standard: '',
                //     order_quantity: 0,
                //     unit: '',
                //     unit_price: 0,
                // }
            ],
        },
        mode: 'onBlur',
    });
    const { fields, replace } = useFieldArray({
        name: 'items',
        control,
    });
    const useFormMethods = useForm({
        defaultValues: {},
    });
    const formValues = useWatch({
        // useWatch 関数は items を監視しその値を返す
        name: 'items',
        control,
    });
    //合計金額の計算
    const totalprice = formValues.reduce((acc, { unit_price, order_quantity, cancel_kbn }) => {
        // cancel_kbnが"1"の場合はスキップ
        if (cancel_kbn === '1') {
            return acc;
        }

        let numericUnitPrice = 0;
        if (isNaN(unit_price)) {
            numericUnitPrice = parseFloat(unit_price.replace(/,/g, ''));
        } else {
            numericUnitPrice = unit_price;
        }

        //const numericUnitPrice = typeof unit_price === 'string' ? parseFloat(unit_price.replace(/,/g, '')) : unit_price || 0;

        return acc + Math.floor((numericUnitPrice || 0) * (order_quantity || 0));
    }, 0);

    const submit = (data) => {
        //変更を保存処理

        setDigOpen(false);
        if (result.orderState === '') {
            //ステータスが空欄のためエラー
            setDigProp({
                ...digProp,
                message: '検索画面から再度検索を行い、明細を再表示してください。',
            });
            setDigOpen(true);
            return;
        }

        if ((result.orderState === ACCEPT_STATUS.DELIVERY || result.orderState === ACCEPT_STATUS.ACCOUNTING) && result.scheduledDate === '') {
            //納期確定、計上完了は予定納品日必須
            //ダイアログ表示
            setDigProp({
                ...digProp,
                message: '予定納品日を入力して下さい。',
            });
            setDigOpen(true);
            return;
        }

        try {
            //SCでの更新を確認
            AcceptService.getOrder(beforeData.orderNo).then(
                (responseneworder) => {
                    // 更新日が変更されている場合
                    if (responseneworder !== null && responseneworder.updcntno !== beforeData.updcntno) {
                        //注文情報設定
                        setNewOrder(responseneworder);

                        // 注文詳細から合計金額を計算する
                        AcceptService.getDetail(beforeData.orderNo).then(
                            (response) => {
                                const newTotal = response.reduce((total, row) => {
                                    // cancel_kbnが"1"の場合はスキップ
                                    if (row.cancel_kbn === '1') {
                                        return total;
                                    }
                                    return total + row.order_quantity * row.unit_price;
                                }, 0);

                                // 新しい合計金額と注文詳細で状態を更新する
                                setNewTotal(newTotal);
                                setNewDetail(response);

                                //SCで更新されていた場合は再読み込み用ダイアログを表示
                                ModalReload({
                                    state: responseneworder.order_status,
                                    total: newTotal,
                                    orderDate: expfnc.datetostr(responseneworder.scheduled_delivery_date),
                                    notifications: '',
                                    detail: response,
                                });
                            },
                            (error) => {
                                // エラーが発生した場合の処理
                                console.error(error);
                            },
                        );
                    } else {
                        // 更新日が変更されていない場合、登録ダイアログを表示する
                        ModalRegist({
                            state: result.orderState,
                            total: total,
                            orderDate: result.scheduledDate,
                            notifications: data.notifications,
                            detail: data.items,
                        });
                    }
                },
                (error) => {
                    // エラーが発生した場合の処理
                    console.error(error);
                },
            );
        } catch (error) {
            // エラーが発生した場合の処理
            console.error(error);
        }
    };

    const onSubmitHeader = () => {
        // ヘッダーの必須チェックが成功したら呼ばれる関数
        submit();
    };

    //更新前データ
    const [beforeData, setBeforeData] = useState({
        orderNo: '', //注文番号
        state: '', //注文ステータス
        total: 0, //合計金額
        orderDate: '', //予定納品日
        notifications: '', //連絡事項
        updateDate: '', //更新日時
        updcntno: '', //更新回数
    });
    //更新後データ
    const [resultData, setResultData] = useState({
        state: '', //注文ステータス
        total: 0, //合計金額
        orderDate: '', //予定納品日
        notifications: '', //連絡事項
        detail: [], //明細データ
    });
    const ModalRegist = (data) => {
        // 登録モーダル画面設定
        setResultData(data);
        setShowModalRegist(true);
    };
    const ModalReload = (data) => {
        // 再読み込みモーダル画面設定
        setResultData(data);
        setShowModalReload(true);
    };
    const ModalStatusHistory = () => {
        //ステータス履歴画面設定
        AcceptService.getStatusLog(order.order_no).then(
            (response) => {
                // フロート画面表示用
                setStatusData(response);
                setShowModalHistory(true);
            },
            (error) => {
                //ステータス履歴データ取得エラー
            },
        );
    };

    const PageReload = () => {
        //画面再読み込み
        //変更されたステータスが注文変更ありの場合は確定待ちとして表示する
        if (newOrder.order_status === ACCEPT_STATUS.CHANGE) newOrder.order_status = ACCEPT_STATUS.WAIT;
        setOrder(newOrder);
        setTotal(newTotal);
        setDetail(newDetail);
        replace(newDetail);
        // setHeader(<AcceptDetailHeader />);
        // setOrderData(newOrder);
        // replace(newDetail);

        setBeforeData({
            orderNo: newOrder.order_no,
            state: newOrder.order_status,
            total: newTotal,
            orderDate: expfnc.datetostr(newOrder.scheduled_delivery_date),
            notifications: '',
            updateDate: newOrder.update_dt,
            updcntno: newOrder.updcntno,
        });
    };

    useLayoutEffect(() => {
        setHeader(<AcceptDetailHeader modalStatusHistory={ModalStatusHistory} onSubmitHeader={onSubmitHeader} />);
    }, []);

    const params = useParams();
    useEffect(() => {
        //初期処理
        const fetchData = async () => {
            try {
                if (params.order != null) {
                    //メールURLからの画面遷移
                    const orderResponse = await AcceptService.getOrder(params.order);

                    if (orderResponse.length !== 0) {
                        //ステータスが新規登録か変更ありの場合は確定待ちに更新する
                        if ([ACCEPT_STATUS.NEW, ACCEPT_STATUS.CHANGE].includes(orderResponse.order_status)) {
                            //仕入先で新規注文、変更ありの場合はステータスを確定待ち
                            const updatedOrder = await AcceptService.updateStatus(
                                params.order,
                                ACCEPT_STATUS.WAIT,
                                AuthService.getCurrentUser().loginid,
                            );
                            setOrder(updatedOrder);
                        } else {
                            //ステータスが編集できない
                            setOrder(orderResponse);
                        }

                        const detailResponse = await AcceptService.getDetail(params.order);

                        if (detailResponse.length !== 0) {
                            setDetail(detailResponse);
                            replace(detailResponse);

                            let total = 0;
                            detailResponse.forEach((row) => {
                                if (row.cancel_kbn === '1') return; //キャンセルされたものは除く
                                total += row.unit_price * row.order_quantity;
                            });

                            setTotal(total);

                            setBeforeData({
                                orderNo: orderResponse.order_no,
                                state: orderResponse.order_status,
                                total: total,
                                orderDate: expfnc.datetostr(orderResponse.scheduled_delivery_date),
                                notifications: '',
                                updateDate: orderResponse.update_dt,
                                updcntno: orderResponse.updcntno,
                            });
                        }
                    }
                } else {
                    replace(detail);

                    setBeforeData({
                        orderNo: order.order_no,
                        state: order.order_status,
                        total: total,
                        orderDate: expfnc.datetostr(order.scheduled_delivery_date),
                        notifications: '',
                        updateDate: order.update_dt,
                        updcntno: order.updcntno,
                    });
                }

                if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
                    setNotiFlg(false);
                }

                openFile();
            } catch (error) {
                // エラーハンドリング
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const openFile = async () => {
        try {
            const fileUrl1 = await AcceptService.getFileattached(order.order_no, '1', expfnc.getFileName(order.file1));
            const fileUrl2 = await AcceptService.getFileattached(order.order_no, '2', expfnc.getFileName(order.file2));
            const fileUrl3 = await AcceptService.getFileattached(order.order_no, '3', expfnc.getFileName(order.file3));
            const fileUrl4 = await AcceptService.getFileattached(order.order_no, '4', expfnc.getFileName(order.file4));
            const fileUrl5 = await AcceptService.getFileattached(order.order_no, '5', expfnc.getFileName(order.file5));
            setFileUrl1(fileUrl1);
            setFileUrl2(fileUrl2);
            setFileUrl3(fileUrl3);
            setFileUrl4(fileUrl4);
            setFileUrl5(fileUrl5);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <FormProvider {...useFormMethods}>
                <form id="mainform" onSubmit={handleSubmit(submit)}>
                    <Box component="main" mb={5}>
                        <Grid container direction="row" spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={6}>
                                {/* 注文情報 */}
                                <AcceptInfo orderData={order} />

                                {/* 特記事項 */}
                                <Card variant="outlined" sx={{ mt: 2 }}>
                                    <Typography gutterBottom sx={{ mt: 1, ml: 1 }} fontWeight={700} fontSize="15px">
                                        特記事項
                                    </Typography>
                                    <Typography sx={{ ml: 1, height: '208px' }}>
                                        {order.notices1}
                                        <br />
                                        {order.notices2}
                                        <br />
                                        {order.notices3}
                                        <br />
                                        {order.notices4}
                                        <br />
                                        {order.notices5}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                {/* 納品先情報 */}
                                <AcceptDelivery orderData={order} />

                                {/* 添付ファイル */}
                                <Card variant="outlined" sx={{ mt: 2 }}>
                                    <Typography gutterBottom sx={{ mt: 1, ml: 1 }} fontWeight={700} fontSize="15px">
                                        添付ファイル
                                    </Typography>
                                    <Typography sx={{ ml: 1 }}>
                                        <Link
                                            href={fileUrl1}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            download={expfnc.getFileName(order.file1)}
                                        >
                                            {expfnc.getFileName(order.file1)}
                                        </Link>
                                        <br />
                                        <Link
                                            href={fileUrl2}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            download={expfnc.getFileName(order.file2)}
                                        >
                                            {expfnc.getFileName(order.file2)}
                                        </Link>
                                        <br />
                                        <Link
                                            href={fileUrl3}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            download={expfnc.getFileName(order.file3)}
                                        >
                                            {expfnc.getFileName(order.file3)}
                                        </Link>
                                        <br />
                                        <Link
                                            href={fileUrl4}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            download={expfnc.getFileName(order.file4)}
                                        >
                                            {expfnc.getFileName(order.file4)}
                                        </Link>
                                        <br />
                                        <Link
                                            href={fileUrl5}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="always"
                                            download={expfnc.getFileName(order.file5)}
                                        >
                                            {expfnc.getFileName(order.file5)}
                                        </Link>
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                        {/* 注文内容 */}
                        <Box>
                            注文内容
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="result table">
                                        <TableHeaderRow>
                                            <TableRow>
                                                <TableHead sx={{ width: 70, fontSize: '14px' }}>行番</TableHead>
                                                <TableHead sx={{ fontSize: '14px' }}>
                                                    品名
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>規格</Box>
                                                </TableHead>
                                                <TableHead sx={{ width: 150, fontSize: '14px' }}>
                                                    数量
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>(単位)</Box>
                                                </TableHead>
                                                <TableHead sx={{ width: 250, fontSize: '14px' }}>
                                                    単価
                                                    <br />
                                                    <Box sx={{ fontWeight: 200 }}>(金額)</Box>
                                                </TableHead>
                                            </TableRow>
                                        </TableHeaderRow>

                                        {fields.map((row, index) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                    backgroundColor: row.cancel_kbn === '1' ? '#ccc' : 'transparent',
                                                }}
                                            >
                                                <TableCellRow sx={{ borderRight: 'none' }}>{('00' + row.lin_no).slice(-2)}</TableCellRow>
                                                <TableCellRow sx={{ borderRight: 'none' }}>
                                                    {row.product_name}
                                                    <br />
                                                    {row.standard}
                                                </TableCellRow>
                                                <TableCellRow sx={{ borderRight: 'none' }}>
                                                    {row.order_quantity.toLocaleString()}
                                                    <br />({row.unit})
                                                </TableCellRow>
                                                <TableCellRow sx={{ borderRight: 'none' }}>
                                                    <ThemeProvider theme={priceInputTheme}>
                                                        <Controller
                                                            control={control}
                                                            name={'unit_price' + row.lin_no}
                                                            {...register(`items.${index}.unit_price`)}
                                                            onBlur={setTotal(totalprice)}
                                                            render={({ field: { onChange, name, value } }) => (
                                                                <TextField
                                                                    defaultValue={row.unit_price}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    name={name}
                                                                    InputProps={{
                                                                        inputComponent: NumericFormatCustom,
                                                                    }}
                                                                    disabled={row.cancel_kbn === '1'}
                                                                />
                                                            )}
                                                        />
                                                    </ThemeProvider>
                                                    <br />
                                                    (¥
                                                    {(row.unit_price * row.order_quantity).toLocaleString()})
                                                </TableCellRow>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 2,
                                }}
                            >
                                <TextField
                                    type="text"
                                    defaultValue={order.notifications}
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    placeholder="注文確認に関する連絡事項"
                                    inputProps={{ readOnly: notiFlg }}
                                    {...register(`notifications`)}
                                />
                            </Paper>
                        </Box>
                    </Box>
                </form>
            </FormProvider>
            {/* 予定納品日エラー用ダイアログ */}
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => setDigOpen(false)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
            {/* ステータス履歴ダイアログ */}
            {showModalHistory ? (
                <AcceptStatusHistory showModal={showModalHistory} statusData={statusData} setShowModal={setShowModalHistory} />
            ) : (
                <></>
            )}
            {/* 登録ダイアログ */}
            {showModalRegist ? (
                <AcceptRegistModal
                    showModal={showModalRegist}
                    setShowModal={setShowModalRegist}
                    order={orderData}
                    beforeData={beforeData}
                    resultData={resultData}
                />
            ) : (
                <></>
            )}
            {/* 再読み込みダイアログ */}
            {showModalReload ? (
                <AcceptReloadModal
                    showModal={showModalReload}
                    setShowModal={setShowModalReload}
                    beforeData={beforeData}
                    resultData={resultData}
                    pageReload={PageReload}
                />
            ) : (
                <></>
            )}
        </>
    );
};
