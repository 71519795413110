import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { CheckBoxGroup } from '../../components/checkbox/CheckBoxGroup';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioJobCategory } from '../../components/radio/RadioJobCategory';
import { TableCell, TableHead, TableRow } from '../../components/table/TableStyled';
import { MESSAGE, PAGE_NAME, ROUTE_PATH } from '../../constants';
import LoginMainteService from '../../services/LoginMainte.service';
import AuthService from '../../services/auth.service';

//ヘッダー
export const LoginMainteUpdateHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <Stack direction="row" spacing={1}>
                {/* タイトル表示 */}
                <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                    {PAGE_NAME.LOGINMAINTE_UPDATE}
                </Typography>

                {/* 戻るボタン */}
                <Button
                    type="button"
                    variant="outlined"
                    sx={{ width: 115 }}
                    onClick={() => {
                        navigate(ROUTE_PATH.LOGINMAINTE, { state: { ...location.state, type: 'return' } });
                    }}
                >
                    戻る
                </Button>
            </Stack>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>ログインIDメンテナンス（変更・削除）を行います。</Typography>
        </>
    );
};

export const LoginMainteUpdate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
        onClose: () => ({}),
    });

    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<LoginMainteUpdateHeader />);
    }, []);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);
    const submit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        switch (buttonName) {
            case 'update':
                LoginMainteService.update(
                    data.loginid,
                    data.name,
                    data.namekana,
                    data.group,
                    data.tel,
                    data.fax,
                    data.email,
                    data.jobcategory,
                    AuthService.getCurrentUser().loginid,
                ).then(
                    (response) => {
                        //ユーザー登録成功
                        console.log('update success');

                        //ダイアログ内容設定
                        setDigProp({
                            ...digProp,
                            message: response.message,
                            onClose: () =>
                                navigate('../' + ROUTE_PATH.LOGINMAINTE, {
                                    state: { ...location.state, type: 'update' },
                                }),
                        });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                    (error) => {
                        //ユーザー登録エラー
                        console.log('update error');
                        //ダイアログ内容設定
                        setDigProp({ ...digProp, message: error.message });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                );
                break;
            case 'delete':
                //削除
                LoginMainteService.delete(data.loginid, AuthService.getCurrentUser().loginid).then(
                    (response) => {
                        //ユーザー削除成功
                        console.log('delete success');
                        //ダイアログ内容設定
                        setDigProp({
                            ...digProp,
                            message: response.message,
                            onClose: () =>
                                navigate('../' + ROUTE_PATH.LOGINMAINTE, {
                                    state: { ...location.state, type: 'delete' },
                                }),
                        });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                    (error) => {
                        //ユーザー削除エラー
                        console.log('delete error');
                        //ダイアログ内容設定
                        setDigProp({ ...digProp, message: error.message });
                        //ダイアログ表示
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                );
                break;
            default:
        }
    };

    const useFormMethods = useForm({
        defaultValues: {
            loginid: location.state.userinfo.login_id,
            name: location.state.userinfo.name,
            namekana: location.state.userinfo.name_kana,
            group: location.state.userinfo.group_cd,
            tel: location.state.userinfo.tel,
            fax: location.state.userinfo.fax,
            email: location.state.userinfo.email,
            jobcategory: location.state.userinfo.job_cat,
        },
    });

    const { handleSubmit } = useFormMethods;
    const [buttonName, setButtonName] = useState('');

    return (
        <>
            <FormProvider {...useFormMethods}>
                <form onSubmit={handleSubmit(submit)}>
                    <Box component="main">
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableHead>ログインID [必須]</TableHead>
                                            <TableCell>
                                                <InputTextBase name="loginid" disabled />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>氏名 [必須]</TableHead>
                                            <TableCell>
                                                <InputTextBase name="name" maxLength={20} required={MESSAGE.REQUIRED} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>氏名フリガナ [必須]</TableHead>
                                            <TableCell>
                                                <InputTextBase name="namekana" maxLength={20} required={MESSAGE.REQUIRED} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>営業所 [必須]</TableHead>
                                            <TableCell>
                                                <CheckBoxGroup name="group" value={location.state.userinfo.group_cd} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>TEL</TableHead>
                                            <TableCell>
                                                <InputTextBase name="tel" maxLength={15} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>FAX</TableHead>
                                            <TableCell>
                                                <InputTextBase name="fax" maxLength={15} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>E-Mailアドレス</TableHead>
                                            <TableCell>
                                                <InputTextBase name="email" maxLength={128} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead>職種区分</TableHead>
                                            <TableCell>
                                                <RadioJobCategory name="jobcategory" />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="error"
                                sx={{ width: 115 }}
                                disabled={processing || AuthService.getCurrentUser().loginid === location.state.userinfo.login_id}
                                onClick={() => {
                                    setButtonName('delete');
                                }}
                            >
                                削除
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ width: 115 }}
                                disabled={processing}
                                onClick={() => {
                                    setButtonName('update');
                                }}
                            >
                                変更を保存
                            </Button>
                        </Stack>
                    </Box>
                </form>
            </FormProvider>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => ''} //OK時の処理など
                onClose={digProp.onClose}
            />
        </>
    );
};
