/**
 * マスタ更新履歴照会　詳細モーダル画面
 * mst_name=仕入先の場合
 */
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import * as expfnc from '../../common/commonFunction';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import groups from '../../data/Group.json';
import status from '../../data/status.json';
import update from '../../data/updateCategory.json';

export const SupplierMstLogModal = (props) => {
    const overlay = {
        backgroundColor: 'rgba(0,0,0,0.85)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
    };
    const Modalcontent = {
        borderRadius: '4px',
        backgroundColor: '#F4F8FE',
        position: 'absolute',
        width: '90%',
        height: '90%',
        overflow: 'auto',
    };
    const group = props.datas.group_cd;
    const supplier_mail = props.datas.supplier_mail;
    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 7,
                                }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHeaderRow>
                                            <TableHead sx={{ width: 220 }}>更新日時</TableHead>
                                            <TableHead sx={{ width: 220 }}>処理区分</TableHead>
                                            <TableHead>更新者</TableHead>
                                        </TableHeaderRow>
                                        <TableRow>
                                            <TableCellRow>{props.datas.update_dt}</TableCellRow>
                                            <TableCellRow>{expfnc.getJsonData(update, props.datas.update_category)}</TableCellRow>
                                            <TableCellRow>{props.datas.update_name}</TableCellRow>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 2,
                                }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>部門</TableHead>
                                            <TableCell>
                                                {group !== null &&
                                                    expfnc.getComma(group).map((code, i) => {
                                                        if (expfnc.getComma(group).length - 1 === i) {
                                                            return expfnc.getJsonData(groups, code);
                                                        } else {
                                                            return expfnc.getJsonData(groups, code) + '，';
                                                        }
                                                    })}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>仕入先EDIコード</TableHead>
                                            <TableCell>{props.datas.supplier_cd}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>ログインID</TableHead>
                                            <TableCell>{props.datas.login_id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>仕入先名</TableHead>
                                            <TableCell>{props.datas.supplier_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>集計先（SC仕入集計先コード）</TableHead>
                                            <TableCell>
                                                {props.datas.sc_supplier_name}({props.datas.sc_supplier_cd})
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>仕入先メールアドレス</TableHead>
                                            <TableCell>
                                                {supplier_mail !== null &&
                                                    expfnc.getComma(supplier_mail).map((code, i) => {
                                                        if (expfnc.getComma(supplier_mail).length - 1 === i) {
                                                            return code;
                                                        } else {
                                                            return code + '，';
                                                        }
                                                    })}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableHead sx={{ width: 220 }}>状態</TableHead>
                                            <TableCell> {expfnc.getJsonData(status, props.datas.status)}</TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default SupplierMstLogModal;
