import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { InputTextEdiCd } from '../../components/input/InputTextEdiCd';
import { LOGIN_TYPE, MESSAGE, PAGE_NAME } from '../../constants';
import fileUploadType from '../../data/FileUploadType.json';
import fileDownloadService from '../../services/FileDownload.service';
import fileUploadService from '../../services/FileUpload.service';
import AuthService from '../../services/auth.service';
import { FileUploadHistoryResult } from './FileUploadHistoryResult';

//ヘッダー
export const FileUploadHistoryHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.FILE_UPLOAD_HISTORY}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                ファイルアップロード済みのファイル一覧を表示しています。
                <br />
                公開を中止する場合は削除ボタンを押してください。
            </Typography>
        </>
    );
};

//メインページ
export const FileUploadHistory = () => {
    const logintype = AuthService.getCurrentUser().logintype;
    const useFormMethods = useForm({
        defaultValues: {
            datefrom: new Date(), //期間From
            dateto: new Date(), //期間To
            //得意先コード
            //得意先名
            //仕入先コード
            //仕入先名
        },
    });

    const { handleSubmit, setValue } = useFormMethods;

    const [visible, setVisible] = useState(false);
    const [isNoData, setIsNoData] = useState(false);
    const [fileUpHistoryList, setFileUpHistoryList] = useState('');
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());

    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<FileUploadHistoryHeader />);
    }, []);

    useEffect(() => {
        // 初期表示時に submit 処理を実行
        setValue('datefrom', new Date());
        setValue('dateto', new Date());
        handleSubmit(submit)();
    }, []);

    //検索ボタン押下時
    const submit = (data) => {
        const fetchData = async () => {
            try {
                setIsNoData(false);
                setVisible(false);

                let datafrom = null;
                if (data.datefrom != null) {
                    datafrom = data.datefrom.toLocaleDateString('ja-JP');
                }
                let datato = null;
                if (data.dateto != null) {
                    datato = data.dateto.toLocaleDateString('ja-JP');
                }
                //仕入先の場合は得意先、仕入先の指定なし
                //自身の仕入先と仕入先全体のみ表示
                let customercd = '';
                let customername = '';
                let suppliercd = '';
                let suppliername = '';
                if (logintype === LOGIN_TYPE.ADMIN) {
                    customercd = data.customercd;
                    customername = data.customername;
                    suppliercd = data.suppliercd;
                    suppliername = data.suppliername;
                }
                if (logintype === LOGIN_TYPE.SUPPLIER) {
                    suppliercd = AuthService.getCurrentUser().edicd;
                }

                const response = await fileUploadService.search(datafrom, datato, customercd, customername, suppliercd, suppliername);

                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    const newDataPromises = response.map(async (item) => {
                        const fileUrl = await fileDownloadService.getFileCustomer(item.file_path, item.file_name);

                        return {
                            ...item,
                            fileUrl: fileUrl,
                        };
                    });

                    // Promise.all を使って全ての非同期処理が完了するのを待つ
                    const newData = await Promise.all(newDataPromises);

                    setFileUpHistoryList(newData);
                    setVisible(true);
                }
            } catch (error) {
                // 検索結果データ取得エラー
                console.log('getData error', error);
            }
        };

        fetchData();
    };

    const download = () => {
        let csvlist = [];
        for (let i = 0; i < fileUpHistoryList.length; i++) {
            let kyo = '';
            if (fileUpHistoryList[i].all_customer_flg === '1') {
                kyo = 'すべての得意先';
            } else if (fileUpHistoryList[i].all_supplier_flg === '1') {
                kyo = 'すべての仕入先';
            } else if (fileUpHistoryList[i].customer_name !== null && fileUpHistoryList[i].customer_name !== '') {
                kyo = fileUpHistoryList[i].customer_name;
            } else if (fileUpHistoryList[i].supplier_name !== null && fileUpHistoryList[i].supplier_name !== '') {
                kyo = fileUpHistoryList[i].supplier_name;
            }
            let del_dsp = '';
            if (fileUpHistoryList[i].delete_flag === '1') {
                del_dsp = '削除済み';
            }
            let uptype = '';
            if (fileUpHistoryList[i].upload_type === '0') {
                uptype = '手動';
            } else if (fileUpHistoryList[i].upload_type === '1') {
                uptype = '自動';
            }
            let mailerr = '';
            if (fileUpHistoryList[i].mail_err_flg === '1') {
                mailerr = 'エラー';
            }

            csvlist.push({
                アップロード日時: expfnc.datetostrformatFileUpDate(fileUpHistoryList[i].upload_date),
                共有先: kyo,
                ファイル名: expfnc.getJsonData(fileUploadType, fileUpHistoryList[i].file_type) + '：' + fileUpHistoryList[i].file_name,
                コメント: fileUpHistoryList[i].comment,
                削除: del_dsp,
                アップロードタイプ: uptype,
                メール送信: mailerr,
            });
        }
        //ファイル名
        let nowdate = expfnc.datetostrformat(new Date(), true, true); //時間
        let failename = 'ファイルアップロード履歴_' + nowdate + '.csv';
        expfnc.fileDownloadDialog(failename, expfnc.json2csv(csvlist, 1, 0));
    };

    const handleDateChangeFrom = (date) => {
        setSelectedDateFrom(date);
    };
    const handleDateChangeTo = (date) => {
        setSelectedDateTo(date);
    };

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ mx: 3, mt: 2 }}>
                            <Typography>期間</Typography>
                        </Box>
                        <Box alignItems="center" sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                            {/* 対象日付範囲選択 */}
                            <DatePickerBase name="datefrom" value={selectedDateFrom} onChange={handleDateChangeFrom} />
                            <Typography sx={{ flexGrow: 1, mx: 2 }}>～</Typography>
                            <DatePickerBase name="dateto" value={selectedDateTo} onChange={handleDateChangeTo} />
                        </Box>
                        {logintype === LOGIN_TYPE.ADMIN && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                {/* 得意先コード */}
                                <InputTextEdiCd name="customercd" placeholder="得意先コード" maxLength={13} sx={{ width: 600 }} />
                                {/* 得意先名 */}
                                <InputTextBase name="customername" placeholder="得意先名" sx={{ ml: 1 }} />
                                {/* 仕入先コード */}
                                <InputTextEdiCd name="suppliercd" placeholder="仕入先コード" maxLength={13} sx={{ ml: 1, width: 600 }} />
                                {/* 仕入先名 */}
                                <InputTextBase name="suppliername" placeholder="仕入先名" sx={{ ml: 1 }} />
                            </Box>
                        )}

                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button variant="contained" sx={{ width: 120 }} onClick={() => download()}>
                                ダウンロード
                            </Button>
                            <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                再表示
                            </Button>
                        </Stack>
                    </Paper>
                    {visible && (
                        <>
                            <Box sx={{ pt: 2 }}>
                                <FileUploadHistoryResult rows={fileUpHistoryList} />
                            </Box>
                        </>
                    )}
                    {isNoData && (
                        <Stack my={2} mr={2} direction="row" spacing={1}>
                            {/* エラーメッセージ */}
                            <Typography>{MESSAGE.FILE_UPLOAD_NO_DATA}</Typography>
                        </Stack>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
