/*
 *希望納品日テキスト表示
 */
import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

import * as expfnc from '../../common/commonFunction';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const TextDesiredDeliveryDate = ({ sx, value }) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                sx={sx}
                variant="standard"
                InputProps={{
                    readOnly: true,
                }}
                fullWidth
                label="希望納品日"
                InputLabelProps={{ shrink: true }}
                value={expfnc.datetostr(value)}
            />
        </ThemeProvider>
    );
};
