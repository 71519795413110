/**
 * 選択
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { JOB_CAT } from '../../constants';
import AuthService from '../../services/auth.service';

export const RadioShare = ({ name, handleValueChange }) => {
    const { control } = useFormContext();
    const [, setValue] = useState('00');
    const handleChange = (event) => {
        handleValueChange(event);
        setValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.status ? true : false}>
                    <RadioGroup
                        row
                        aria-labelledby="radio-group-label"
                        name={field.name}
                        defaultValue="00"
                        // onChange={(e) => handleValueChange(e)}
                        onChange={handleChange}
                        value={field.value === undefined ? '00' : field.value}
                    >
                        <FormControlLabel value="00" control={<Radio />} label="共有先を指定する" />
                        {AuthService.getCurrentUser().jobcat === JOB_CAT.ADMIN && (
                            <>
                                <FormControlLabel value="01" control={<Radio />} label="すべての得意先に共有する" />
                                <FormControlLabel value="02" control={<Radio />} label="すべての仕入先に共有する" />
                            </>
                        )}
                    </RadioGroup>
                    <FormHelperText>{errors.status?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
