/**
 * 送り状番号登録
 */
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioSearchTypeDate } from '../../components/radio/RadioSearchTypeDate';
import { RadioSenderRegist } from '../../components/radio/RadioSenderRegist';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { MESSAGE } from '../../constants';
import { LOGIN_TYPE, PAGE_NAME } from '../../constants';
import companylist from '../../data/shippingCompanyCode.json';
import InvoiceAcceptService from '../../services/InvoiceAccept.service';
import AuthService from '../../services/auth.service';
import { SendRegistResult } from './SendRegistResult';

//ヘッダー
export const AcceptHeader = () => {
    const tempFile = process.env.PUBLIC_URL + '/csv/送り状一括登録テンプレート.csv';

    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.SEND}
            </Typography>

            {/* 画面説明 */}

            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                送り状を一括登録する場合は、csvファイルをアップロードしてください。
                <Link href={tempFile} download>
                    テンプレートのダウンロードはこちら
                </Link>
            </Typography>
        </>
    );
};

//メインページ
export const SenderRegist = () => {
    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<AcceptHeader />);
    }, []);

    function getCompanyIdByCompanyName(companyName) {
        const company = companylist.find((item) => item.name === companyName);
        return company ? company.id : '99';
    }

    const onDrop = (acceptedFiles) => {
        //ファイル情報設定
        acceptedFiles.forEach((file) => {
            if (submitProcessing.current) return;
            submitProcessing.current = true;
            setProcessing(true);

            const reader = new FileReader();
            reader.onloadend = () => {
                const resultData = reader.result;

                const data = resultData.split('\r\n').map((row) => row.split(','));
                //選択したファイル内のテキスト内容取得

                //アップロード処理をする
                let csvList = [];
                //i=0は見出し行になる
                for (let i = 1; i < data.length; i++) {
                    if (data[i].length !== 4) {
                        //項目数違い
                        setDigProp({
                            ...digProp,
                            message: 'アップロードファイルの項目数を確認してください。',
                        });
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                        return;
                    }
                    csvList.push({
                        order_no: data[i][0], //注文番号
                        //運送会社を文字からコードに変換する
                        shipping_company_code: getCompanyIdByCompanyName(data[i][1]), //運送会社
                        destination_number: data[i][2], //送り状番号
                        number_of_pieces: data[i][3], //個口
                        login_id: AuthService.getCurrentUser().loginid,
                    });
                }
                InvoiceAcceptService.updateInvoice(csvList).then(
                    (response) => {
                        setDigProp({
                            ...digProp,
                            message: response.message,
                        });
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                    (error) => {
                        setDigProp({
                            ...digProp,
                            message: 'データ不備のため送り状一括登録に失敗しました。',
                        });
                        setDigOpen(true);
                        submitProcessing.current = false;
                        setProcessing(false);
                    },
                );
            };
            //選択したファイル内のテキスト内容取得
            reader.readAsText(file);
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        //選択できるファイル種類
        accept: { 'text/csv': ['.csv'] },
        // 最大で選択できるファイル数（1）
        maxFiles: 1,
    });

    const [senderRegist, setSenderRegist] = useState('01');

    const submit = (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        setIsNoData(false);
        setVisible(false);
        console.log(data);
        let datafrom = null;
        if (data.datefrom != null) {
            datafrom = data.datefrom.toLocaleDateString('ja-JP');
        }
        let datato = null;
        if (data.dateto != null) {
            datato = data.dateto.toLocaleDateString('ja-JP');
        }

        //ログインが仕入先の場合は自身の仕入先コードを検索条件とする
        let scd = data.suppliercd;
        if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
            scd = AuthService.getCurrentUser().edicd;
        }
        setDateFrom(data.datefrom);
        setDateTo(data.dateto);
        InvoiceAcceptService.searchInvoice(
            data.datetype,
            datafrom,
            datato,
            data.group,
            data.personname,
            data.orderno,
            data.propertyname,
            data.deliveryname,
            data.customername,
            scd,
            data.suppliername,
            data.senderregist,
        ).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    setVisible(true);
                    setResultData(response);
                }
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    //初期値
    const from = new Date();

    const to = new Date();

    to.setDate(from.getDate() + 3);

    //日付範囲初期値
    const [datefrom, setDateFrom] = useState(from);
    const [dateto, setDateTo] = useState(to);

    useEffect(() => {
        setValue('datefrom', from);
        setValue('dateto', to);
    }, []);

    const useFormMethods = useForm({
        defaultValues: {
            datetype: '02', //注文日、予定納品日、納品日選択
            datefrom: null, //対象日付範囲選択from
            dateto: null, //対象日付範囲選択to
            group: '', //営業所
            personname: '', //担当者
            orderno: '', //注文番号
            propertyname: '', //物件名
            deliveryaddress: '', //納品先
            customername: '', //客先
            suppliername: '', //仕入先名
            suppliercd: '', //仕入先コード
            senderregist: senderRegist, //送り状番号登録状態
        },
    });
    const { handleSubmit, setValue } = useFormMethods;
    const [resultData, setResultData] = useState();
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [digOpen, setDigOpen] = useState(false);
    //ダイアログ内の表示内容
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });
    //ラジオボタン選択時
    const handleValueChange = (event) => {
        setSenderRegist(event.target.value);
    };

    return (
        <>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(submit)}>
                        <Box component="main">
                            <Box sx={{ mx: 3, mt: 2 }}>
                                {/* 注文日、納品日選択 */}
                                <RadioSearchTypeDate name="datetype" />
                            </Box>
                            <Box alignItems="center" sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                                {/* 対象日付範囲選択 */}
                                <DatePickerBase name="datefrom" value={datefrom} />
                                <Typography sx={{ flexGrow: 1, mx: 2 }}>～</Typography>
                                <DatePickerBase name="dateto" value={dateto} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                {/* 注文番号入力 */}
                                <InputTextBase name="orderno" placeholder="注文番号" />
                                {/* 営業所選択 */}
                                <SelectSearchGroup name="group" label="営業所" fullWidth="true" sx={{ ml: 1 }} />
                                {/* 担当者入力 */}
                                <InputTextBase name="personname" placeholder="担当者名" sx={{ ml: 1 }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                {/* 物件名 */}
                                <InputTextBase name="propertyname" placeholder="物件名" />
                                {/* 納品先 */}
                                <InputTextBase name="deliveryname" placeholder="納品先" sx={{ ml: 1 }} />
                                {/* 客先 */}
                                <InputTextBase name="customername" placeholder="客先" sx={{ ml: 1 }} />
                            </Box>

                            {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                // 仕入先ログイン時は非表示
                                <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                    {/* 仕入先名 */}
                                    <InputTextBase name="suppliername" placeholder="仕入先名" />
                                    {/* 仕入先コード */}
                                    <InputTextBase name="suppliercd" placeholder="仕入先コード" sx={{ ml: 1 }} />
                                </Box>
                            )}
                            <Box sx={{ mx: 3, mt: 2 }}>
                                {/*送り状登録状態 */}
                                <RadioSenderRegist name="senderregist" handleValueChange={handleValueChange} />
                            </Box>

                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                    検索
                                </Button>
                                <div {...getRootProps()}>
                                    <Button variant="outlined" sx={{ width: 240, border: 'none' }} disabled={processing}>
                                        送り状番号一括登録はこちら
                                        <input {...getInputProps()} />
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                    </form>
                </FormProvider>
            </Paper>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => ''} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
            <Box>
                {visible && <SendRegistResult rows={resultData} />}
                <Stack my={2} mr={2} direction="row" spacing={1}>
                    {isNoData && <Typography>{MESSAGE.NO_DATA_ACCEPT}</Typography>}
                </Stack>
            </Box>
        </>
    );
};
