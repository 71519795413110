/*
 *ファイルアップロード：コメント入力
 */
import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const InputTextComment = React.forwardRef((props, ref) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField fullWidth {...props} ref={ref} />
        </ThemeProvider>
    );
});
