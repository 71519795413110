import React, { useRef, useState } from 'react';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { orderContext } from '../../components/MainLayout';
import { LOGIN_TYPE } from '../../constants';
import acceptStatus from '../../data/AcceptStatus.json';
import group from '../../data/Group.json';
import AcceptService from '../../services/Accept.service';
import AuthService from '../../services/auth.service';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    // backgroundColor: '#F4F8FE',
    backgroundColor: 'white',
    position: 'absolute',
    width: '817px',
    // height: '427px',
    overflow: 'auto',
};

export const AcceptRegistModal = (props) => {
    const [order] = useContext(orderContext);
    useEffect(() => {}, []);
    const { register, handleSubmit } = useForm();
    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const onSubmit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        //登録処理
        AcceptService.update(
            props.beforeData.orderNo, //注文番号
            props.beforeData.state, //変更前注文ステータス
            props.resultData.state, //注文ステータス
            expfnc.strtodate(props.resultData.orderDate), //予定納品日
            expfnc.strtodate(order.desired_delivery_date), //希望納品日
            order.delivery_time, //時間指定
            expfnc.getJsonData(group, order.group_cd), //営業所名
            order.person_name, //担当者名
            order.property_name, //物件名
            props.resultData.notifications, //注文注意事項
            data.notifications, //ステータス変更注意事項
            props.resultData.detail, //明細データ
            order.supplier_cd, //仕入先コード
            order.supplier_name, //仕入先名
            AuthService.getCurrentUser().loginid,
        ).then(
            (response) => {
                props.setShowModal(false);
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ mt: 3, mx: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                下記の変更を実行してよろしいですか？
                            </Typography>
                            <Typography color="error">
                                ※「計上完了」後は、御社による変更ができません。納期・金額確定後、「計上完了」としてください。
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                注文ステータス　：　
                                {expfnc.getJsonData(acceptStatus, props.beforeData.state)}
                                　→　
                                {expfnc.getJsonData(acceptStatus, props.resultData.state)}
                                <br />
                                注文合計金額　　：　￥
                                {props.beforeData.total.toLocaleString()}　→　 ￥{props.resultData.total.toLocaleString()}
                                <br />
                                納品予定日　　　：　{props.beforeData.orderDate}　→　
                                {props.resultData.orderDate}
                                {/* 仕入先の場合は連絡事項の表示 */}
                                {/* サンベックの場合はステータスコメント入力 */}
                                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER && (
                                    <>
                                        <Box sx={{ mt: 1 }} style={{ fontSize: 13 }}>
                                            注文に関する注意事項
                                        </Box>
                                        <Box style={{ fontSize: 13, whiteSpace: 'pre-line' }}>{props.resultData.notifications}</Box>
                                    </>
                                )}
                                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                    <>
                                        <Box sx={{ mt: 1 }} style={{ fontSize: 13 }}>
                                            ステータス変更に関する連絡事項がありましたら、下記に記載してください。
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                multiline
                                                fullWidth
                                                minRows={3}
                                                maxRows={3}
                                                placeholder="ステータス変更に関する連絡事項"
                                                {...register(`notifications`)}
                                            />
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Box sx={{ mt: 2 }}></Box>
                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button variant="text" sx={{ width: 115 }} onClick={() => props.setShowModal(false)}>
                                    キャンセル
                                </Button>
                                <Button type="submit" variant="contained" sx={{ width: 115 }} disabled={processing}>
                                    実行する
                                </Button>
                            </Stack>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </form>
    );
};
