import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import HelpIcon from '@mui/icons-material/Help';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import SideMenuList from '../SideMenuList.json';
import { ICON_COMPONENTS, JOB_CAT, LOGIN_TYPE, ROUTE_PATH } from '../constants';
import AuthService from '../services/auth.service';

const drawerWidth = 300;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

function ViewImage(props) {
    const isOpen = props.viewflg;
    if (isOpen) {
        return <img alt="logo" src={`${process.env.PUBLIC_URL}/images/sanbec-logo.png`} width="106" height="49" />;
    }
    return <div />;
}

function MainList() {
    let jsonList;
    let urltype;
    const loginType = AuthService.getCurrentUser().logintype;
    switch (loginType) {
        case LOGIN_TYPE.CUSTOMER:
            jsonList = SideMenuList.costomer;
            urltype = ROUTE_PATH.MAIN_CUSTOMER + '/';
            break;
        case LOGIN_TYPE.SUPPLIER:
            jsonList = SideMenuList.supplier;
            urltype = ROUTE_PATH.MAIN_SUPPLIER + '/';
            break;
        case LOGIN_TYPE.ADMIN:
            jsonList = SideMenuList.admin;
            urltype = ROUTE_PATH.MAIN_ADMIN + '/';
            break;
        default:
            jsonList = SideMenuList.admin;
            break;
    }
    return MenuItemList(jsonList, urltype);
}

function MainteList(props) {
    const jsonList = SideMenuList.mainte;
    const loginType = AuthService.getCurrentUser().logintype;
    const jobCat = AuthService.getCurrentUser().jobcat;
    if (loginType === LOGIN_TYPE.ADMIN && jobCat !== JOB_CAT.SALES) {
        //営業職は表示しない
        return (
            <>
                {props.open ? (
                    <>
                        <Typography sx={{ fontSize: 15, color: grey[500] }}>管理者メニュー</Typography>
                    </>
                ) : (
                    <></>
                )}
                {MenuItemList(jsonList, ROUTE_PATH.MAIN_ADMIN + '/')}
            </>
        );
    }
    return <></>;
}

function MenuItemList(list, urltype) {
    const location = useLocation();
    // const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const segmentList = location.pathname.split('/');
    const navigate = useNavigate();

    const menuItemList = list.map((item) => {
        const loginType = AuthService.getCurrentUser().logintype;
        const jobCat = AuthService.getCurrentUser().jobcat;
        if (loginType === LOGIN_TYPE.ADMIN && !item.auth.includes(jobCat)) {
            //ログインが社員で権限がない職種区分の場合は表示しない
            return <></>;
        }

        const Icon = ICON_COMPONENTS[item.icon];
        return (
            <div key={item.id}>
                <ListItemButton
                    // selected={location.pathname.includes(item.main)}
                    // selected={lastSegment === item.main}
                    selected={segmentList.some((segment) => segment === item.main)}
                    // to={urltype + item.main}
                    onClick={() => {
                        location.state = null;
                        navigate(urltype + item.main);
                    }}
                    sx={{
                        height: (theme) => theme.spacing(5),
                    }}
                >
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItemButton>
                <Divider />
            </div>
        );
    });
    return menuItemList;
}

export const SideMenu = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [urltype, setUrltype] = useState('');
    const [urllogout, setUrllogout] = useState('');
    const loginType = AuthService.getCurrentUser().logintype;
    useEffect(() => {
        switch (loginType) {
            case LOGIN_TYPE.CUSTOMER:
                setUrltype(ROUTE_PATH.MAIN_CUSTOMER + '/');
                setUrllogout(ROUTE_PATH.CUSTOMER);
                break;
            case LOGIN_TYPE.SUPPLIER:
                setUrltype(ROUTE_PATH.MAIN_SUPPLIER + '/');
                setUrllogout(ROUTE_PATH.SUPPLIER);
                break;
            case LOGIN_TYPE.ADMIN:
                setUrltype(ROUTE_PATH.MAIN_ADMIN + '/');
                setUrllogout(ROUTE_PATH.ADMIN);
                break;
            default:
                setUrltype(ROUTE_PATH.MAIN_ADMIN + '/');
                setUrllogout(ROUTE_PATH.ADMIN);
                break;
        }
    }, []);
    return (
        <>
            <Drawer
                PaperProps={{
                    style: {
                        justifyContent: 'space-between',
                    },
                }}
                variant="permanent"
                open={props.open}
            >
                <Box>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: props.open ? 'space-between' : 'center',
                            px: [1],
                        }}
                    >
                        <ViewImage viewflg={props.open} />
                        <IconButton onClick={props.toggleDrawer}>{props.open ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
                    </Toolbar>
                    <Divider />

                    <List component="nav">
                        {/* サイドメニュー表示アイテムリスト */}
                        <MainList />
                        <MainteList open={props.open} />
                    </List>
                </Box>
                {/* 以下のメニューはログイン種別にかかわらず固定 */}
                <List component="nav">
                    <div>
                        <ListItemButton
                            onClick={() => {
                                let pdfUrl = '';
                                switch (loginType) {
                                    case LOGIN_TYPE.CUSTOMER:
                                        pdfUrl = '/pdf/webedi_manual_c.pdf';
                                        break;
                                    case LOGIN_TYPE.SUPPLIER:
                                        pdfUrl = '/pdf/webedi_manual_s.pdf';
                                        break;
                                    case LOGIN_TYPE.ADMIN:
                                        pdfUrl = '/pdf/webedi_manual.pdf';
                                        break;
                                    default:
                                        break;
                                }
                                window.open(pdfUrl, '_blank');
                            }}
                            sx={{ height: (theme) => theme.spacing(5) }}
                        >
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="ご利用マニュアル" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton
                            onClick={() => {
                                location.state = null;
                                navigate(urltype + ROUTE_PATH.CHANGE_MAIL);
                            }}
                            sx={{ height: (theme) => theme.spacing(5) }}
                            selected={location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === ROUTE_PATH.CHANGE_MAIL}
                        >
                            <ListItemIcon>
                                <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="メール設定" />
                        </ListItemButton>
                        <Divider />

                        <ListItemButton
                            onClick={() => {
                                location.state = null;
                                navigate(urltype + ROUTE_PATH.CHANGE_PASSWORD);
                            }}
                            sx={{ height: (theme) => theme.spacing(5) }}
                            selected={location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === ROUTE_PATH.CHANGE_PASSWORD}
                        >
                            <ListItemIcon>
                                <LockResetIcon />
                            </ListItemIcon>
                            <ListItemText primary="パスワード変更" />
                        </ListItemButton>

                        <Divider />
                        <ListItemButton
                            onClick={() => {
                                AuthService.logout();
                                navigate(urllogout);
                            }}
                            sx={{ height: (theme) => theme.spacing(5) }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="ログアウト" />
                        </ListItemButton>
                        <Divider />
                    </div>
                </List>
            </Drawer>
        </>
    );
};
