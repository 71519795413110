import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { LOGIN_TYPE } from '../../constants';
import group from '../../data/Group.json';
import ModifySlipService from '../../services/ModifySlip.service';
import AuthService from '../../services/auth.service';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    backgroundColor: '#F4F8FE',
    position: 'absolute',
    top: '60px',
    left: '80px',
    right: '80px',
    bottom: '60px',
    overflow: 'auto',
};

export const ReturnsDetail = (props) => {
    const getData = props.datas;
    const [detailData, setDetailData] = useState([]);

    useEffect(() => {
        ModifySlipService.getDetail(getData.order_no).then(
            (response) => {
                console.log('getData');
                setDetailData(response);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <>
            {props.showModal && detailData.length !== 0 ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            {/* ボタンは右上に固定表示する */}
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    position: 'fixed',
                                    top: '70px',
                                    right: '120px',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>

                            {/* 計上日　伝票No. */}
                            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <Paper sx={{ width: '30%' }}>
                                    {/* 計上日 */}
                                    <Table>
                                        <TableHead sx={{ width: '20%' }}>計上日</TableHead>
                                        <TableCell
                                            sx={{
                                                alignItems: 'center',
                                                width: '80%',
                                            }}
                                        >
                                            {expfnc.datetostr(getData.record_date)}
                                        </TableCell>
                                    </Table>
                                </Paper>
                                <Paper sx={{ width: '70%' }}>
                                    {/* 伝票No */}
                                    <Table>
                                        <TableHead sx={{ width: '20%' }}>伝票No.</TableHead>
                                        <TableCell
                                            sx={{
                                                alignItems: 'center',

                                                width: '80%',
                                            }}
                                        >
                                            {getData.order_no}
                                        </TableCell>
                                    </Table>
                                </Paper>
                            </Box>

                            {/* 修正伝票ヘッダ情報 */}
                            <Paper sx={{ mt: 2 }}>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                {/* 営業所名 */}
                                                <TableHead sx={{ width: '10%' }}>営業所名</TableHead>
                                                <TableCell>{expfnc.getJsonData(group, getData.group_cd)}</TableCell>
                                            </TableRow>
                                            {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                                <TableRow>
                                                    {/* 仕入先 */}
                                                    <TableHead sx={{ width: '10%' }}>仕入先</TableHead>
                                                    <TableCell>{getData.supplier_name}</TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                {/* 担当者 */}
                                                <TableHead sx={{ width: '10%' }}>担当者名</TableHead>
                                                <TableCell>{getData.person_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {/* 備考 */}
                                                <TableHead sx={{ width: '10%' }}>備考</TableHead>
                                                <TableCell>{getData.remark}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                            {/* 注文内容 */}
                            <Box sx={{ mt: 2 }}>
                                <Typography>注文内容</Typography>
                                <Paper>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="result table">
                                            <TableHeaderRow>
                                                <TableRow>
                                                    <TableHead sx={{ width: 70, fontSize: '14px' }}>行番</TableHead>
                                                    <TableHead sx={{ fontSize: '14px' }}>
                                                        品名
                                                        <br />
                                                        <Box sx={{ fontWeight: 200 }}>(規格)</Box>
                                                    </TableHead>
                                                    <TableHead sx={{ width: 150, fontSize: '14px' }}>
                                                        数量
                                                        <br />
                                                        <Box sx={{ fontWeight: 200 }}>(単位)</Box>
                                                    </TableHead>
                                                    <TableHead sx={{ width: 250, fontSize: '14px' }}>
                                                        単価
                                                        <br />
                                                        <Box sx={{ fontWeight: 200 }}>(金額)</Box>
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeaderRow>
                                            <TableBody>
                                                {detailData.map((row) => (
                                                    <TableRow
                                                        sx={{
                                                            '&:last-child td, &:last-child th': {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        {/* 行番 */}
                                                        <TableCellRow sx={{ borderRight: 'none' }}>{('00' + row.lin_no).slice(-2)}</TableCellRow>
                                                        {/* 商品名・規格 */}
                                                        <TableCellRow sx={{ borderRight: 'none' }}>
                                                            {row.product_name}
                                                            <br />({row.standard})
                                                        </TableCellRow>
                                                        {/* 数量　単位 */}
                                                        <TableCellRow sx={{ borderRight: 'none' }}>
                                                            {row.order_quantity < 0 ? (
                                                                <Typography variant="body2" sx={{ color: 'red' }}>
                                                                    {row.order_quantity.toLocaleString()}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="body2">{row.order_quantity.toLocaleString()}</Typography>
                                                            )}
                                                            ({row.unit})
                                                        </TableCellRow>
                                                        {/* 単価　金額 */}
                                                        <TableCellRow sx={{ borderRight: 'none' }}>
                                                            ￥{row.unit_price.toLocaleString()}
                                                            <br />
                                                            {row.order_quantity * row.unit_price < 0 ? (
                                                                <Typography variant="body2" sx={{ color: 'red' }}>
                                                                    (￥
                                                                    {(row.order_quantity * row.unit_price).toLocaleString()})
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="body2">
                                                                    (￥
                                                                    {(row.order_quantity * row.unit_price).toLocaleString()})
                                                                </Typography>
                                                            )}
                                                        </TableCellRow>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>

                            {/* 合計金額　マイナスなら赤文字にする */}
                            <Stack my={2} direction="row" justifyContent="end" spacing={1}>
                                <Paper
                                    sx={{
                                        width: '40%',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Table>
                                        <TableHead sx={{ width: '20%' }}>合計金額</TableHead>
                                        <TableCell
                                            sx={{
                                                alignItems: 'center',
                                                textAlign: 'right',
                                                width: '80%',
                                            }}
                                        >
                                            {getData.result_price < 0 ? (
                                                <Typography sx={{ color: 'red' }}>￥{getData.result_price.toLocaleString()}</Typography>
                                            ) : (
                                                <Typography>￥{getData.result_price.toLocaleString()}</Typography>
                                            )}
                                        </TableCell>
                                    </Table>
                                </Paper>
                            </Stack>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default ReturnsDetail;
