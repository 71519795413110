import ApiClient from '../services/ApiClient';

class AcceptService {
    search(datetype, datefrom, dateto, group, personname, orderno, propertyname, deliveryname, customername, suppliercd, suppliername, status) {
        return ApiClient.get(
            'api/accept/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    datetype: datetype,
                    datefrom: datefrom,
                    dateto: dateto,
                    group: group,
                    personname: personname,
                    orderno: orderno,
                    propertyname: propertyname,
                    deliveryname: deliveryname,
                    customername: customername,
                    suppliercd: suppliercd,
                    suppliername: suppliername,
                    status: status,
                },
            },
        );
    }

    getOrder(orderno) {
        return ApiClient.get(
            'api/accept/searchorder',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    orderno: orderno,
                },
            },
        );
    }

    // 得意先用
    searchCustomer(
        datefrom, //予定納品日From
        dateto, //予定納品日To
        propertyname, //物件名
        deliveryname, //納品先
        personname, //担当者
        deliverydateflg, //納期未確定のみ表示
    ) {
        return ApiClient.get(
            'api/accept/searchCustomer',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    datefrom: datefrom,
                    dateto: dateto,
                    propertyname: propertyname,
                    deliveryname: deliveryname,
                    personname: personname,
                    deliverydateflg: deliverydateflg,
                },
            },
        );
    }

    getDetail(orderno) {
        return ApiClient.get(
            'api/accept/getDetail',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    orderno: orderno,
                },
            },
        );
    }

    getDetailCustomer(jdnno) {
        return ApiClient.get(
            'api/accept/getDetailCustomer',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    jdnno: jdnno,
                },
            },
        );
    }

    getStatusLog(orderno) {
        return ApiClient.get(
            'api/accept/getHistory',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    orderno: orderno,
                },
            },
        );
    }

    update(
        order_no,
        before_order_status,
        order_status,
        scheduled_delivery_date,
        desired_delivery_date,
        specification,
        group_name,
        person_name,
        property_name,
        notifications,
        status_notifications,
        detail,
        supplier_cd,
        supplier_name,
        update_id,
    ) {
        return ApiClient.post(
            'api/accept/update',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                order_no: order_no, //注文番号
                before_order_status: before_order_status, //変更前の注文ステータス
                order_status: order_status, //注文ステータス
                scheduled_delivery_date: scheduled_delivery_date, //予定納品日
                desired_delivery_date: desired_delivery_date,
                specification: specification,
                group_name: group_name,
                person_name: person_name,
                property_name: property_name,
                notifications: notifications, //注文注意事項
                status_notifications: status_notifications,
                detail: detail, //明細データ
                supplier_cd: supplier_cd,
                supplier_name: supplier_name,
                update_id: update_id, //更新者ID
            },
        );
    }

    updateStatus(order_no, order_status, update_id) {
        return ApiClient.post(
            'api/accept/updateStatus',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                order_no: order_no, //注文番号
                order_status: order_status, //注文ステータス
                update_id: update_id, //更新者ID
            },
        );
    }

    updateOrderIssue(order_no) {
        return ApiClient.post(
            'api/accept/updateOrderIssue',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                order_no: order_no, //注文番号
                before_order_status: '', //変更前の注文ステータス
                order_status: '', //注文ステータス
                scheduled_delivery_date: '', //予定納品日
                desired_delivery_date: '',
                specification: '',
                group_name: '',
                person_name: '',
                property_name: '',
                notifications: '', //注文注意事項
                status_notifications: '',
                detail: null, //明細データ
                supplier_cd: '',
                supplier_name: '',
                update_id: '', //更新者ID
            },
        );
    }

    getFileattached(orderno, index, filename) {
        return ApiClient.getFileAttached(orderno, index, filename);
    }
}

export default new AcceptService();
