/**
 * 得意先マスタ検索
 */
import React, { useContext, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { MESSAGE, PAGE_NAME } from '../../constants';
import { CustomerMainteResult } from '../../pages/maintenance/CustomerMainteResult';
import CustomerMainteService from '../../services/CustomerMainte.service';

//ヘッダー
export const CustomerMainteHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.CUSTOMERMAINTE}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>得意先の一覧を表示します。得意先EDIコードを選択すると詳細画面が表示されます。</Typography>
        </>
    );
};

//メインページ
export const CustomerMainte = () => {
    const setHeader = useContext(headerContext);
    const useFormMethods = useForm({
        defaultValues: {
            customer_code: '',
            customer_name: '',
        },
    });
    const { handleSubmit } = useFormMethods;
    const [customerMainteList, setCustomerMainteList] = useState('');
    const [visible, setVisible] = useState(false);
    const [isNoData, setIsNoData] = useState(true);
    const [msg, setMsg] = useState(MESSAGE.SEARCH);

    useLayoutEffect(() => {
        setHeader(<CustomerMainteHeader />);
    }, []);
    const submit = (data) => {
        setVisible(false);
        setIsNoData(false);
        let customer_cd = data.customer_code;
        if (customer_cd !== '') {
            //得意先コードが入力されていたら13文字になるよう前を0埋めにする
            customer_cd = customer_cd.toString().padStart(13, '0');
        }

        CustomerMainteService.search(customer_cd, data.customer_name).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    setVisible(false);
                    setIsNoData(true);
                    setMsg(MESSAGE.NO_DATA_CUSTOMERMAINTE);
                } else {
                    setVisible(true);
                    setCustomerMainteList(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                setIsNoData(false);
                setVisible(false);
            },
        );
    };

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                m: 2,
                            }}
                        >
                            <InputTextBase name="customer_code" placeholder="得意先EDIコード" maxLength={13} sx={{ mr: 2, width: '30%' }} />

                            <InputTextBase name="customer_name" placeholder="得意先名" maxLength={125} sx={{ width: '70%' }} />
                        </Box>

                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                検索
                            </Button>
                        </Stack>
                    </Paper>

                    {visible && (
                        <Box sx={{ pt: 2 }}>
                            <CustomerMainteResult rows={customerMainteList} />
                        </Box>
                    )}
                    {isNoData && (
                        <Box sx={{ m: 2 }}>
                            {/* <Typography>{MESSAGE.NO_DATA_CUSTOMERMAINTE}</Typography> */}
                            <Typography sx={{ flexGrow: 1, mb: 1 }}>{msg}</Typography>
                        </Box>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
