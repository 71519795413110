import ApiClient from '../services/ApiClient';

class ResultDataDownloadServise {
    download(order_no, datefrom, dateto, group_cd, supplier_cd, type) {
        return ApiClient.get(
            'api/resultdata/download',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    order_no: order_no,
                    datefrom: datefrom,
                    dateto: dateto,
                    group_cd: group_cd,
                    supplier_cd: supplier_cd,
                    type: type,
                },
            },
        );
    }
}

export default new ResultDataDownloadServise();
