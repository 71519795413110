import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { CheckBoxSearchStatus } from '../../components/checkbox/CheckBoxSearchStatus';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { RadioSearchTypeDate } from '../../components/radio/RadioSearchTypeDate';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { ACCEPT_STATUS, MESSAGE } from '../../constants';
import { LOGIN_TYPE, PAGE_NAME } from '../../constants';
import acceptStatus from '../../data/AcceptStatus.json';
import AcceptService from '../../services/Accept.service';
import AuthService from '../../services/auth.service';
import { AcceptResult } from './AcceptResult';

export const acceptDataListContext = React.createContext();

//ヘッダー
export const AcceptHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.ACCEPT}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                注文番号を選択すると注文状況詳細画面が表示されます。
                <br />
                注文ステータス履歴を確認する場合は、注文ステータスを選択してください。
            </Typography>
        </>
    );
};

//メインページ
export const Accept = () => {
    // 初期処理でヘッダー設定
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<AcceptHeader />);
    }, []);

    const location = useLocation();
    // 入力項目の初期値設定
    const useFormMethods = useForm({
        defaultValues: {
            datetype: '02', //注文日、納品日選択
            datefrom: null, //対象日付範囲選択from
            dateto: null, //対象日付範囲選択to
            group: '', //営業所
            personname: '', //担当者
            orderno: '', //注文番号
            propertyname: '', //物件名
            deliveryname: '', //納品先
            customername: '', //客先
            suppliername: '', //仕入先名
            suppliercd: '', //仕入先コード
            status: '', //注文ステータス
        },
    });

    const params = useParams();
    useEffect(() => {
        // 検索条件
        if (params.order !== void 0 && params.order !== null) {
            setValue('orderno', params.order);
            handleSubmit(submit)();
        }
        if (location.state !== void 0 && location.state !== null && location.state.formData !== void 0 && location.state.formData !== null) {
            setValue('datetype', location.state.formData.datetype);
            setValue('datefrom', location.state.formData.datefrom);
            setValue('dateto', location.state.formData.dateto);
            setValue('group', location.state.formData.group);
            setValue('personname', location.state.formData.personname);
            setValue('orderno', location.state.formData.orderno);
            setValue('propertyname', location.state.formData.propertyname);
            setValue('deliveryname', location.state.formData.deliveryname);
            setValue('customername', location.state.formData.customername);
            setValue('suppliername', location.state.formData.suppliername);
            setValue('suppliercd', location.state.formData.suppliercd);
            setValue('status', location.state.formData.status);

            handleSubmit(submit)();
        }
    }, []);

    const { handleSubmit, setValue } = useFormMethods;
    const [acceptDataList, setAcceptDataList] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [formData, setformData] = useState('');
    const [dateform, setDateForm] = useState(null);
    const [dateto, setDateTo] = useState(null);
    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    // 検索ボタン押下時の処理
    const submit = (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        console.log(data);
        setIsNoData(false);
        setVisible(false);
        setformData(data);
        //保持データ表示用 日付
        setDateForm(data.datefrom);
        setDateTo(data.dateto);
        let datafrom = null;
        if (data.datefrom != null) {
            datafrom = data.datefrom.toLocaleDateString('ja-JP');
        }
        let datato = null;
        if (data.dateto != null) {
            datato = data.dateto.toLocaleDateString('ja-JP');
        }

        //ログインが仕入先の場合は自身の仕入先コードを検索条件とする
        let scd = data.suppliercd;
        if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
            scd = AuthService.getCurrentUser().edicd;
        }

        //選択したステータスがキャンセルを含むのみの場合はすべてのステータスを条件とする
        //なにも選択されていない場合はキャンセル以外を条件とする
        let setstate = '';
        if (ACCEPT_STATUS.CANCEL === data.status || data.status === '') {
            acceptStatus.forEach((st) => {
                if (setstate !== '') setstate = setstate + ',';
                if (!(data.status === '' && st.id === '08')) {
                    setstate = setstate + st.id;
                }
            });
        } else {
            setstate = data.status;
        }

        AcceptService.search(
            data.datetype,
            datafrom,
            datato,
            data.group,
            data.personname,
            data.orderno,
            data.propertyname,
            data.deliveryname,
            data.customername,
            scd,
            data.suppliername,
            setstate,
        ).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    setVisible(true);
                    setAcceptDataList(response);
                }
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    return (
        <acceptDataListContext.Provider value={[acceptDataList, setAcceptDataList]}>
            <FormProvider {...useFormMethods}>
                <form onSubmit={handleSubmit(submit)}>
                    <Box component="main">
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box sx={{ mx: 3, mt: 2 }}>
                                {/* 注文日、納品日選択 */}
                                <RadioSearchTypeDate name="datetype" />
                            </Box>
                            <Box alignItems="center" sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                                {/* 対象日付範囲選択 */}
                                {/* setValueでvalueが設定されていても表示されないため、直接valueを設定する */}
                                <DatePickerBase name="datefrom" value={dateform} />
                                <Typography sx={{ flexGrow: 1, mx: 2 }}>～</Typography>
                                <DatePickerBase name="dateto" value={dateto} />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                {/* 注文番号入力 */}
                                <InputTextBase name="orderno" placeholder="注文番号" />
                                {/* 営業所選択 */}
                                <SelectSearchGroup name="group" label="営業所" fullWidth="true" sx={{ ml: 1 }} />
                                {/* 担当者入力 */}
                                <InputTextBase name="personname" placeholder="担当者名" sx={{ ml: 1 }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                {/* 物件名 */}
                                <InputTextBase name="propertyname" placeholder="物件名" />
                                {/* 納品先 */}
                                <InputTextBase name="deliveryname" placeholder="納品先" sx={{ ml: 1 }} />
                                {/* 客先 */}
                                <InputTextBase name="customername" placeholder="客先" sx={{ ml: 1 }} />
                            </Box>

                            {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                // 仕入先ログイン時は非表示
                                <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                                    {/* 仕入先名 */}
                                    <InputTextBase name="suppliername" placeholder="仕入先名" />
                                    {/* 仕入先コード */}
                                    <InputTextBase name="suppliercd" placeholder="仕入先コード" sx={{ ml: 1 }} />
                                </Box>
                            )}

                            <Box sx={{ mx: 3, mt: 2 }}>
                                {/* 注文ステータス */}
                                <CheckBoxSearchStatus name="status" />
                            </Box>

                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button type="submit" variant="contained" sx={{ width: 115 }} disabled={processing}>
                                    検索
                                </Button>
                            </Stack>
                        </Paper>

                        {visible && (
                            <>
                                <Box sx={{ pt: 2 }}>
                                    <AcceptResult rows={acceptDataList} formData={formData} />
                                </Box>
                            </>
                        )}
                        {isNoData && (
                            <Stack my={2} mr={2} direction="row" spacing={1}>
                                <Typography>{MESSAGE.NO_DATA_ACCEPT}</Typography>
                            </Stack>
                        )}
                    </Box>
                </form>
            </FormProvider>
        </acceptDataListContext.Provider>
    );
};
