/**
 * 日付変換（曜日付き）
 * @param {Date} targetDate 対象日付
 * @param {boolean} isWeek 曜日付与(true:曜日あり、false:曜日なし)
 * @param {boolean} isTime 時間付与(true:時間あり、false:時間なし)
 * @returns 日付文字列
 */
export const datetostr = function (targetDate, isWeek, isTime) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = String(date.getFullYear()); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var w = date.getDay(); // 曜日を取得（数値）
    var hh = ('00' + String(date.getHours())).slice(-2); // 時間を取得
    var mi = ('00' + String(date.getMinutes())).slice(-2); // 分を取得
    var sep = '/';
    var sepweeks = '(';
    var sepweeke = ')';

    // 曜日を数値から文字列に変換するための配列
    var week = ['日', '月', '火', '水', '木', '金', '土'];
    var result = '';
    if (isWeek) {
        result = yyyy + sep + mm + sep + dd + sep + sepweeks + week[w] + sepweeke;
    } else {
        result = yyyy + sep + mm + sep + dd;
    }

    if (isTime) {
        result = result + ' ' + hh + ':' + mi;
    }

    return result;
};

export const datetostr_filename = function (targetDate, isTime) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = date.getFullYear(); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var hh = ('00' + String(date.getHours())).slice(-2); // 時間を取得
    var mi = ('00' + String(date.getMinutes())).slice(-2); // 分を取得

    var result = yyyy + mm + dd;
    if (isTime) {
        result = result + hh + mi;
    }

    return result;
};

/**
 * 日付変換（ミリ秒まで）
 * @param {Date} targetDate 対象日付
 * @param {boolean} isMills ミリ秒付与(true:ミリ秒あり、false:ミリ秒なし)
 * @returns 日付文字列
 */
export const datetostr_mills = function (targetDate, isMills) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = String(date.getFullYear()); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var hh = ('00' + String(date.getHours())).slice(-2); // 時間を取得
    var mi = ('00' + String(date.getMinutes())).slice(-2); // 分を取得
    var ss = ('00' + date.getSeconds()).slice(-2); // 秒を取得
    var mil = String(date.getMilliseconds()); //ミリ秒を取得

    var result = '';

    result = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + mi + ':' + ss;
    if (isMills) {
        result = result + '.' + mil;
    }

    return result;
};

/**
 * 日付変換（yyyyMMdd形式）
 * @param {Date} targetDate 対象日付
 * @param {boolean} isHours 時間付与(true:時間あり、false:時間なし)
 * @param {boolean} isMinutes 分付与(true:分あり、false:分なし)
 * @returns 日付文字列
 */
export const datetostrformat = function (targetDate, isHours, isMinutes) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = date.getFullYear(); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var hh = ('00' + date.getHours()).slice(-2); // 時間を取得
    var mi = ('00' + date.getMinutes()).slice(-2); // 分を取得

    var result = yyyy + mm + dd;

    if (isHours) {
        result = result + hh;
    }
    if (isMinutes) {
        result = result + mi;
    }
    return result;
};

/**
 * 日付変換（ファイルアップロード日時用）
 * @param {Date} targetDate 対象日付
 * @returns 日付文字列
 */
export const datetostrformatFileUpDate = function (targetDate) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = date.getFullYear(); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var hh = ('00' + date.getHours()).slice(-2); // 時間を取得
    var mi = ('00' + date.getMinutes()).slice(-2); // 分を取得
    var ss = ('00' + date.getSeconds()).slice(-2); // 秒を取得

    var result = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + mi + ':' + ss;

    return result;
};

/**
 * 日付変換（ファイルダウンロード日時用）
 * @param {Date} targetDate 対象日付
 * @returns 日付文字列（yyyyMmddhhmmss）
 */
export const datetostrformatFileDownDate = function (targetDate) {
    if (targetDate === null || targetDate === void 0) return '';
    var date = new Date(targetDate);
    var yyyy = date.getFullYear(); // 西暦を取得
    var mm = ('00' + (date.getMonth() + 1)).slice(-2); // 月を取得（返り値は実際の月-1なので、+1する）
    var dd = ('00' + date.getDate()).slice(-2); // 日を取得
    var hh = ('00' + date.getHours()).slice(-2); // 時間を取得
    var mi = ('00' + date.getMinutes()).slice(-2); // 分を取得
    var ss = ('00' + date.getSeconds()).slice(-2); // 秒を取得

    var result = yyyy + mm + dd + hh + mi + ss;

    return result;
};

/**
 * 文字列から日付へ変換
 * 変換不可の場合はnullを返却する
 * @param {String} targetString
 * @returns 日付型
 */
export const strtodate = function (targetString) {
    if (targetString === null || targetString === void 0) return null;
    let dateObject = new Date(targetString);
    if (isNaN(dateObject.getDate())) return null;
    return dateObject;
};

/**
 * jsonファイルから対象idのnameを取得
 * @param {json} targetJson 対象のjsonファイル
 * @param {string} id 取得対象のid
 * @returns
 */
export const getJsonData = function (targetJson, id) {
    var ret = '';
    targetJson.forEach((json) => {
        if (id === json.id) {
            ret = json.name;
        }
    });

    return ret;
};

/**
 * ファイルパスからファイル名のみを取得する
 * @param {String} filepath
 */
export const getFileName = function (filepath) {
    if (filepath === null || filepath === void 0) return '';
    var filename = filepath.split('/').pop().split('\\').pop();
    return filename;
};

/**

 * カンマ区切りのデータを配列に加工する
 * @param {String} commaStr
 */
export const getComma = function (commaStr) {
    if (commaStr === null || commaStr === '') return '';
    var commaList = commaStr.split(',');
    return commaList;
};

/* jsonデータをcsvとして取得する
 * @param {String} json
 * @param {String} title 見出し行有無 0:なし、1:あり
 * @param {String} separator 項目区切り 0:カンマ、1:タブ
 * @returns csvファイル
 */
export const json2csv = function (json, title, separator) {
    var sep = separator === 0 ? ',' : '\t';
    var header = Object.keys(json[0]).join(sep) + '\n';
    var body = json
        .map(function (d) {
            return Object.keys(d)
                .map(function (key) {
                    var value = d[key] !== null ? String(d[key]) : '';
                    return '"' + value.replace(/"/g, '""') + '"';
                })
                .join(sep);
        })
        .join('\n');

    var result = title === 0 ? body : header + body;

    return result;
};

/**
 * CSVファイルダウンロード処理
 * @param {String} fileName ダウンロードするファイル名
 * @param {*} csvfile 対象のcsvデータ
 */
export const fileDownload = function (fileName, csvfile) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM
    const dataWithBom = new Blob([bom, csvfile], { type: 'text/csv' });

    const jsonURL = window.URL.createObjectURL(dataWithBom);
    const link = document.createElement('a');
    document.body.appendChild(link);

    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();

    document.body.removeChild(link);
};

/**
 * ファイルダウンロード処理（ダイアログ表示）
 * @param {String} fileName ダウンロードするファイル名
 * @param {*} csvfile 対象のcsvデータ
 */
// export const fileDownloadDialog = async function (fileName, csvfile) {
//     try {
//         // ファイルをどこにどんな名前で保存するか訊くダイアログを表示
//         const saveHandle = await window.showSaveFilePicker({ suggestedName: fileName });
//         // 保存先ファイルに書き込み準備
//         const writable = await saveHandle.createWritable();
//         await writable.write(csvfile);
//         await writable.close();
//     } catch (e) {
//         //console.error(e);
//         //キャンセルした場合DOMExceptionが発生する
//     }
// };
export const fileDownloadDialog = function (fileName, content) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM
    const blobWithBom = new Blob([bom, content], { type: 'text/csv' });

    const url = URL.createObjectURL(blobWithBom);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none'; // 非表示にすることでクリックイベントを発生させる
    document.body.appendChild(a);

    a.click(); // クリックイベントを発生させてダウンロードを開始
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
};
