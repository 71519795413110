/**
 * 仕入先マスタ　検索結果
 */
import React, { useState } from 'react';

import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import * as expfnc from '../../common/commonFunction';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import groupsJson from '../../data/Group.json';
import { SupplierMainteModal } from './SupplierMainteModal';

export const SupplierMainteResult = (props) => {
    const [visibleRows, setVisibleRows] = useState(props.data);
    const [showModal, setShowModal] = useState(false);
    const [resultData, setResultData] = useState();
    const ModalStatus = (row) => {
        const groupCdArray = row.group_cd ? expfnc.getComma(row.group_cd) : [];
        const transformedArray = groupCdArray.map((code) => expfnc.getJsonData(groupsJson, code));
        const transformedString = transformedArray.join(',');
        const newRow = {
            ...row,
            groupCdArray: transformedString,
        };

        //モーダル表示処理
        setShowModal(true);
        setResultData(newRow);
    };
    return (
        <>
            <div>
                <TableSearchResult rows={props.data} setVisibleRows={setVisibleRows}>
                    <TableHeaderRow>
                        <TableRow>
                            <TableHead sx={{ textAlign: 'left', width: 120 }}>仕入先EDIコード</TableHead>
                            <TableHead sx={{ textAlign: 'left' }}>仕入先名</TableHead>
                        </TableRow>
                    </TableHeaderRow>
                    <TableBody>
                        {visibleRows.map((row) => (
                            <TableRow key={row.supplier_cd}>
                                <TableCellRow sx={{ textAlign: 'center' }}>
                                    {/* 仕入先EDIコード */}
                                    <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ModalStatus(row)}>
                                        {row.supplier_cd}
                                    </Link>
                                </TableCellRow>
                                {/* 仕入先名 */}
                                <TableCellRow>{row.supplier_name}</TableCellRow>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableSearchResult>
                {/* フロート画面 */}
                {showModal ? <SupplierMainteModal showModal={showModal} datas={resultData} setShowModal={setShowModal} /> : <></>}
            </div>
        </>
    );
};
