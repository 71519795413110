import ApiClient from './ApiClient';

class AcceptPrintService {
    search(
        datetype,
        datefrom,
        dateto,
        orderno,
        group,
        personname,
        propertyname,
        deliveryaddress,
        customername,
        suppliercd,
        suppliername,
        status,
        printstatus,
    ) {
        return ApiClient.get(
            'api/accept/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    datetype: datetype,
                    datefrom: datefrom,
                    dateto: dateto,
                    orderno: orderno,
                    group: group,
                    personname: personname,
                    propertyname: propertyname,
                    deliveryaddress: deliveryaddress,
                    customername: customername,
                    suppliercd: suppliercd,
                    suppliername: suppliername,
                    status: status,
                    printstatus: printstatus,
                },
            },
        );
    }
}

export default new AcceptPrintService();
