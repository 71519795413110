/**
 * 送り状番号登録検索結果部分
 */
import { useContext, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { Link, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import muiTableCell from '@mui/material/TableCell';
import muiTableHead from '@mui/material/TableHead';
import muiTableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import CommonDialog from '../../components/CommonDialog';
import { detailContext, orderContext, totalContext } from '../../components/MainLayout';
import { SelectOrderDisp } from '../../components/select/SelectOrderDisp';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { LOGIN_TYPE } from '../../constants';
import acceptStatus from '../../data/AcceptStatus.json';
import group from '../../data/Group.json';
import company from '../../data/shippingCompanyCode.json';
import AcceptService from '../../services/Accept.service';
import InvoiceAcceptService from '../../services/InvoiceAccept.service';
import AuthService from '../../services/auth.service';
import { AcceptDetailModal } from './AcceptDetailModal';
import { AcceptStatusHistory } from './AcceptStatusHistory';

const TableHead = styled(muiTableHead)(() => ({
    borderBottom: 'solid',
    borderBottomColor: grey[200],
    borderBottomWidth: '2px',
}));
const TableHeadCellTop = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableHeadCell = styled(muiTableCell)(() => ({
    //fontWeight: 700,
    fontSize: '13px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableBodyCell = styled(muiTableCell)(() => ({
    fontSize: '13px',
    textAlign: 'left',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableRow = styled(muiTableRow)({
    height: 10,
});
export const SendRegistResult = (props) => {
    const getData = props.rows;
    const [visibleRows, setVisibleRows] = useState(props.rows);
    const [sortRows, setSortRows] = useState(props.rows);
    const [showModalHistory, setShowModalHistory] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [statusData, setStatusData] = useState();
    const [, setOrder] = useContext(orderContext);
    const [, setTotal] = useContext(totalContext);
    const [, setDetail] = useContext(detailContext);
    const [digOpen, setDigOpen] = useState(false);
    //ダイアログ内の表示内容
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });
    const [selectedOrder, setSelectedOrder] = useState('delivery_date');

    const ViewDetail = (row) => {
        setOrder(row); //注文情報を設定
        //明細情報取得、設定
        AcceptService.getDetail(row.order_no).then(
            (response) => {
                //検索結果データ取得成功
                setDetail(response);

                //合計金額計算
                let t = 0;
                response.forEach((row) => {
                    if (row.cancel_kbn === '1') return; //キャンセルされたものは除く
                    t = t + row.unit_price * row.order_quantity;
                });
                setTotal(t);

                //サンベック
                setShowModalDetail(true); //モーダル表示
            },
            (error) => {
                //検索結果データ取得エラー
            },
        );
    };

    const ModalStatusHistory = (row) => {
        setOrder(row); //注文情報を設定
        //ステータス履歴取得
        AcceptService.getStatusLog(row.order_no).then(
            (response) => {
                //ステータス履歴データ取得成功
                console.log('getData success');
                // フロート画面表示用
                setShowModalHistory(true);
                // setResultData(row);
                setStatusData(response);
            },
            (error) => {
                //ステータス履歴データ取得エラー
                console.log('getData error');
            },
        );
    };

    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            items: [],
        },
    });

    const { replace } = useFieldArray({
        name: 'items',
        control,
    });

    const useFormMethods = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        replace(getData);
    }, [replace]);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);
    //登録ボタン押下時の処理
    const registButton = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        console.log(data);
        //取得したデータ内で不要なデータをのぞく
        let dataList = [];
        for (let i = 0; i < data.items.length; i++) {
            dataList.push({
                order_no: data.items[i].order_no, //注文番号
                shipping_company_code: data.items[i].shipping_company_code, //運送会社
                destination_number: data.items[i].destination_number, //送り状番号
                number_of_pieces: data.items[i].number_of_pieces, //個口
                login_id: AuthService.getCurrentUser().loginid,
            });
        }
        InvoiceAcceptService.updateInvoice(dataList).then(
            (response) => {
                console.log(response);
                setDigProp({
                    ...digProp,
                    message: '送り状番号が登録されました。',
                });
                setDigOpen(true);
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    };

    const handleOrderChange = (event) => {
        const newSelectedOrder = event.target.value;
        setSelectedOrder(newSelectedOrder);
        const sortedData = [...props.rows].sort((a, b) => {
            if (newSelectedOrder === 'order_no') {
                return b[newSelectedOrder] - a[newSelectedOrder];
            } else {
                const dateAValue = a.scheduled_delivery_date ? a.scheduled_delivery_date : a.desired_delivery_date;
                const dateBValue = b.scheduled_delivery_date ? b.scheduled_delivery_date : b.desired_delivery_date;
                return new Date(dateBValue) - new Date(dateAValue);
            }
        });
        setVisibleRows(sortedData);
        setSortRows(sortedData);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <SelectOrderDisp selectedOrder={selectedOrder} onOrderChange={handleOrderChange} />

            <FormProvider {...useFormMethods}>
                <form id="registform" onSubmit={handleSubmit(registButton)}>
                    <TableSearchResult rows={sortRows} setVisibleRows={setVisibleRows}>
                        <TableHead>
                            <TableRow>
                                <TableHeadCellTop sx={{ width: 180 }}>注文番号</TableHeadCellTop>
                                <TableHeadCellTop sx={{ width: 180 }}>注文日時</TableHeadCellTop>
                                <TableHeadCellTop>物件名・客先</TableHeadCellTop>
                                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                    <TableHeadCellTop rowSpan={3}>仕入先</TableHeadCellTop>
                                )}
                                <TableHeadCellTop rowSpan={3}>注文ステータス</TableHeadCellTop>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>営業所名</TableHeadCell>
                                <TableHeadCell>希望納品日</TableHeadCell>
                                <TableHeadCell>住所</TableHeadCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>担当者名</TableHeadCell>
                                <TableHeadCell>予定納品日</TableHeadCell>
                                <TableHeadCell>運送会社・送り状番号・個口</TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.map((row, index) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={row.id}>
                                    <TableCell>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableBodyCell sx={{ padding: 0 }}>
                                                        {/* 注文番号 */}
                                                        <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ViewDetail(row)}>
                                                            {row.order_no}
                                                        </Link>
                                                    </TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 営業所名 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{expfnc.getJsonData(group, row.group_cd)}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 担当者名 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{row.person_name}</TableBodyCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <TableCell>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    {/* 注文日時 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{expfnc.datetostr(row.order_date, false, true)}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 希望納品日 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{expfnc.datetostr(row.desired_delivery_date)}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 予定納品日 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{expfnc.datetostr(row.scheduled_delivery_date)}</TableBodyCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <TableCell>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    {/* 物件名・客先 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>
                                                        {row.property_name}
                                                        {row.customer_name != null && <>・{row.customer_name}</>}
                                                    </TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 住所 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{row.delivery_address}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableBodyCell sx={{ padding: 0 }}>
                                                        <FormControl variant="standard" sx={{ minWidth: 150, mr: 2 }}>
                                                            <InputLabel>運送会社</InputLabel>
                                                            <Select
                                                                label="運送会社"
                                                                labelId="select-label"
                                                                defaultValue={row.shipping_company_code}
                                                                {...register(`items.${index}.shipping_company_code`)}
                                                            >
                                                                {company.map((row, index) => (
                                                                    <MenuItem key={index} value={row.id}>
                                                                        {row.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{ width: 300, mr: 2, mt: 2 }}
                                                            defaultValue={row.destination_number}
                                                            inputProps={{ maxLength: 12 }}
                                                            placeholder="送り状番号"
                                                            label="送り状番号"
                                                            {...register(`items.${index}.destination_number`)}
                                                        />
                                                        {/* <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            sx={{ width: 90, mt: 2 }}
                                                            defaultValue={row.number_of_pieces}
                                                            placeholder="個口"
                                                            label="個口"
                                                            {...register(`items.${index}.number_of_pieces`)}
                                                        /> */}
                                                        <Controller
                                                            control={control}
                                                            {...register(`items.${index}.number_of_pieces`)}
                                                            render={({ field: { onChange, name, value } }) => (
                                                                <NumericFormat
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{ width: 90, mt: 2 }}
                                                                    placeholder="個口"
                                                                    label="個口"
                                                                    customInput={TextField}
                                                                    thousandSeparator={true}
                                                                    thousandsGroupStyle="thousand"
                                                                    decimalScale={0}
                                                                    name={name}
                                                                    defaultValue={row.number_of_pieces}
                                                                    maxLength={9}
                                                                    value={value}
                                                                    onValueChange={(values) => {
                                                                        onChange(values.value);
                                                                    }}
                                                                    format={false}
                                                                />
                                                            )}
                                                        />
                                                    </TableBodyCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    {/* 仕入先 */}
                                    {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                        <TableCell sx={{ padding: 0 }}>
                                            <Tooltip title={row.supplier_name}>
                                                <Typography>{row.supplier_name}</Typography>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    {/* 注文ステータス */}
                                    <TableCell sx={{ padding: 0 }}>
                                        <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ModalStatusHistory(row)}>
                                            {expfnc.getJsonData(acceptStatus, row.order_status)}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableSearchResult>

                    <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                        <Button form="registform" type="submit" variant="contained" sx={{ width: 115 }} disabled={processing}>
                            登録
                        </Button>
                    </Stack>
                    <CommonDialog
                        title={digProp.title}
                        message={digProp.message}
                        buttonType={digProp.buttonType}
                        open={digOpen}
                        onAccept={() => ''} //OK時の処理など
                        onClose={() => setDigOpen(false)}
                    />
                </form>
            </FormProvider>
            {showModalHistory ? (
                <AcceptStatusHistory
                    showModal={showModalHistory}
                    // orderData={resultData}
                    statusData={statusData}
                    setShowModal={setShowModalHistory}
                />
            ) : (
                <></>
            )}
            {showModalDetail ? (
                <AcceptDetailModal
                    showModal={showModalDetail}
                    // orderData={resultData}
                    setShowModal={setShowModalDetail}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};
