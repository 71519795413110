import ApiClient from './ApiClient';

class FileUploadService {
    upload(sharetype, detail) {
        return ApiClient.post(
            'api/fileupload/upload',
            (response) => {
                console.log(response);

                return response;
            },
            {
                sharetype: sharetype,
                detail: detail,
            },
        );
    }

    search(datefrom, dateto, customercd, customername, suppliercd, suppliername) {
        return ApiClient.get(
            'api/fileupload/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                params: {
                    datefrom: datefrom,
                    dateto: dateto,
                    customercd: customercd,
                    customername: customername,
                    suppliercd: suppliercd,
                    suppliername: suppliername,
                },
            },
        );
    }

    searchcustomer(customercd) {
        return ApiClient.get(
            'api/fileupload/searchcustomer',
            (response) => {
                console.log(response);

                return response;
            },
            {
                params: {
                    customercd: customercd,
                },
            },
        );
    }

    delete(id, update_dt) {
        return ApiClient.post(
            'api/fileupload/delete',
            (response) => {
                console.log(response);

                return response;
            },
            {
                id: id,
                update_dt: update_dt,
            },
        );
    }
}

export default new FileUploadService();
