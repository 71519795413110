/**
 * 修正伝票
 */
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useEffect } from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import muiTableCell from '@mui/material/TableCell';
import muiTableHead from '@mui/material/TableHead';
import muiTableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { headerContext } from '../../components/MainLayout';
import { TableResultNotCount } from '../../components/table/TableResultNotCount';
import { LOGIN_TYPE, PAGE_NAME } from '../../constants';
import ModifySlipService from '../../services/ModifySlip.service';
import AuthService from '../../services/auth.service';
import ReturnsDetail from './ReturnsDetail';

const TableHead = styled(muiTableHead)(() => ({
    borderBottom: 'solid',
    borderBottomColor: grey[200],
    borderBottomWidth: '2px',
}));
const TableHeadCellTop = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '13px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableHeadCell = styled(muiTableCell)(() => ({
    //fontWeight: 700,
    fontSize: '13px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableBodyCell = styled(muiTableCell)(() => ({
    fontSize: '13px',
    textAlign: 'left',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableRow = styled(muiTableRow)({
    height: 10,
});

//ヘッダー
export const ReturnsHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.RETURNS}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>返品や値引きなど、修正伝票の一覧です。伝票No.を選択すると詳細画面が表示されます。</Typography>
        </>
    );
};

//メインページ
export const Returns = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<ReturnsHeader />);
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [visibleRows, setVisibleRows] = useState();
    //Modal画面の検索用
    const [modalData, setModalData] = useState();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        ModifySlipService.searchAll().then(
            (response) => {
                if (response.length === 0) {
                    setVisible(false);
                } else {
                    setResultData(response);
                    setVisibleRows(response);
                    setVisible(true);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <Box component="main">
            {visible ? (
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TableResultNotCount rows={visibleRows} setVisibleRows={setResultData}>
                        <TableHead>
                            <TableRow>
                                <TableHeadCellTop sx={{ width: 180 }}>伝票No.</TableHeadCellTop>
                                <TableHeadCellTop sx={{ width: 180 }}>計上日</TableHeadCellTop>
                                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                    <TableHeadCellTop rowSpan={3}>仕入先</TableHeadCellTop>
                                )}
                                <TableHeadCellTop rowSpan={3}>備考</TableHeadCellTop>
                                <TableHeadCellTop rowSpan={3}>コメント</TableHeadCellTop>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>営業所名</TableHeadCell>
                                <TableHeadCell>合計金額</TableHeadCell>
                            </TableRow>
                            <TableRow>
                                <TableHeadCell>担当者名</TableHeadCell>
                                <TableHeadCell></TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resultData.map((row) => (
                                <TableRow
                                    key={row.order_no}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        backgroundColor: row.delete_flag === '1' ? '#ccc' : 'transparent',
                                    }}
                                >
                                    <TableCell>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableBodyCell sx={{ padding: 0 }}>
                                                        {/* 伝票番号 */}
                                                        <Link
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setModalData(row);
                                                            }}
                                                        >
                                                            {row.order_no}
                                                        </Link>
                                                    </TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 営業所名 */}
                                                    {/* <TableBodyCell sx={{ padding: 0 }}>{expfnc.getJsonData(group, row.group_cd)}</TableBodyCell> */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{row.group_name}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 担当者名 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{row.person_name}</TableBodyCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <TableCell>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    {/* 計上日 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{expfnc.datetostr(row.record_date)}</TableBodyCell>
                                                </TableRow>
                                                <TableRow>
                                                    {/* 合計金額 */}
                                                    <TableBodyCell sx={{ padding: 0 }}>{row.result_price.toLocaleString()}</TableBodyCell>
                                                </TableRow>
                                                <TableRow></TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>

                                    {/* 仕入先 */}
                                    {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                        <TableCell sx={{ padding: 0 }}>
                                            <Tooltip title={row.supplier_name}>
                                                <Typography>{row.supplier_name}</Typography>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    {/* 備考 */}
                                    <TableCell sx={{ padding: 0 }}>{row.remark}</TableCell>
                                    {/* コメント*/}
                                    <TableCell sx={{ padding: 0, textAlign: 'left' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            {row.comment1 && <div>{row.comment1}</div>}
                                            {row.comment2 && <div>{row.comment2}</div>}
                                            {row.comment3 && <div>{row.comment3}</div>}
                                            {row.comment4 && <div>{row.comment4}</div>}
                                            {row.comment5 && <div>{row.comment5}</div>}
                                            {row.comment6 && <div>{row.comment6}</div>}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableResultNotCount>
                </Paper>
            ) : (
                <Typography>対象の修正伝票はありません。</Typography>
            )}
            {showModal ? <ReturnsDetail showModal={showModal} datas={modalData} setShowModal={setShowModal} /> : <></>}
        </Box>
    );
};
