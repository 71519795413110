import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import LayersIcon from '@mui/icons-material/Layers';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleIcon from '@mui/icons-material/People';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import UploadFileIcon from '@mui/icons-material/UploadFile';

//APIのURL
//mst
export const API_URL = 'http://system.sanbec.co.jp/';
//dev
//export const API_URL = 'http://192.168.0.151/';
////local
//export const API_URL = 'http://localhost:8080/';

//検索結果表示件数
export const ROW_PER_PAGE_OPTIONS = [5, 10, 50];

//検索結果表示件数_初期値
export const ROWS_PER_PAGE = 10;

// 最大ファイルサイズ（メガバイト単位）
export const MAX_FILE_SIZE_MB = 5;

//アイコン取得用
export const ICON_COMPONENTS = {
    BarChartIcon: BarChartIcon,
    BusinessIcon: BusinessIcon,
    DashboardIcon: DashboardIcon,
    DownloadIcon: DownloadIcon,
    DownloadForOfflineIcon: DownloadForOfflineIcon,
    GetAppIcon: GetAppIcon,
    FileUploadIcon: FileUploadIcon,
    FileDownloadIcon: FileDownloadIcon,
    HistoryEduIcon: HistoryEduIcon,
    LayersIcon: LayersIcon,
    MenuOpenIcon: MenuOpenIcon,
    MoveToInboxIcon: MoveToInboxIcon,
    ManageAccountsIcon: ManageAccountsIcon,
    ManageHistoryIcon: ManageHistoryIcon,
    InsertPageBreakIcon: InsertPageBreakIcon,
    ReceiptLongIcon: ReceiptLongIcon,
    PeopleIcon: PeopleIcon,
    PlagiarismIcon: PlagiarismIcon,
    PictureAsPdfIcon: PictureAsPdfIcon,
    LocalShippingIcon: LocalShippingIcon,
    UploadFileIcon: UploadFileIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    StorefrontIcon: StorefrontIcon,
    DownloadTwoToneIcon: DownloadTwoToneIcon,
    FileDownloadOutlinedIcon: FileDownloadOutlinedIcon,
    FileUploadOutlinedIcon: FileUploadOutlinedIcon,
};

//ルーティング用パス
export const ROUTE_PATH = {
    CUSTOMER: '/customerlogin', //得意先用ログイン
    SUPPLIER: '/supplierlogin', //仕入先用ログイン
    ADMIN: '/adminlogin', //管理者用ログイン

    MAIN_CUSTOMER: '/customer', //メインレイアウト用（得意先）
    MAIN_SUPPLIER: '/supplier', //メインレイアウト用（仕入先）
    MAIN_ADMIN: '/admin', //メインレイアウト用（管理者）

    ACCEPT: 'accept', //注文状況検索（仕入先・管理者・得意先）
    ACCEPT_DETAIL: 'accept/detail', //注文状況詳細
    ACCEPT_STATUS_HISTORY: 'accept/status', //注文ステータス履歴
    ACCEPT_PRINT: 'acceptprint', //注文書発行

    FILE_DOWNLOAD: 'filedownload', //ファイルダウンロード
    FILE_UPLOAD: 'fileupload', //ファイルアップロード
    FILE_UPLOAD_HISTORY: 'fileuphistory', //ファイルアップロード履歴
    DELIVERYSLIP_INVOICEDOWNLOAD: 'releasefiledownload', //請求書・納品書等ダウンロード

    MASTERLOG: 'masterlog', //マスタ更新ログ照会
    MASTER_DOWNLOAD: 'masterdownload', //マスタダウンロード

    INVOICE_REGIST: 'invoiceregist', //送り状番号登録
    CORRECTION_SLIP: 'modifyslip', //修正伝票画面
    // ORDER_HISTORY: 'orderhistory', //注文確認データ履歴照会
    ACEPPT_UPLOAD: 'acceptdataupload', //注文確認データアップロード
    ACEPPT_UPLOAD_LOG: 'acceptdatauploadlog', //注文確認データ履歴照会
    ACEPPT_UPLOAD_SET: 'acceptdataupload/setting', //注文確認データ設定一覧
    ACEPPT_UPLOAD_SETREG: 'acceptdataupload/setting/regist', //注文確認データ設定登録

    ACTUAL_DOWNLOAD: 'resultdatadownload', //実績データダウンロード
    LOGINMAINTE: 'loginmainte', //ログインIDマスタ登録画面
    LOGINMAINTE_REGIST: 'loginmainte/regist', //ログインIDマスタ登録画面
    LOGINMAINTE_UPDATE: 'loginmainte/update', //ログインIDマスタ更新画面
    SUPPLIERMAINTE: 'suppliermainte', //仕入先マスタ
    SUPPLIERMAINTE_REGIST: 'suppliermainte/regist', //仕入先マスタ登録画面
    CUSTOMERMAINTE: 'customermainte', //得意先マスタ
    CUSTOMERMAINTE_REGIST: 'customermainte/regist', //得意先マスタ登録画面

    CHANGE_MAIL: 'changemail', //メールアドレス変更
    CHANGE_PASSWORD: 'changepassword', //パスワード変更
    LOGOUT: 'logout', //ログアウト
};

//画面名
export const PAGE_NAME = {
    LOGIN: 'サンベックWebEDI',
    ACCEPT: '注文状況検索',
    ACCEPT_DETAIL: '注文状況詳細',

    FILE_UPLOAD: 'ファイルアップロード',
    FILE_UPLOAD_HISTORY: 'ファイルアップロード履歴照会',
    FILE_DOWNLOAD: 'ファイルダウンロード',
    RETURNS: '修正伝票照会',
    MASTERLOG: 'マスタ更新履歴',
    SEND: '送り状番号登録',
    ACEPPT_UPLOAD: '注文確認データアップロード',
    ORDER_UP_HIS: '注文確認データアップロード履歴照会',
    RESULTDATA_DOWNLOAD: '実績データダウンロード',
    ACCEPT_PRINT: '注文書発行',
    DELIVERYSLIP_INVOICEDOWNLOAD: '納品書・請求書ダウンロード',

    LOGINMAINTE: 'ログインIDマスタ',
    LOGINMAINTE_REGIST: 'ログインIDマスタ（新規登録）',
    LOGINMAINTE_UPDATE: 'ログインIDマスタ（変更・削除）',
    CUSTOMERMAINTE: '得意先マスタ',
    CUSTOMERMAINTE_REGIST: '得意先マスタ(変更・削除)',
    SUPPLIERMAINTE: '仕入先マスタ',
    SUPPLIERMAINTE_UPDATE: '仕入先マスタ（変更・削除）',

    CHANGEMAILADDRES: 'メール設定',
    CHANGEPASSWORD: 'パスワード変更',
    MASTERDOWNLOAD: 'マスタダウンロード',
};

//メッセージ
export const MESSAGE = {
    LOGIN_HELP: '※ログインできない場合はこちら',
    LOGIN_ERROR: '入力されたログイン情報に誤りがあります。',
    SEARCH: '条件を入力し、検索ボタンを押下してください。',
    NO_DATA_LOGINID: '対象のユーザーIDはありません。',
    NO_DATA_ACCEPT: '対象のご注文はありません。',
    NO_DATA_CUSTOMERMAINTE: '対象の得意先がありません。',
    NO_DATA_SUPPLIER: '対象の仕入先がありません。',
    NO_DATA: '対象のデータがありません。',
    REQUIRED: '入力必須項目です',
    ERR_UNIQUE_DATA: '指定されたIDはすでに登録されているため、変更を保存できません。',
    PASS_CHANGE_ERR_NO_CNG: '新しいパスワードは現在のパスワードから変更してください',
    PASS_CHANGE_ERR_CNF: '新しいパスワードが確認用のパスワードと違います',
    PASS_CHANGE_OK: 'パスワードを更新しました',
    FILE_UPLOAD_ORVER: '１回のアップロードは10件までになります。',
    FILE_UPLOAD_SIZE_ORVER: '１回のアップロードサイズ上限は5MBまでになります。',
    FILE_UPLOAD_DELETE: 'この項目は一度、削除すると復元することができません。\n削除してよろしいですか？',
    FILE_UPLOAD_NO_DATA: '対象のアップロード履歴はありません。',
};

//ログインID種別
export const LOGIN_TYPE = {
    CUSTOMER: '01', //得意先
    SUPPLIER: '02', //仕入先
    ADMIN: '03', //管理者
};

//職種区分
export const JOB_CAT = {
    ADMIN: '1', //管理者
    SALES: '2', //営業
    OFFICE: '3', //事務
    PAYABLE: '4', //買掛
};
//注文ステータス
export const ACCEPT_STATUS = {
    NEW: '01', //新規注文
    CHANGE: '02', //注文変更あり
    WAIT: '03', //確定待ち
    DELIVERY: '04', //納期確定
    AMOUNT: '05', //金額確定
    ACCOUNTING: '06', //計上完了
    PROXY: '07', //代理更新中
    CANCEL: '08', //キャンセル
};

//注文書発行ステータス
//01:注文書未発行、02:変更あり、03:注文書発行済み
export const ACCEPT_ISSUE_STATUS = {
    NEW: '01', //注文書未発行
    CHANGE: '02', //変更あり
    ISSUED: '03', //注文書発行済み
};

//編集不可フラグ
export const UNABLE_EDIT_FLG = {
    LOCK: '0', //ロック
    RELEASE: '1', //解除
};
