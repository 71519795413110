import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { headerContext } from '../../components/MainLayout';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { PAGE_NAME } from '../../constants';
import AcceptDataUploadService from '../../services/AcceptDataUpload.service';
import { AcceptDetailModal } from '../accept/AcceptDetailModal';
import AcceptUploadDetail from './AcceptUploadDetail';
import AcceptUploadError from './AcceptUploadError';

//ヘッダー
export const OrderUploadHisHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.ORDER_UP_HIS}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>
                アップロードの履歴を表示しています。（過去6ヶ月分）
                <br />
                アップロード日時を選択すると明細単位の処理結果が表示されます。
            </Typography>
        </>
    );
};

//メインページ
export const OrderUploadHis = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<OrderUploadHisHeader />);
    }, []);

    const [resultData, setResultData] = useState();
    const [visibleRows, setVisibleRows] = useState();
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [datas, setDatas] = useState();

    //注文状況詳細モーダル画面の表示制御用
    const [showModal, setShowModal] = useState(false);
    //エラー詳細モーダル画面の表示制御用
    const [errorModal, setErrorModal] = useState(false);

    useEffect(() => {
        AcceptDataUploadService.uploadLog().then(
            (response) => {
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                } else {
                    setResultData(response);
                    setVisibleRows(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <Box component="main" sx={{ m: 2 }}>
            {resultData != null ? (
                <div>
                    <TableSearchResult rows={resultData} setVisibleRows={setVisibleRows}>
                        <TableHeaderRow>
                            <TableRow>
                                <TableHead sx={{ width: 200 }}>アップロード日時</TableHead>
                                <TableHead sx={{ textAlign: 'left' }}>コメント</TableHead>
                                <TableHead sx={{ textAlign: 'left', width: 100 }}>正常（件）</TableHead>
                                <TableHead sx={{ textAlign: 'left', width: 100 }}>エラー（件）</TableHead>
                            </TableRow>
                        </TableHeaderRow>
                        {visibleRows.map((rows) => (
                            <TableBody>
                                <TableCellRow>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        underline="always"
                                        onClick={() => {
                                            // フロート画面表示用
                                            setShowDetailModal(true);
                                            setModalData(rows);
                                        }}
                                    >
                                        {expfnc.datetostr_mills(rows.upload_date, false)}
                                    </Link>
                                </TableCellRow>
                                <TableCellRow>{rows.comment}</TableCellRow>
                                <TableCellRow sx={{ textAlign: 'right' }}>{rows.normal_count}</TableCellRow>
                                {rows.error_count !== 0 ? (
                                    <TableCellRow
                                        sx={{
                                            background: 'rgba(244,67,54,0.3)',
                                            textAlign: 'right',
                                        }}
                                    >
                                        {rows.error_count}
                                    </TableCellRow>
                                ) : (
                                    <TableCellRow sx={{ textAlign: 'right' }}>{rows.error_count}</TableCellRow>
                                )}
                            </TableBody>
                        ))}
                    </TableSearchResult>
                    {showDetailModal ? (
                        //注文確認データ履歴詳細モーダル
                        <AcceptUploadDetail
                            showDetailModal={showDetailModal}
                            datas={modalData}
                            setShowDetailModal={setShowDetailModal}
                            setErrorModal={setErrorModal}
                            setShowModal={setShowModal}
                            setDatas={setDatas}
                        />
                    ) : (
                        <></>
                    )}
                    {errorModal ? (
                        //注文確認データ履歴エラー詳細モーダル
                        <AcceptUploadError
                            setErrorModal={setErrorModal}
                            errorModal={errorModal}
                            setShowDetailModal={setShowDetailModal}
                            datas={datas}
                        />
                    ) : (
                        <></>
                    )}
                    {showModal ? <AcceptDetailModal setShowModal={setShowModal} showModal={showModal} /> : <></>}
                </div>
            ) : (
                <Typography>過去6ヶ月以内にアップロードされたデータはありません。</Typography>
            )}
        </Box>
    );
};
