/**
 * 仕入先マスタフロート画面
 */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import * as expfnc from '../../common/commonFunction';
import CommonDialog from '../../components/CommonDialog';
import CommonDialogRes from '../../components/CommonDialog';
import { TableCell, TableHead, TableRow } from '../../components/table/TableStyled';
import { JOB_CAT } from '../../constants';
import status from '../../data/status.json';
import SupplierMainteService from '../../services/SupplierMainte.service';
import AuthService from '../../services/auth.service';

export const SupplierMainteModal = (props) => {
    const overlay = {
        backgroundColor: 'rgba(0,0,0,0.85)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
    };
    const Modalcontent = {
        borderRadius: '4px',
        backgroundColor: '#F4F8FE',
        position: 'absolute',
        width: '90%',
        height: '90%',
        overflow: 'auto',
    };

    const [getMail, setGetMail] = useState([]);
    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: 'パスワード初期化',
        message: 'パスワードを初期化します。よろしいですか？',
        buttonType: 'YesNo',
        onAccept: () => initPassword(),
        onClose: () => setDigOpen(false),
    });
    const [digOpenRes, setDigOpenRes] = useState(false);
    const [digPropRes, setDigPropRes] = useState({
        title: 'パスワード初期化',
        message: 'パスワードを初期化しました。',
        buttonType: 'OkOnly',
        onClose: () => setDigOpenRes(false),
    });

    const initPassword = async () => {
        //パスワード初期化
        //ログインIDをパスワードとする
        setDigOpenRes(false);
        const newpass = props.datas.login_id;
        SupplierMainteService.initpass(props.datas.supplier_cd, newpass).then(
            (response) => {
                //成功
                //ダイアログ内容設定
                setDigPropRes({
                    ...digPropRes,
                    message: 'パスワードを初期化しました。',
                    buttonType: 'OkOnly',
                });
                //ダイアログ表示
                setDigOpenRes(true);
            },
            (error) => {
                //エラー
                setDigPropRes({
                    ...digPropRes,
                    message: 'パスワードの初期化に失敗しました。',
                    buttonType: 'OkOnly',
                });
                //ダイアログ表示
                setDigOpenRes(true);
            },
        );
    };

    //選択したlogin_idと一致するメールアドレスを取得
    useEffect(() => {
        SupplierMainteService.mailSeach(props.datas.supplier_cd).then(
            (response) => {
                //検索結果データ取得成功
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                } else {
                    setGetMail(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        if (!digOpen && !digOpenRes) {
                            props.setShowModal(false);
                        }
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                }}
                            >
                                {AuthService.getCurrentUser().jobcat === JOB_CAT.ADMIN && (
                                    <Button
                                        variant="outlined"
                                        sx={{ width: 160, mr: 2 }}
                                        onClick={() => {
                                            //ダイアログ表示
                                            setDigOpen(true);
                                        }}
                                    >
                                        パスワード初期化
                                    </Button>
                                )}
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 7,
                                }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableHead sx={{ width: '210px' }}>部門</TableHead>
                                                <TableCell>{props.datas.groupCdArray}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>仕入先EDIコード</TableHead>
                                                <TableCell>{props.datas.supplier_cd}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>ログインID</TableHead>
                                                <TableCell>{props.datas.login_id}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>仕入先名</TableHead>
                                                <TableCell>{props.datas.supplier_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>集計先（SC仕入集計先コード）</TableHead>
                                                <TableCell>
                                                    {props.datas.sc_supplier_name}({props.datas.sc_supplier_cd})
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>仕入先メールアドレス</TableHead>
                                                <TableCell>
                                                    {getMail.map((mail, i) => {
                                                        if (getMail.length - 1 === i) {
                                                            return mail;
                                                        } else {
                                                            return mail + '，';
                                                        }
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableHead>状態</TableHead>
                                                <TableCell>{expfnc.getJsonData(status, props.datas.status)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                    <CommonDialog
                        title={digProp.title}
                        message={digProp.message}
                        buttonType={digProp.buttonType}
                        open={digOpen}
                        onAccept={digProp.onAccept} //OK時の処理など
                        onClose={digProp.onClose}
                    />
                    <CommonDialogRes
                        title={digPropRes.title}
                        message={digPropRes.message}
                        buttonType={digPropRes.buttonType}
                        open={digOpenRes}
                        onAccept={() => {}}
                        onClose={digPropRes.onClose}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default SupplierMainteModal;
