/**
 * 得意先マスタ検索結果
 */
import React, { useState } from 'react';

import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import * as expfnc from '../../common/commonFunction';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import groupsJson from '../../data/Group.json';
import { CustomerMainteModal } from './CustomerMainteModal';

export const CustomerMainteResult = (props) => {
    //部門表示編集
    const [visibleRows, setVisibleRows] = useState(props.rows);
    const [showModal, setShowModal] = useState(false);
    const [resultData, setResultData] = useState();
    const ModalStatus = (row) => {
        const groupCdArray = row.group_cd ? expfnc.getComma(row.group_cd) : [];
        const transformedArray = groupCdArray.map((code) => expfnc.getJsonData(groupsJson, code));
        const transformedString = transformedArray.join(',');
        const newRow = {
            ...row,
            groupCdArray: transformedString,
        };

        //モーダル表示処理
        setShowModal(true);
        setResultData(newRow);
    };

    return (
        <>
            <TableSearchResult rows={props.rows} setVisibleRows={setVisibleRows}>
                <TableHeaderRow>
                    <TableRow>
                        <TableHead sx={{ width: 130 }}>得意先コード</TableHead>
                        <TableHead sx={{ textAlign: 'left' }}>得意先名</TableHead>
                    </TableRow>
                </TableHeaderRow>
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow key={row.customer_cd}>
                            <TableCellRow sx={{ textAlign: 'center' }}>
                                {/* 得意先コード */}
                                <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ModalStatus(row)}>
                                    {row.customer_cd}
                                </Link>
                            </TableCellRow>
                            <TableCellRow>
                                {/* 得意先名 */}
                                {row.customer_name}
                            </TableCellRow>
                        </TableRow>
                    ))}
                </TableBody>
            </TableSearchResult>

            {showModal ? <CustomerMainteModal showModal={showModal} orderData={resultData} setShowModal={setShowModal} /> : <></>}
        </>
    );
};
