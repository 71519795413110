import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ROUTE_PATH } from '../../constants';
import { LOGIN_TYPE, MESSAGE, PAGE_NAME } from '../../constants';
import AuthService from '../../services/auth.service';
import { InputTextLoginId } from './InputTextLoginId';
import { InputTextPassword } from './InputTextPassword';

const defaultTheme = createTheme();

export const LoginCustomer = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const useFormMethods = useForm({
        defaultValues: {
            loginid: '',
            password: '',
        },
    });
    const { handleSubmit } = useFormMethods;
    const params = useParams();
    const location = useLocation();
    useEffect(() => {
        const user = AuthService.getCurrentUser();
        console.log('Current Path:', location.pathname);
        if (location.pathname === '/customer/download') {
            //保持されているユーザーが仕入先かチェックする
            if (user !== null && user.logintype === LOGIN_TYPE.CUSTOMER) {
                navigate(ROUTE_PATH.MAIN_CUSTOMER + '/' + ROUTE_PATH.DELIVERYSLIP_INVOICEDOWNLOAD);
            }
        }
    }, []);

    const onSubmit = (data) => {
        console.log(data);
        setVisible(false);
        AuthService.login(LOGIN_TYPE.CUSTOMER + '_' + data.loginid, data.password).then(
            () => {
                if (location.pathname === '/customer/download') {
                    navigate(ROUTE_PATH.MAIN_CUSTOMER + '/' + ROUTE_PATH.DELIVERYSLIP_INVOICEDOWNLOAD);
                } else {
                    //ログイン成功時はメニュー画面へ遷移
                    navigate(ROUTE_PATH.MAIN_CUSTOMER + '/' + ROUTE_PATH.ACCEPT);
                }
            },
            (error) => {
                //ログイン失敗時はエラー表示
                setVisible(true);
            },
        );
    };

    return (
        <FormProvider {...useFormMethods}>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Paper>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={`${process.env.PUBLIC_URL}/images/sanbec-logo.png`} alt="サンベックロゴ" />
                            <Typography component="h1" variant="h4">
                                {PAGE_NAME.LOGIN}
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                                <InputTextLoginId />
                                <InputTextPassword />
                                <Button size="large" type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
                                    ログイン
                                </Button>
                            </Box>
                        </Box>
                        {visible && (
                            <>
                                <Typography color="error" sx={{ ml: 3, fontWeight: 'bold' }}>
                                    {MESSAGE.LOGIN_ERROR}
                                </Typography>
                            </>
                        )}
                    </Paper>
                    <Box my={1} flexDirection="row" justifyContent="flex-end" display="flex">
                        <Link href="/pdf/webedi_manual_c.pdf#page=12" target="_blank" rel="noopener noreferrer" underline="always">
                            {MESSAGE.LOGIN_HELP}
                        </Link>
                    </Box>
                </Container>
            </ThemeProvider>
        </FormProvider>
    );
};
