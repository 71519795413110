/*
 *テキスト入力
 */
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const InputTextBase = ({ name, disabled, maxLength, pattern, required, placeholder, sx, rows, multiline }) => {
    const { control } = useFormContext();

    return (
        <ThemeProvider theme={theme}>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{
                    required: required,
                    maxLength: maxLength,
                    pattern: pattern,
                }}
                render={({ field, fieldState }) => {
                    const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                    return (
                        <TextField
                            sx={sx}
                            {...field}
                            //inputProps={{ maxLength: maxLength, pattern: pattern }}
                            fullWidth
                            label=""
                            disabled={disabled}
                            placeholder={placeholder}
                            error={!!errorMessage}
                            helperText={errorMessage}
                            //textarea用
                            rows={rows}
                            multiline={multiline}
                        />
                    );
                }}
            />
        </ThemeProvider>
    );
};
