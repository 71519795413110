/*
 *項目区切り
 */
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioItemSeparator = () => {
    const { control } = useFormContext();
    return (
        <Controller
            name="itemseparator"
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.itemseparator ? true : false}>
                    <FormLabel>項目区切り</FormLabel>
                    <RadioGroup row aria-labelledby="radio-group-label" name="itemseparator" defaultValue="0">
                        <FormControlLabel value="0" control={<Radio />} label="カンマ区切り" />
                        <FormControlLabel value="1" control={<Radio />} label="タブ区切り" />
                    </RadioGroup>
                    <FormHelperText>{errors.itemseparator?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
