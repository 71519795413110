/*
 *メールを受信するか選択
 */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const SwitchMailReceive = ({ name }) => {
    const { control } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                        control={<Switch checked={value} onChange={onChange} />}
                        label="受信する"
                    />
                )}
            />
        </>
    );
};
