/*
 *営業所選択チェックボックス
 */
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, createTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import GroupList from '../../data/Group.json';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const CheckBoxGroup = ({ name, sx, value }) => {
    const { control } = useFormContext();
    const [checkedValues, setCheckedValues] = useState([]);

    const handleCheck = (data, event) => {
        let checklist = [];
        //0番目が空白文字の場合削除数
        if (checkedValues[0] === '') {
            checkedValues.shift();
        }
        if (checkedValues.includes(event.target.value)) {
            //選択したものがリストにすでにある場合は削除
            checklist = checkedValues.filter((checkedValue) => checkedValue !== event.target.value);
            setCheckedValues(checklist);
        } else {
            //選択したものがリストになければ追加
            checklist = [...checkedValues, event.target.value];
            setCheckedValues(checklist);
        }
        return checklist.join(',');
    };

    useEffect(() => {
        if (value !== null || value !== '') {
            setCheckedValues(value.split(','));
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <FormGroup sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    rules={{ required: '選択してください。' }}
                    render={({ field, fieldState }) => {
                        const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                        return (
                            <>
                                {GroupList.map((data, index) => (
                                    <FormControlLabel
                                        {...field}
                                        name={name}
                                        key={index}
                                        control={<Checkbox />}
                                        label={data.id + data.name}
                                        value={data.id}
                                        checked={checkedValues.includes(data.id)}
                                        onChange={(event) => field.onChange(handleCheck(data, event))}
                                    />
                                ))}
                                <FormHelperText>{errorMessage}</FormHelperText>
                            </>
                        );
                    }}
                />
            </FormGroup>
        </ThemeProvider>
    );
};
