import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { ROUTE_PATH } from '../../constants';

export const LoginResult = (props) => {
    const navigate = useNavigate();
    const [visibleRows, setVisibleRows] = useState(props.rows);

    return (
        <TableSearchResult rows={props.rows} setVisibleRows={setVisibleRows}>
            <TableHeaderRow>
                <TableRow>
                    <TableHead sx={{ width: 130 }}>ログインID</TableHead>
                    <TableHead sx={{ textAlign: 'left' }}>氏名</TableHead>
                    <TableHead sx={{ textAlign: 'left' }}>営業所名</TableHead>
                </TableRow>
            </TableHeaderRow>
            <TableBody>
                {visibleRows.map((row) => (
                    <TableRow key={row.login_id}>
                        <TableCellRow sx={{ textAlign: 'center' }}>
                            <Link
                                style={{ cursor: 'pointer' }}
                                underline="always"
                                onClick={() => {
                                    navigate('../' + ROUTE_PATH.LOGINMAINTE_UPDATE, {
                                        state: {
                                            userinfo: row,
                                            dataList: props.rows,
                                            formData: props.formData,
                                        },
                                    });
                                }}
                            >
                                {row.login_id}
                            </Link>
                        </TableCellRow>
                        <TableCellRow>{row.name}</TableCellRow>
                        <TableCellRow>{row.name_kana}</TableCellRow>
                    </TableRow>
                ))}
            </TableBody>
        </TableSearchResult>
    );
};
