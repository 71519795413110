import { useEffect, useState } from 'react';

import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';

import * as expfnc from '../../common/commonFunction';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { JOB_CAT, LOGIN_TYPE, MESSAGE } from '../../constants';
import fileUploadType from '../../data/FileUploadType.json';
import group from '../../data/Group.json';
import fileUploadService from '../../services/FileUpload.service';
import AuthService from '../../services/auth.service';
import FileUploadDeleteDialog from '../upload/FileUploadDeleteDialog';

export const FileDownloadResult = (props) => {
    const logintype = AuthService.getCurrentUser().logintype;
    const jobcat = AuthService.getCurrentUser().jobcat;
    const [searchRows, setSearchRows] = useState(props.rows);
    const [visibleRows, setVisibleRows] = useState(props.rows);
    const isRowDeleted = (row) => row.delete_flag === '1';
    const [selectedRow, setSelectedRow] = useState();

    const handleDeleteClick = (row) => {
        //ダイアログ内容設定
        setSelectedRow(row);
        //ダイアログ表示
        setDigOpen(true);
    };

    const handleOk = (row) => {
        // サーバー側の処理
        const nowdate = new Date();

        fileUploadService.delete(row.id, nowdate).then(
            (response) => {
                // console.log(response);
                // クリックされた行を非活性にする
                const updatedRowData = visibleRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setVisibleRows(updatedRowData);

                const updatedSearchRowsData = searchRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setSearchRows(updatedSearchRowsData);
                //ダイアログOK時処理
                setDigOpen(false); //ダイアログを閉じる
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                setDigOpen(false); //ダイアログを閉じる
            },
        );
    };

    useEffect(() => {}, []);

    const [digOpen, setDigOpen] = useState(false);
    const [digProp] = useState({
        title: '',
        message: MESSAGE.FILE_UPLOAD_DELETE,
        onClose: () => ({}),
    });

    return (
        <div>
            <TableSearchResult rows={searchRows} setVisibleRows={setVisibleRows}>
                <TableHeaderRow>
                    <TableRow>
                        <TableHead sx={{ width: 200 }}>アップロード日時</TableHead>
                        {logintype === LOGIN_TYPE.ADMIN && <TableHead sx={{ width: 250 }}>仕入先</TableHead>}
                        {logintype === LOGIN_TYPE.ADMIN && <TableHead sx={{ width: 130 }}>営業所</TableHead>}
                        <TableHead sx={{ width: 300 }}>ファイル</TableHead>
                        <TableHead>コメント</TableHead>
                        <TableHead sx={{ width: 150 }}>削除</TableHead>
                    </TableRow>
                </TableHeaderRow>
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}
                            className={isRowDeleted(row) ? 'deleted-row' : ''}
                        >
                            <TableCellRow sx={{ padding: 0 }}>
                                {/* アップロード日時 */}
                                <Box sx={{ ml: 1 }}>
                                    <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>
                                        {expfnc.datetostrformatFileUpDate(row.upload_date)}
                                    </Typography>
                                </Box>
                            </TableCellRow>
                            {logintype === LOGIN_TYPE.ADMIN && (
                                <>
                                    <TableCellRow sx={{ padding: 0 }}>
                                        {/* 仕入先 */}
                                        <Box sx={{ ml: 1 }}>
                                            {row.all_supplier_flg === '1' && (
                                                <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>すべての仕入先</Typography>
                                            )}
                                            {row.supplier_name !== '' && (
                                                <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.supplier_name}</Typography>
                                            )}
                                        </Box>
                                    </TableCellRow>
                                    <TableCellRow sx={{ padding: 0 }}>
                                        {/* 営業所 */}
                                        <Box sx={{ ml: 1 }}>
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>
                                                {expfnc.getJsonData(group, row.group_cd)}
                                            </Typography>
                                        </Box>
                                    </TableCellRow>
                                </>
                            )}

                            <TableCellRow sx={{ padding: 0 }}>
                                {/* ファイル */}
                                <Box sx={{ ml: 1 }}>
                                    {isRowDeleted(row) ? (
                                        <Box display="flex" flexDirection="row">
                                            <Typography style={{ width: '100px', display: 'inline' }}>
                                                {expfnc.getJsonData(fileUploadType, row.file_type) + '：'}
                                            </Typography>
                                            <Typography style={{ color: 'gray' }}>{row.file_name}</Typography>
                                        </Box>
                                    ) : (
                                        <Box display="flex" flexDirection="row">
                                            <Typography style={{ width: '100px', display: 'inline' }}>
                                                {expfnc.getJsonData(fileUploadType, row.file_type)}：
                                            </Typography>

                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'blue' }}>
                                                <Link
                                                    underline="always"
                                                    target="_blank"
                                                    rel="noopener"
                                                    // href={row.file_path + '/' + row.file_name}
                                                    href={row.fileUrl}
                                                    download={row.file_name}
                                                    disabled={isRowDeleted(row)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textAlign: 'left',
                                                        overflowWrap: 'break-word',
                                                        textDecoration: isRowDeleted(row) ? 'none' : 'underline',
                                                    }}
                                                >
                                                    {row.file_name}
                                                </Link>
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </TableCellRow>
                            <TableCellRow sx={{ padding: 0 }}>
                                {/* コメント */}
                                <Box sx={{ ml: 1 }}>
                                    <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.comment}</Typography>
                                </Box>
                            </TableCellRow>
                            <TableCellRow align="center" valign="middle" sx={{ padding: 0 }}>
                                {/* 削除 */}
                                {isRowDeleted(row) ? (
                                    <>
                                        <Typography style={{ color: 'gray' }}>削除済み</Typography>
                                        <Typography style={{ color: 'gray', fontSize: '0.8rem' }}>
                                            {new Date(row.update_dt).toLocaleString()}
                                        </Typography>
                                    </>
                                ) : (
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{ width: 95 }}
                                        onClick={() => handleDeleteClick(row)}
                                        disabled={(logintype === LOGIN_TYPE.SUPPLIER && row.all_supplier_flg === '1') || jobcat === JOB_CAT.SALES}
                                    >
                                        削除
                                    </Button>
                                )}
                            </TableCellRow>
                        </TableRow>
                    ))}
                </TableBody>
            </TableSearchResult>
            <FileUploadDeleteDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => handleOk(selectedRow)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </div>
    );
};
