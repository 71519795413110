/*
 *検索画面用営業所選択
 */
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import GroupList from '../../data/Group.json';

export const SelectSearchGroup = ({ name, label, fullWidth, sx }) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
                <FormControl
                    variant="standard"
                    sx={{ ...sx, minWidth: 240 }}
                    error={errors.select ? true : false}
                    fullWidth={JSON.parse(fullWidth.toLowerCase())}
                >
                    <InputLabel shrink>{label}</InputLabel>
                    <Select labelId="select-label" id="group" label={label} {...field}>
                        <MenuItem key={99} value={''}></MenuItem>
                        {GroupList.map((row, index) => (
                            <MenuItem key={index} value={row.id}>
                                {row.id + row.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors.group?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
