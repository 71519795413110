/**
 * 注文確認データ履歴照会詳細フロート画面
 */
import React from 'react';
import { useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import * as expfnc from '../../common/commonFunction';
import { detailContext, orderContext, totalContext } from '../../components/MainLayout';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import AcceptService from '../../services/Accept.service';
import AcceptDataUploadService from '../../services/AcceptDataUpload.service';

export const AcceptUploadDetail = (props) => {
    const overlay = {
        backgroundColor: 'rgba(0,0,0,0.85)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
    };
    const Modalcontent = {
        borderRadius: '4px',
        backgroundColor: '#F4F8FE',
        position: 'absolute',
        width: '90%',
        height: '90%',
        overflow: 'auto',
    };

    const [getData] = useState(props.datas);
    const [visibleRows, setVisibleRows] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [, setOrder] = useContext(orderContext);
    const [, setTotal] = useContext(totalContext);
    const [, setDetail] = useContext(detailContext);

    //注文状況詳細画面
    const ViewDetail = (row) => {
        AcceptService.search(null, null, null, null, null, row.order_no, null, null, null, null, null, null).then(
            (response) => {
                if (response.length === 0) {
                    alert('データがありません');
                } else {
                    setOrder(response[0]);
                }
            },
            (error) => {
                //検索結果データ取得エラー
            },
        );

        //明細情報取得、設定
        AcceptService.getDetail(row.order_no).then(
            (response) => {
                //検索結果データ取得成功
                if (response.length === 0) {
                } else {
                    setDetail(response);

                    //合計金額計算
                    let t = 0;
                    response.forEach((row) => {
                        if (row.cancel_kbn === '1') return; //キャンセルされたものは除く
                        t = t + row.unit_price * row.order_quantity;
                    });
                    setTotal(t);

                    //■仕入先
                    //新規注文、確定待ち、納期確定、金額確定、計上完了
                    //■サンベック
                    //計上完了、確定待ち、代理更新中
                    //※サンベックの場合はフロート画面へ遷移してから編集画面へ遷移する
                    //　仕入先の場合は上記ステータスであった場合は編集画面
                    //　以外ステータスはフロート表示で編集不可
                    props.setShowDetailModal(false);
                    props.setShowModal(true); //モーダル表示
                }
            },
            (error) => {
                //検索結果データ取得エラー
            },
        );
    };

    //エラー参照画面
    const errClick = (row) => {
        //現在の画面を閉じる
        props.setShowDetailModal(false);
        props.setErrorModal(true);
        props.setDatas(row);
    };

    useEffect(() => {
        AcceptDataUploadService.detailSeach(expfnc.datetostr_mills(getData.upload_date, true)).then(
            (response) => {
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                } else {
                    setDetailData(response);
                    setVisibleRows(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <>
            {props.showDetailModal ? (
                <>
                    <div
                        id="overlay"
                        style={overlay}
                        onClick={() => {
                            props.setShowDetailModal(false);
                        }}
                    >
                        <div
                            id="modalContent"
                            style={Modalcontent}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Box component="main" sx={{ m: 5 }}>
                                <Box
                                    sx={{
                                        justifyContent: 'flex-end',
                                        position: 'fixed',
                                        top: '70px',
                                        right: '110px',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        sx={{ width: 160 }}
                                        onClick={() => {
                                            props.setShowDetailModal(false);
                                        }}
                                    >
                                        閉じる
                                    </Button>
                                </Box>
                                <Typography>注文番号を選択すると注文状況詳細が表示されます。</Typography>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mt: 7,
                                    }}
                                >
                                    <TableContainer>
                                        <Table>
                                            <TableRow>
                                                <TableHead sx={{ width: 300 }}>アップロード日時</TableHead>
                                                <TableHead>コメント</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: 100 }}>正常件数</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: 100 }}>エラー件数</TableHead>
                                            </TableRow>
                                            <TableRow>
                                                <TableCellRow>{expfnc.datetostr(getData.upload_date, false, true)}</TableCellRow>
                                                <TableCellRow>{getData.comment}</TableCellRow>
                                                <TableCellRow sx={{ textAlign: 'right' }}>{getData.normal_count}</TableCellRow>
                                                <TableCellRow sx={{ textAlign: 'right' }}>
                                                    {getData.error_count !== 0 ? (
                                                        <Typography variant="body2" sx={{ color: 'red' }}>
                                                            {getData.error_count}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body2">{getData.error_count}</Typography>
                                                    )}
                                                </TableCellRow>
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mt: 2,
                                    }}
                                >
                                    <TableSearchResult rows={detailData} setVisibleRows={setVisibleRows}>
                                        <TableHeaderRow>
                                            <TableRow>
                                                <TableHead sx={{ textAlign: 'left', width: '12%' }}>注文番号</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '15%' }}>予定納品日</TableHead>
                                                <TableHead sx={{ textAlign: 'left' }}>実行後の注文ステータス</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '15%' }}>注文日時</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '10%' }}>正常明細件数（件）</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '10%' }}>エラー明細件数（件）</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '8%' }}>エラーデータ</TableHead>
                                            </TableRow>
                                        </TableHeaderRow>
                                        <TableBody>
                                            {visibleRows.map((row) => (
                                                <TableRow>
                                                    <TableCellRow sx={{ textAlign: 'left' }}>
                                                        <Link
                                                            style={{ cursor: 'pointer' }}
                                                            underline="always"
                                                            onClick={() =>
                                                                //注文状況詳細;
                                                                ViewDetail(row)
                                                            }
                                                        >
                                                            {row.order_no}
                                                        </Link>
                                                    </TableCellRow>
                                                    <TableCellRow>{expfnc.datetostr(row.scheduled_delivery_date, false, false)}</TableCellRow>
                                                    <TableCellRow> {row.order_status}</TableCellRow>
                                                    <TableCellRow>{expfnc.datetostr_mills(row.order_date, false)}</TableCellRow>
                                                    <TableCellRow sx={{ textAlign: 'right' }}>{row.normal_count}</TableCellRow>
                                                    {row.error_count !== 0 ? (
                                                        <>
                                                            <TableCellRow
                                                                sx={{
                                                                    background: 'rgba(244,67,54,0.3)',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {row.error_count}
                                                            </TableCellRow>
                                                            <TableCellRow>
                                                                <Button variant="outlined" onClick={() => errClick(row)}>
                                                                    参照
                                                                </Button>
                                                            </TableCellRow>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCellRow sx={{ textAlign: 'right' }}>{row.error_count}</TableCellRow>
                                                            <TableCellRow>なし</TableCellRow>
                                                        </>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </TableSearchResult>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};
export default AcceptUploadDetail;
