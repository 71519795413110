/**
 * 注文確認データ履歴照会詳細フロート画面
 */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import * as expfnc from '../../common/commonFunction';
import { TableResultNotCount } from '../../components/table/TableResultNotCount';
import { TableCell, TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import AcceptDataUploadService from '../../services/AcceptDataUpload.service';

export const AcceptUploadError = (props) => {
    const overlay = {
        backgroundColor: 'rgba(0,0,0,0.85)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
    };
    const Modalcontent = {
        borderRadius: '4px',
        backgroundColor: '#F4F8FE',
        position: 'absolute',
        width: '90%',
        height: '90%',
        overflow: 'auto',
    };

    const [getData] = useState(props.datas);
    const [visibleRows, setVisibleRows] = useState([]);
    const [detailData, setDetailData] = useState([]);
    useEffect(() => {
        AcceptDataUploadService.selectError(getData.order_no, expfnc.datetostr_mills(getData.upload_date, true)).then(
            (response) => {
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                } else {
                    setDetailData(response);
                    setVisibleRows(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    return (
        <>
            {props.errorModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setErrorModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ m: 5 }}>
                            <Box
                                sx={{
                                    justifyContent: 'flex-end',
                                    position: 'fixed',
                                    top: '70px',
                                    right: '110px',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160, mr: 2 }}
                                    onClick={() => {
                                        props.setErrorModal(false);
                                        props.setShowDetailModal(true);
                                    }}
                                >
                                    戻る
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ width: 160 }}
                                    onClick={() => {
                                        props.setErrorModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
                                <Box sx={{ mt: 2 }}>
                                    <Table
                                        sx={{
                                            backgroundColor: 'rgba(33,150,243,0.5)',
                                            borderRadius: 2,
                                        }}
                                    >
                                        <TableRow>
                                            <TableHead
                                                sx={{
                                                    backgroundColor: 'rgba(255,255,255,0)',
                                                    width: 200,
                                                }}
                                            >
                                                アップロード日時
                                            </TableHead>
                                            <TableCell sx={{ width: 200 }}>{expfnc.datetostr(getData.upload_date, false, true)}</TableCell>
                                            <TableHead
                                                sx={{
                                                    backgroundColor: 'rgba(255,255,255,0)',
                                                    width: 200,
                                                }}
                                            >
                                                注文番号
                                            </TableHead>
                                            <TableCell sx={{ width: 200 }}>{getData.order_no}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </Table>
                                </Box>
                                <Box sx={{ mt: -1 }}>
                                    <TableResultNotCount rows={detailData} setVisibleRows={setVisibleRows}>
                                        <TableHeaderRow>
                                            <TableRow>
                                                <TableHead sx={{ textAlign: 'left', width: '15%' }}>ファイル行番号</TableHead>
                                                <TableHead sx={{ textAlign: 'left', width: '15%' }}>明細番号</TableHead>
                                                <TableHead sx={{ textAlign: 'left' }}>エラー内容</TableHead>
                                            </TableRow>
                                        </TableHeaderRow>
                                        <TableBody>
                                            {visibleRows.map((row) => (
                                                <TableRow>
                                                    <TableCellRow sx={{ textAlign: 'left' }}>{row.file_line_no}</TableCellRow>
                                                    <TableCellRow>{row.line_no}</TableCellRow>
                                                    <TableCellRow> {row.error_msg}</TableCellRow>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </TableResultNotCount>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default AcceptUploadError;
