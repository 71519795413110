/*
 *表示順序選択
 */
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SortDispAccept from '../../data/sortDispAccept.json';

export const SelectOrderDisp = ({ selectedOrder, onOrderChange }) => {
    return (
        <>
            <FormControl variant="standard" sx={{ width: 150 }}>
                <InputLabel>表示順序</InputLabel>
                <Select value={selectedOrder} onChange={onOrderChange}>
                    {SortDispAccept.map((row) => (
                        <MenuItem key={row.id} value={row.value}>
                            {row.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
