import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { SelectMaster } from '../../components/select/SelectMaster';
import { PAGE_NAME } from '../../constants';
import MstLogService from '../../services/MstLog.service';
import { MstLogResult } from './MstLogResult';

//ヘッダー
export const MstLogHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.MASTERLOG}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>検索条件を指定して、「検索」ボタンを押してください。</Typography>
        </>
    );
};

//メインページ
export const MstLog = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<MstLogHeader />);
    }, []);
    const submit = (data) => {
        console.log(data);
        //ボタン押下時falseではなかった場合、前回の結果が表示されたままになるので
        // falseを設定する
        setIsNoData(false);
        //検索後の値を保持する
        setDateFrom(data.date_from);
        setDateTo(data.date_to);
        let datafrom = null;
        if (data.date_from != null) {
            datafrom = data.date_from.toLocaleDateString('ja-JP');
        }
        let datato = null;
        if (data.date_to != null) {
            datato = data.date_to.toLocaleDateString('ja-JP');
        }
        MstLogService.search(datafrom, datato, data.mst_name, data.key_code).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);

                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                } else {
                    //結果テーブルに送る用にセット
                    setIsNoData(true);
                    setResultData(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    };
    //初期値　現在日付から1ヶ月前の間に更新されたものを表示
    const from = new Date();
    from.setMonth(from.getMonth() - 1);
    const now = new Date();

    //日付範囲初期値
    const [datefrom, setDateFrom] = useState(from);
    const [dateto, setDateTo] = useState(now);

    const useFormMethods = useForm({
        defaultValues: {
            date_from: '',
            date_to: '',
            mst_name: '',
            key_code: '',
        },
    });
    const [resultData, setResultData] = useState();

    const { handleSubmit, setValue } = useFormMethods;
    const [isNoData, setIsNoData] = useState(false);
    useEffect(() => {
        setValue('date_from', from);
        setValue('date_to', now);
    }, []);

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                        }}
                    >
                        <Box sx={{ mx: 3 }}>
                            <Typography>期間</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                            <DatePickerBase name="date_from" value={datefrom} />
                            <Typography align="center" sx={{ flexGrow: 1, mx: 2 }}>
                                ～
                            </Typography>
                            <DatePickerBase name="date_to" value={dateto} />
                        </Box>
                        <Box sx={{ mx: 3, mt: 2 }}>
                            <SelectMaster name="mst_name" label="マスタ" />
                        </Box>
                        <Box sx={{ mx: 3, mt: 2 }}>
                            <InputTextBase name="key_code" placeholder="コード" />
                        </Box>
                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                検索
                            </Button>
                        </Stack>
                    </Paper>
                    <Box sx={{ mt: 2 }}>
                        {/* 検索結果表示 */}
                        {/* isNoDataがtrueだったら表示 */}
                        {isNoData ? <MstLogResult data={resultData} /> : <Typography>対象のデータがありません。</Typography>}
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};
