/**
 * ファイル形式選択
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const RadioFileFormat = ({ name, disabled, handleValueChange }) => {
    const { control } = useFormContext();
    const [, setValue] = useState('1');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="01"
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.status ? true : false}>
                    <RadioGroup
                        row
                        aria-labelledby="radio-group-label"
                        name={field.name}
                        value={field.value === undefined ? '' : field.value}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="全ての項目を出力" disabled={disabled} />
                        <FormControlLabel value="2" control={<Radio />} label="金額確認用の項目を出力" disabled={disabled} />
                    </RadioGroup>
                    <FormHelperText>{errors.status?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
export default RadioFileFormat;
