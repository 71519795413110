/*
 *検索画面用営業所選択
 */
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioGroupSearchOrderLogin = ({ name, label }) => {
    const { control } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.searchorderlogin ? true : false}>
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="radio-group-label"
                        name="searchorderlogin"
                        defaultValue="01"
                        value={field.value === undefined ? '' : field.value}
                    >
                        <FormControlLabel value="01" control={<Radio />} label="ログインID順" />
                        <FormControlLabel value="02" control={<Radio />} label="氏名（カナ）順" />
                    </RadioGroup>
                    <FormHelperText>{errors.searchorderlogin?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
