import ApiClient from './ApiClient';

class FileDownloadService {
    search(datefrom, dateto, groupcd, suppliercd, suppliername) {
        return ApiClient.get(
            'api/fileDownload/search',
            (response) => {
                console.log(response);

                return response;
            },
            {
                params: {
                    datefrom: datefrom,
                    dateto: dateto,
                    groupcd: groupcd,
                    suppliercd: suppliercd,
                    suppliername: suppliername,
                },
            },
        );
    }

    delete(id, update_dt) {
        return ApiClient.post(
            'api/fileDownload/delete',
            (response) => {
                console.log(response);

                return response;
            },
            {
                id: id,
                update_dt: update_dt,
            },
        );
    }

    getFileCustomer(filepath, fileName) {
        return ApiClient.getFileDownLoad(filepath, fileName);
    }

    getFileOrder(orderno) {
        return ApiClient.getFileOrder(orderno);
    }
}

export default new FileDownloadService();
