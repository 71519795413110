import ApiClient from './ApiClient';

class ModifySlipService {
    searchAll() {
        return ApiClient.get('/api/modifyslip/selectAll', (response) => {
            console.log(response);

            return response;
        });
    }

    getDetail(order_no) {
        return ApiClient.get(
            'api/modifyslip/getDetail',
            (response) => {
                console.log(response);
                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    order_no: order_no,
                },
            },
        );
    }
}

export default new ModifySlipService();
