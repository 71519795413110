/*
 *納期未確定分のみ表示
 */
import { Controller, useFormContext } from 'react-hook-form';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const SwitchDeliveryDate = ({ name }) => {
    const { control } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label="納期未確定分のみ表示" />
                )}
            />
        </>
    );
};
