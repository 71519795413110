/**
 * 仕入先マスタ　検索部分
 */
import React, { useContext, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { InputTextEdiCd } from '../../components/input/InputTextEdiCd';
import { MESSAGE, PAGE_NAME } from '../../constants';
import SupplierMainteService from '../../services/SupplierMainte.service';
import { SupplierMainteResult } from './SupplierMainteResult';

export const SupplierHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.SUPPLIERMAINTE}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>仕入先の一覧を表示します。仕入先EDIコードを選択すると詳細画面が表示されます。</Typography>
        </>
    );
};

export const SupplierMainte = () => {
    const setHeader = useContext(headerContext);
    const useFormMethods = useForm({
        defaultValues: {
            supplier_cd: '',
            supplier_name: '',
        },
    });
    const { handleSubmit } = useFormMethods;
    const [resultData, setResultData] = useState();
    const [visible, setVisible] = useState(false);
    const [isNoData, setIsNoData] = useState(true);
    const [msg, setMsg] = useState(MESSAGE.SEARCH);

    // ヘッダー部分
    useLayoutEffect(() => {
        setHeader(<SupplierHeader />);
    });

    const submit = (data) => {
        setVisible(false);
        setIsNoData(false);
        let supCd = data.supplier_cd;
        if (supCd !== '') {
            supCd = supCd.toString().padStart(13, '0');
        }
        SupplierMainteService.search(supCd, data.supplier_name).then(
            (response) => {
                //検索結果データ取得成功
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    console.log('データなし');
                    setVisible(false);
                    setIsNoData(true);
                    setMsg(MESSAGE.NO_DATA_SUPPLIER);
                } else {
                    //結果テーブルに送る用にセット
                    setVisible(true);
                    setResultData(response);
                }
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                setIsNoData(false);
                setVisible(false);
            },
        );
    };

    return (
        <FormProvider {...useFormMethods}>
            <Box component="main">
                {/* 検索条件部分 */}
                <form onSubmit={handleSubmit(submit)}>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                m: 2,
                            }}
                        >
                            <InputTextEdiCd name="supplier_cd" placeholder={'仕入先EDIコード'} maxLength={13} sx={{ mr: 2, width: '30%' }} />
                            <InputTextBase name="supplier_name" placeholder={'仕入先名'} maxLength={125} sx={{ width: '70%' }} />
                        </Box>

                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button type="submit" variant="contained" sx={{ width: 115 }}>
                                検索
                            </Button>
                        </Stack>
                    </Paper>
                </form>

                {/* 検索結果表示 */}
                {visible && (
                    <Box sx={{ pt: 2 }}>
                        <SupplierMainteResult data={resultData} />
                    </Box>
                )}
                {isNoData && (
                    <Box sx={{ m: 2 }}>
                        <Typography sx={{ flexGrow: 1, mb: 1 }}>{msg}</Typography>
                    </Box>
                )}
            </Box>
        </FormProvider>
    );
};
