import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { InputTextBase } from '../../components/input/InputTextBase';
import { TableCell, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { PAGE_NAME } from '../../constants';
import MailChangeService from '../../services/MailChange.service';
import AuthService from '../../services/auth.service';

//ヘッダー
export const CustomerMailChangeHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.CHANGEMAILADDRES}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>連絡先メールアドレスの変更ができます。</Typography>
        </>
    );
};
export const CustomerMailChange = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<CustomerMailChangeHeader />);
    }, []);

    const useFormMethods = useForm({
        defaultValues: {
            mail1: '',
            mail2: '',
            mail3: '',
            mail4: '',
            mail5: '',
        },
    });
    const { handleSubmit, setValue } = useFormMethods;
    const [digOpen, setDigOpen] = useState(false);
    //ダイアログ内の表示内容
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });

    const handleBeforeUnload = (e) => {
        // ページ遷移やリロードのキャンセル
        e.preventDefault();
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    const submit = async (data) => {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        console.log(data);
        //取得したデータを配列にする
        let mailList = [];
        //mail最大登録数が5個に固定 送られてきたデータが空欄出ない場合リストに追加
        if (data.mail1 !== '') {
            mailList.push(data.mail1);
        }
        if (data.mail2 !== '') {
            mailList.push(data.mail2);
        }
        if (data.mail3 !== '') {
            mailList.push(data.mail3);
        }
        if (data.mail4 !== '') {
            mailList.push(data.mail4);
        }
        if (data.mail5 !== '') {
            mailList.push(data.mail5);
        }
        let chkList = true;
        if (mailList.filter((mail) => mail === data.mail1).length > 1) chkList = false;
        if (mailList.filter((mail) => mail === data.mail2).length > 1) chkList = false;
        if (mailList.filter((mail) => mail === data.mail3).length > 1) chkList = false;
        if (mailList.filter((mail) => mail === data.mail4).length > 1) chkList = false;
        if (mailList.filter((mail) => mail === data.mail5).length > 1) chkList = false;

        if (mailList.length === 0) {
            setDigProp({
                ...digProp,
                message: 'メールアドレスは必ず1件入力してください。',
            });
            setProcessing(false);
            submitProcessing.current = false;
            //ダイアログ表示
            setDigOpen(true);
        } else if (!chkList) {
            setDigProp({
                ...digProp,
                message: 'メールアドレスが重複しています。',
            });
            setProcessing(false);
            submitProcessing.current = false;
            //ダイアログ表示
            setDigOpen(true);
        } else {
            //バックエンド側に送るデータ
            //ログインID　メールアドレス
            MailChangeService.customerRegist(
                AuthService.getCurrentUser().edicd,
                mailList,
                AuthService.getCurrentUser().loginid,
                AuthService.getCurrentUser().name,
            ).then(
                (response) => {
                    //検索結果データ取得成功
                    console.log('getData success');
                    console.log(response);

                    setDigProp({
                        ...digProp,
                        message: response.message,
                    });
                    submitProcessing.current = false;
                    setProcessing(false);
                    //ダイアログ表示
                    setDigOpen(true);
                },
                (error) => {
                    submitProcessing.current = false;
                    setProcessing(false);
                    //検索結果データ取得エラー
                    console.log('getData error');
                },
            );
        }
    };

    //メールアドレスを初期表示する
    useEffect(() => {
        MailChangeService.customerSeach(AuthService.getCurrentUser().edicd).then(
            (response) => {
                //検索結果データ取得成功
                console.log('getData success');
                console.log(response);
                let mails = [];
                for (let i = 0; i < response.length; i++) {
                    mails.push(response[i].mail);
                }
                if (response.length !== 5) {
                    for (let j = response.length; j < 5; j++) {
                        //空白を指定
                        mails.push('');
                    }
                }
                setValue('mail1', mails[0]);
                setValue('mail2', mails[1]);
                setValue('mail3', mails[2]);
                setValue('mail4', mails[3]);
                setValue('mail5', mails[4]);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
            },
        );
    }, []);

    const mailmessage = '入力形式がメールアドレスではありません。';

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableHeaderRow sx={{ display: 'flex' }}>メールアドレス</TableHeaderRow>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '100%' }}>
                                            <InputTextBase
                                                name="mail1"
                                                pattern={{
                                                    value: /^[\w\-.+_]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: mailmessage,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '100%' }}>
                                            <InputTextBase
                                                name="mail2"
                                                pattern={{
                                                    value: /^[\w\-.+_]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: mailmessage,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '100%' }}>
                                            <InputTextBase
                                                name="mail3"
                                                pattern={{
                                                    value: /^[\w\-+._]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: mailmessage,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '100%' }}>
                                            <InputTextBase
                                                name="mail4"
                                                pattern={{
                                                    value: /^[\w\-.+_]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: mailmessage,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ width: '100%' }}>
                                            <InputTextBase
                                                name="mail5"
                                                pattern={{
                                                    value: /^[\w\-.+_]+@[\w\-._]+\.[A-Za-z]+/,
                                                    message: mailmessage,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                        <Button type="submit" variant="contained" sx={{ width: 150 }} disabled={processing}>
                            設定を保存
                        </Button>
                    </Stack>
                </Box>
            </form>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => setDigOpen(false)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </FormProvider>
    );
};
