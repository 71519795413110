/*
 *パスワードテキスト入力
 */
import { Controller, useFormContext } from 'react-hook-form';

import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

const theme = createTheme({
    components: {
        MuiFormControl: {
            /* 全体のスタイル */
        },
        MuiFormLabel: {
            /* ラベルのスタイル */
        },
        MuiOutlinedInput: {
            /* フィールドのスタイル */
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                input: {
                    paddingTop: '10px',
                    paddingBottom: '8px',
                    height: 'auto',
                },
                notchedOutline: {
                    top: 0,
                    legend: {
                        display: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            /* ヘルパーテキストのスタイル */
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
    },
});
export const InputTextPassword = ({ name, disabled, placeholder }) => {
    const { control } = useFormContext();
    return (
        <ThemeProvider theme={theme}>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: '入力必須項目です',
                    maxLength: {
                        value: 20,
                        message: '20文字以内で入力してください',
                    },
                    minLength: {
                        value: 5,
                        message: '5文字以上で入力してください',
                    },
                }}
                render={({ field, fieldState }) => {
                    const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                    return (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            type="password"
                            disabled={disabled}
                            placeholder={placeholder}
                            error={!!errorMessage}
                            helperText={errorMessage}
                        />
                    );
                }}
            />
        </ThemeProvider>
    );
};
