import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ACCEPT_STATUS, ROUTE_PATH } from '../../constants';
import { JOB_CAT, LOGIN_TYPE, MESSAGE, PAGE_NAME, UNABLE_EDIT_FLG } from '../../constants';
import AcceptService from '../../services/Accept.service';
import AuthService from '../../services/auth.service';
import { InputTextLoginId } from './InputTextLoginId';
import { InputTextPassword } from './InputTextPassword';

const defaultTheme = createTheme();

export const LoginAdmin = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const useFormMethods = useForm({
        defaultValues: {
            loginid: '',
            password: '',
        },
    });
    const { handleSubmit } = useFormMethods;

    const params = useParams();
    const location = useLocation();
    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (location.pathname === '/admin/download') {
            //保持されているユーザーが仕入先かチェックする
            if (user !== null && user.logintype === LOGIN_TYPE.ADMIN) {
                navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.FILE_DOWNLOAD);
            }
        }
        let eflg = false;
        if (params.order !== void 0 && params.order !== null) {
            //パラメータがある場合は注文データチェック
            const fetchData = async () => {
                try {
                    //ステータスの確認
                    const orderResponse = await AcceptService.getOrder(params.order);
                    if (orderResponse.length !== 0) {
                        if (
                            [ACCEPT_STATUS.PROXY, ACCEPT_STATUS.ACCOUNTING].includes(orderResponse.order_status) &&
                            orderResponse.unable_edit_flg === UNABLE_EDIT_FLG.RELEASE &&
                            (user.jobcat === JOB_CAT.ADMIN || user.jobcat === JOB_CAT.PAYABLE)
                        ) {
                            ///計上完了と代理更新中で更新フラグが更新可
                            //代理更新中の場合は買掛、管理者のみ編集可
                            eflg = true;
                        }
                        //保持されているユーザーが社員かチェックする
                        if (eflg && user !== null && params.order !== void 0 && params.order !== null && user.logintype === LOGIN_TYPE.ADMIN) {
                            // ログイン済みでパラメータが設定されている場合は注文状況詳細画面へ遷移する
                            navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.ACCEPT_DETAIL + '/' + params.order, {
                                state: { orderData: orderResponse },
                            });
                        } else {
                            //編集不可の場合は検索画面
                            navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.ACCEPT + '/' + params.order);
                        }
                    }
                } catch (error) {
                    // エラーハンドリング
                    console.error(error);
                }
            };
            fetchData();
        }
    }, []);

    const onSubmit = (data) => {
        setVisible(false);
        AuthService.login(LOGIN_TYPE.ADMIN + '_' + data.loginid, data.password).then(
            (response) => {
                //ログイン成功時はメニュー画面へ遷移
                if (location.pathname === '/admin/download') {
                    navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.FILE_DOWNLOAD);
                    return;
                }
                if (params.order != null) {
                    const fetchData = async () => {
                        try {
                            //ステータスの確認
                            const orderResponse = await AcceptService.getOrder(params.order);
                            if (orderResponse.length !== 0) {
                                let eflg = false;
                                if (
                                    [ACCEPT_STATUS.PROXY, ACCEPT_STATUS.ACCOUNTING].includes(orderResponse.order_status) &&
                                    orderResponse.unable_edit_flg === UNABLE_EDIT_FLG.RELEASE &&
                                    (response.jobcat === JOB_CAT.ADMIN || response.jobcat === JOB_CAT.PAYABLE)
                                ) {
                                    ///計上完了と代理更新中で更新フラグが更新可
                                    //代理更新中の場合は買掛、管理者のみ編集可
                                    eflg = true;
                                }
                                if (eflg) {
                                    navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.ACCEPT_DETAIL + '/' + params.order, {
                                        state: { orderData: orderResponse },
                                    });
                                } else {
                                    navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.ACCEPT + '/' + params.order);
                                }
                            }
                        } catch (error) {
                            // エラーハンドリング
                            console.error(error);
                        }
                    };
                    fetchData();
                } else {
                    navigate(ROUTE_PATH.MAIN_ADMIN + '/' + ROUTE_PATH.ACCEPT);
                }
            },
            (error) => {
                //ログイン失敗時はエラー表示
                setVisible(true);
            },
        );
    };

    return (
        <FormProvider {...useFormMethods}>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Paper>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={`${process.env.PUBLIC_URL}/images/sanbec-logo.png`} alt="サンベックロゴ" />
                            <Typography component="h1" variant="h4">
                                {PAGE_NAME.LOGIN}
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                                <InputTextLoginId />
                                <InputTextPassword />
                                <Button size="large" type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
                                    ログイン
                                </Button>
                            </Box>
                        </Box>
                        {visible && (
                            <>
                                <Typography color="error" sx={{ ml: 3, fontWeight: 'bold' }}>
                                    {MESSAGE.LOGIN_ERROR}
                                </Typography>
                            </>
                        )}
                    </Paper>
                    <Box my={1} flexDirection="row" justifyContent="flex-end" display="flex">
                        <Link href="/pdf/webedi_manual.pdf" target="_blank" rel="noopener noreferrer" underline="always">
                            {MESSAGE.LOGIN_HELP}
                        </Link>
                    </Box>
                </Container>
            </ThemeProvider>
        </FormProvider>
    );
};
