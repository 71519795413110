import ApiClient from './ApiClient';

class SupplierService {
    searchAll() {
        return ApiClient.get('/api/supplier/searchAll', (response) => {
            console.log(response);

            return response;
        });
    }
    searchAllList() {
        return ApiClient.get('/api/supplier/searchAllList', (response) => {
            console.log(response);

            return response;
        });
    }
    search() {
        return ApiClient.get('/api/supplier/searchSupplier', (response) => {
            console.log(response);

            return response;
        });
    }
}

export default new SupplierService();
