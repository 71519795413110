import ApiClient from './ApiClient';

class CustomerService {
    searchAllList() {
        return ApiClient.get('/api/customer/searchAllList', (response) => {
            console.log(response);

            return response;
        });
    }
}

export default new CustomerService();
