import React, { useContext, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import CommonDialog from '../../components/CommonDialog';
import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import RadioFileFormat from '../../components/radio/RadioFileFormat';
import { SelectSearchGroup } from '../../components/select/SelectSearchGroup';
import { SelectSupplier } from '../../components/select/SelectSupplier';
import { LOGIN_TYPE, PAGE_NAME } from '../../constants';
import ResultDataDownloadServise from '../../services/ResultDataDownload.servise';
import AuthService from '../../services/auth.service';

//ヘッダー
export const ResultDataDownloadHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.RESULTDATA_DOWNLOAD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1, mb: 1 }}>ダウンロードを行う条件を指定してください。</Typography>
        </>
    );
};
//メインページ
export const ResultDataDownload = () => {
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<ResultDataDownloadHeader />);
    }, []);
    const [isData, setIsData] = useState(false);
    //ダイアログ内の表示内容
    const [digOpen, setDigOpen] = useState(false);
    const [digProp, setDigProp] = useState({
        title: '',
        message: '',
        buttonType: 'OkOnly',
    });
    const from = new Date();
    from.setDate(1);
    const now = new Date();
    const [dateform, setDateForm] = useState(from);
    const [dateto, setDateTo] = useState(now);
    const useFormMethods = useForm({
        defaultValues: {
            datefrom: from,
            dateto: now,
            group_cd: null,
            supplier_cd: null,
            type: '1',
        },
    });

    const submit = (data) => {
        console.log(data);
        setIsData(false);

        setDateForm(data.datefrom);
        setDateTo(data.dateto);
        let datefrom = null;
        if (data.datefrom !== null) {
            datefrom = data.datefrom.toLocaleDateString('ja-JP');
        }
        let dateto = null;
        if (data.dateto !== null) {
            dateto = data.dateto.toLocaleDateString('ja-JP');
        }

        /* ログインIDが社員・管理者だったら表示 */
        let supplier_cd = data.supplier;
        if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
            supplier_cd = AuthService.getCurrentUser().edicd;
        }
        console.log(supplier_cd);
        if (supplier_cd === null || supplier_cd === '' || data.group_cd === null || data.group_cd === null) {
            //仕入先、営業所が未入力
            let mes = '営業所・仕入先を選択してください。';
            if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
                mes = '営業所を選択してください。';
            }
            setDigProp({
                ...digProp,
                message: mes,
            });
            setDigOpen(true);

            return;
        }

        ResultDataDownloadServise.download(null, datefrom, dateto, data.group_cd, supplier_cd, data.type).then(
            (response) => {
                console.log(response);
                if (response.length === 0) {
                    setIsData(true);
                } else {
                    if (data.type === '2') {
                        let csvlist = [];
                        for (let i = 0; i < response.length; i++) {
                            //null等関係なくレスポンスされた全ての項目を表示するため加工しなおす
                            csvlist.push({
                                注文番号: response[i].注文番号,
                                行番号: response[i].行番号,
                                注文日時: response[i].注文日時,
                                営業所名: response[i].営業所名,
                                担当者名: response[i].担当者名,
                                客先: response[i].客先,
                                物件名: response[i].物件名,
                                仕入先名: response[i].仕入先名,
                                品名: response[i].品名,
                                規格: response[i].規格,
                                単価: response[i].単価,
                                注文数量: response[i].注文数量,
                                金額: response[i].金額,
                                希望納品日: response[i].希望納品日,
                                予定納品日: response[i].予定納品日,
                                ステータス: response[i].ステータス,
                                代理更新区分: response[i].代理更新区分,
                            });
                        }
                        //ファイル名
                        let nowdate = expfnc.datetostrformat(new Date(), true, true); //時間
                        let failename = '実績データ_' + nowdate + '.csv';
                        expfnc.fileDownloadDialog(failename, expfnc.json2csv(csvlist, 1, 0));
                    } else {
                        //ファイル名
                        let nowdate = expfnc.datetostrformat(new Date(), true, true); //時間
                        let failename = '実績データ_' + nowdate + '.csv';
                        expfnc.fileDownloadDialog(failename, expfnc.json2csv(response, 1, 0));
                    }
                }
            },
            (error) => {
                setDigProp({
                    ...digProp,
                    message: '実績データダウンロードに失敗しました。',
                });
                setDigOpen(true);
            },
        );
    };

    const { handleSubmit } = useFormMethods;

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                        }}
                    >
                        <Box sx={{ mx: 3, display: 'flex', flexDirection: 'column' }}>
                            <Typography>納品日</Typography>
                            {/* 検索時現在日付の月初めから検索時現在日付 */}
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <DatePickerBase name="datefrom" value={dateform} />
                                <Typography align="center" sx={{ flexGrow: 1, mx: 2 }}>
                                    ～
                                </Typography>
                                <DatePickerBase name="dateto" value={dateto} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <SelectSearchGroup name="group_cd" label="営業所" fullWidth="true" />
                                {/* ログインIDが社員・管理者だったら表示 */}
                                {AuthService.getCurrentUser().logintype === LOGIN_TYPE.ADMIN && (
                                    <Box>
                                        <SelectSupplier name="supplier" label="仕入先" fullWidth="true" sx={{ mt: 2 }} />
                                        <Box sx={{ mt: 2 }}>
                                            <RadioFileFormat name="type" />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button type="submit" variant="contained" sx={{ width: 200 }}>
                                    ファイルをダウンロード
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                    {isData && <Typography sx={{ mt: 2, color: 'red' }}>該当するデータがありません。</Typography>}
                </Box>
            </form>
            <CommonDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => ''} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </FormProvider>
    );
};
