import ApiClient from './ApiClient';

class SupplierMainteService {
    search(supplier_cd, supplier_name) {
        return ApiClient.get(
            '/api/supplier/search',
            (response) => {
                return response;
            },
            {
                // getの場合は「params:」でパラメータ指定
                params: {
                    supplier_cd: supplier_cd,
                    supplier_name: supplier_name,
                },
            },
        );
    }

    regist(supplier_cd, password, supplier_name, supplier_kana, status, login_id) {
        return ApiClient.post(
            '/api/supplier/register',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                supplier_cd,
                password,
                supplier_name,
                supplier_kana,
                status,
                login_id,
            },
        );
    }

    update(supplier_cd, password, supplier_name, supplier_kana, status, login_id) {
        return ApiClient.post(
            '/api/supplier/update',
            (response) => {
                console.log(response);
                return response;
            },
            {
                // postの場合は「params:」は不要
                supplier_cd,
                password,
                supplier_name,
                supplier_kana,
                status,
                login_id,
            },
        );
    }

    delete(supplier_cd) {
        return ApiClient.post(
            '/api/supplier/delete',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                supplier_cd,
            },
        );
    }

    mailSeach(supplier_cd) {
        return ApiClient.get(
            '/api/supplier/mailGet',
            (response) => {
                return response;
            },
            {
                params: {
                    supplier_cd: supplier_cd,
                },
            },
        );
    }

    initpass(supplier_cd, password) {
        return ApiClient.post(
            '/api/supplier/initpass',
            (response) => {
                console.log(response);

                return response;
            },
            {
                // postの場合は「params:」は不要
                supplier_cd,
                password,
            },
        );
    }
}

export default new SupplierMainteService();
