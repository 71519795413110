//注文情報詳細に表示する納品先部分のコンポーネント
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TableCell, TableHead, TableRow } from '../../components/table/TableStyled';

export const AcceptDelivery = (props) => {
    return (
        <Box>
            納品先
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableHead>物件名</TableHead>
                                <TableCell>{props.orderData.property_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>納品先名</TableHead>
                                <TableCell>
                                    {props.orderData.delivery_name1}
                                    {props.orderData.delivery_name2}
                                    {props.orderData.delivery_name3}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ height: 106 }}>
                                <TableHead>住所</TableHead>
                                <TableCell>
                                    〒{props.orderData.delivery_add1}
                                    <br />
                                    {props.orderData.delivery_add2}
                                    <br />
                                    {props.orderData.delivery_add3}
                                    <br />
                                    {props.orderData.delivery_add4}
                                    <br />
                                    {props.orderData.delivery_add5}
                                    <br />
                                    {props.orderData.delivery_add6}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>TEL1/担当者</TableHead>
                                <TableCell>
                                    {props.orderData.delivery_tel1} / {props.orderData.delivery_person_name1}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>TEL2/担当者</TableHead>
                                <TableCell>
                                    {props.orderData.delivery_tel2} / {props.orderData.delivery_person_name2}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableHead>FAX</TableHead>
                                <TableCell>{props.orderData.delivery_fax}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};
