/*
 *状態選択
 */
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioState = (props) => {
    const { control } = useFormContext();

    return (
        <Controller
            name="status"
            control={control}
            render={({ field, formState: { errors } }) => (
                <FormControl {...field} error={errors.status ? true : false}>
                    <FormLabel>状態</FormLabel>
                    <RadioGroup row aria-labelledby="radio-group-label" name="jobcategory" defaultValue="9">
                        <FormControlLabel value="9" control={<Radio />} disabled={props.isDisabled} label="すべて" />

                        <FormControlLabel value="1" control={<Radio />} disabled={props.isDisabled} label="有効" />
                        <FormControlLabel value="0" control={<Radio />} disabled={props.isDisabled} label="無効" />
                    </RadioGroup>
                    <FormHelperText>{errors.status?.message || ''}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
