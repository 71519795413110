import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import * as expfnc from '../../common/commonFunction';
import { headerContext } from '../../components/MainLayout';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { TableCellRow, TableHead, TableHeaderRow, TableRow } from '../../components/table/TableStyled';
import { MESSAGE } from '../../constants';
import { PAGE_NAME } from '../../constants';
import fileUploadType from '../../data/FileUploadType.json';
import fileDownloadService from '../../services/FileDownload.service';
import fileUploadService from '../../services/FileUpload.service';
import AuthService from '../../services/auth.service';
import FileUploadDeleteDialog from '../upload/FileUploadDeleteDialog';

//ヘッダー
export const DeliveryslipInvoiceDownloadHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.DELIVERYSLIP_INVOICEDOWNLOAD}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1 }}>ファイルの一覧を表示しています。</Typography>
        </>
    );
};

//メインページ
export const DeliveryslipInvoiceDownload = () => {
    const edicd = AuthService.getCurrentUser().edicd;
    const [acceptDataList, setAcceptDataList] = useState('');
    const [searchRows, setSearchRows] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);
    const isRowDeleted = (row) => row.delete_flag === '1';
    const [selectedRow, setSelectedRow] = useState();

    // 初期処理でヘッダー設定
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<DeliveryslipInvoiceDownloadHeader />);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fileUploadService.searchcustomer(edicd);

                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    const newDataPromises = response.map(async (item) => {
                        const fileUrl = await fileDownloadService.getFileCustomer(item.file_path, item.file_name);

                        return {
                            ...item,
                            fileUrl: fileUrl,
                        };
                    });

                    // Promise.all を使って全ての非同期処理が完了するのを待つ
                    const newData = await Promise.all(newDataPromises);
                    setAcceptDataList(newData);
                    setSearchRows(newData);
                    setVisible(true);
                }
            } catch (error) {
                // 検索結果データ取得エラー
                console.log('getData error', error);
            }
        };

        fetchData();
    }, []);

    const handleDeleteClick = (row) => {
        //ダイアログ内容設定
        setSelectedRow(row);
        // setDigProp({
        //     ...digProp,
        //     onAccept: () => handleOk(row),
        // });

        //ダイアログ表示
        setDigOpen(true);
    };
    const [digOpen, setDigOpen] = useState(false);
    const [digProp] = useState({
        title: '',
        message: MESSAGE.FILE_UPLOAD_DELETE,
        onClose: () => ({}),
    });

    const handleOk = (row) => {
        // サーバー側の処理
        const nowdate = new Date();

        fileUploadService.delete(row.id, nowdate).then(
            (response) => {
                // console.log(response);
                // クリックされた行を非活性にする
                const updatedRowData = acceptDataList.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setAcceptDataList(updatedRowData);
                const updatedSearchRowData = searchRows.map((r) =>
                    r.id === row.id ? { ...r, delete_flag: '1', update_dt: nowdate.toLocaleString() } : r,
                );
                setSearchRows(updatedSearchRowData);
                //ダイアログOK時処理
                setDigOpen(false); //ダイアログを閉じる
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                setDigOpen(false); //ダイアログを閉じる
            },
        );
    };

    return (
        <>
            <Box component="main">
                {visible && (
                    <TableSearchResult rows={searchRows} setVisibleRows={setAcceptDataList}>
                        <TableHeaderRow>
                            <TableRow>
                                <TableHead sx={{ width: 200 }}>アップロード日時</TableHead>
                                <TableHead sx={{ width: 400 }}>ファイル</TableHead>
                                <TableHead>コメント</TableHead>
                                <TableHead sx={{ width: 150 }}>削除</TableHead>
                            </TableRow>
                        </TableHeaderRow>
                        <TableBody>
                            {acceptDataList.map((row) => (
                                <TableRow
                                    key={row.id}
                                    style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}
                                    className={isRowDeleted(row) ? 'deleted-row' : ''}
                                >
                                    <TableCellRow sx={{ padding: 0 }}>
                                        {/* アップロード日時 */}
                                        <Box sx={{ ml: 1 }}>
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>
                                                {expfnc.datetostrformatFileUpDate(row.upload_date)}
                                            </Typography>
                                        </Box>
                                    </TableCellRow>
                                    <TableCellRow sx={{ padding: 0 }}>
                                        {/* ファイル */}
                                        <Box sx={{ ml: 1 }}>
                                            {isRowDeleted(row) ? (
                                                <Typography style={{ color: 'gray' }}>
                                                    {expfnc.getJsonData(fileUploadType, row.file_type) + '：' + row.file_name}
                                                </Typography>
                                            ) : (
                                                <Box display="flex" flexDirection="row">
                                                    <Typography style={{ display: 'inline' }}>
                                                        {expfnc.getJsonData(fileUploadType, row.file_type)}：
                                                    </Typography>
                                                    <Link
                                                        underline="always"
                                                        target="_blank"
                                                        rel="noopener"
                                                        // href={row.file_path + '/' + row.file_name}
                                                        href={row.fileUrl}
                                                        download={row.file_name}
                                                        disabled={isRowDeleted(row)}
                                                        style={{ cursor: 'pointer', textDecoration: isRowDeleted(row) ? 'none' : 'underline' }}
                                                    >
                                                        <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'blue' }}>
                                                            {row.file_name}
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                            )}
                                        </Box>
                                    </TableCellRow>
                                    <TableCellRow sx={{ padding: 0 }}>
                                        {/* コメント */}
                                        <Box sx={{ ml: 1 }}>
                                            <Typography style={{ color: isRowDeleted(row) ? 'gray' : 'black' }}>{row.comment}</Typography>
                                        </Box>
                                    </TableCellRow>
                                    <TableCellRow align="center" valign="middle" sx={{ padding: 0 }}>
                                        {/* 削除 */}
                                        {isRowDeleted(row) ? (
                                            <>
                                                <Typography style={{ color: 'gray' }}>削除済み</Typography>
                                                <Typography style={{ color: 'gray', fontSize: '0.8rem' }}>
                                                    {new Date(row.update_dt).toLocaleString()}
                                                </Typography>
                                            </>
                                        ) : (
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                sx={{ width: 95 }}
                                                onClick={() => handleDeleteClick(row)}
                                                disabled={row.all_customer_flg === '1'}
                                            >
                                                削除
                                            </Button>
                                        )}
                                    </TableCellRow>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableSearchResult>
                )}
                {isNoData && (
                    <Stack my={2} mr={2} direction="row" spacing={1}>
                        {/* エラーメッセージ */}
                        <Typography>{MESSAGE.FILE_UPLOAD_NO_DATA}</Typography>
                    </Stack>
                )}
            </Box>
            <FileUploadDeleteDialog
                title={digProp.title}
                message={digProp.message}
                buttonType={digProp.buttonType}
                open={digOpen}
                onAccept={() => handleOk(selectedRow)} //OK時の処理など
                onClose={() => setDigOpen(false)}
            />
        </>
    );
};
