import React from 'react';

import { MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import * as expfnc from '../../common/commonFunction';
import groupjson from '../../data/Group.json';

function SelectFileUploadGroup({ selectedGroup, onGroupChange, allGroups }) {
    const nonEmptyGroups = allGroups.filter((group_cd) => group_cd !== '');
    return (
        <>
            <FormControl variant="standard" sx={{ width: 150 }}>
                <InputLabel>営業所</InputLabel>
                <Select value={selectedGroup} onChange={onGroupChange}>
                    {nonEmptyGroups.map((group_cd) => (
                        <MenuItem key={group_cd} value={group_cd}>
                            {expfnc.getJsonData(groupjson, group_cd)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

export default SelectFileUploadGroup;
