/*
 *注文確認データアップロード画面アップロード形式選択
 */
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioUploadItems = ({ name, label }) => {
    const { control } = useFormContext();
    const [, setValue] = useState('01');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue="01"
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error ? fieldState.error.message : undefined;
                return (
                    <FormControl {...field} error={!!errorMessage}>
                        <FormLabel sx={{ fontSize: 13 }}>{label}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="radio-group-label"
                            name={field.name}
                            onChange={handleChange}
                            value={field.value === undefined ? '' : field.value}
                        >
                            <FormControlLabel value="01" control={<Radio />} label="必須項目のみ" />
                            <FormControlLabel value="02" control={<Radio />} label="すべての項目" />
                        </RadioGroup>
                        <FormHelperText>{errorMessage}</FormHelperText>
                    </FormControl>
                );
            }}
        />
    );
};
