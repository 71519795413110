import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { headerContext } from '../../components/MainLayout';
import { DatePickerBase } from '../../components/datepicker/DatePickerBase';
import { InputTextBase } from '../../components/input/InputTextBase';
import { SwitchDeliveryDate } from '../../components/switch/SwitchDeliveryDate';
import { MESSAGE } from '../../constants';
import { PAGE_NAME } from '../../constants';
import AcceptService from '../../services/Accept.service';
import { AcceptResultCustomer } from './AcceptResultCustomer';

//ヘッダー
export const AcceptCustomerHeader = () => {
    return (
        <>
            {/* タイトル表示 */}
            <Typography component="h1" variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
                {PAGE_NAME.ACCEPT}
            </Typography>

            {/* 画面説明 */}
            <Typography sx={{ flexGrow: 1 }}>伝票番号を選択すると注文状況詳細画面が表示されます。</Typography>
        </>
    );
};

//メインページ
export const AcceptCustomer = () => {
    // 入力項目の初期値設定
    const defaultFromDate = new Date();
    const defaultToDate = new Date(new Date().setDate(new Date().getDate() + 3));
    const useFormMethods = useForm({
        defaultValues: {
            datefrom: defaultFromDate, //対象日付範囲選択from
            dateto: defaultToDate, //対象日付範囲選択to
            propertyname: '', //物件名
            deliveryaddress: '', //納品先
            personname: '', //担当者
            delivery: false, //納期未確定分のみ表示
        },
    });
    const { handleSubmit, reset, setValue } = useFormMethods;
    const [acceptDataList, setAcceptDataList] = useState('');
    const [isNoData, setIsNoData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [dateform, setDateForm] = useState(new Date());
    const [dateto, setDateTo] = useState(new Date(new Date().setDate(new Date().getDate() + 3)));
    const submitProcessing = useRef(false);
    const [processing, setProcessing] = useState(false);

    // 初期処理でヘッダー設定
    const setHeader = useContext(headerContext);
    useLayoutEffect(() => {
        setHeader(<AcceptCustomerHeader />);
    }, []);

    //初期表示時点で検索を実行する
    useEffect(() => {
        let data = {
            datefrom: defaultFromDate, //対象日付範囲選択from
            dateto: defaultToDate, //対象日付範囲選択to
            propertyname: '', //物件名
            deliveryaddress: '', //納品先
            personname: '', //担当者
            delivery: false,
        };
        search(data);
    }, []);

    // 検索ボタン押下時の処理
    const submit = (data) => {
        search(data);
    };

    function search(data) {
        if (submitProcessing.current) return;
        submitProcessing.current = true;
        setProcessing(true);

        setIsNoData(false);
        setVisible(false);

        let datafrom = null;
        if (data.datefrom != null) {
            datafrom = data.datefrom.toLocaleDateString('ja-JP');
        }
        let datato = null;
        if (data.dateto != null) {
            datato = data.dateto.toLocaleDateString('ja-JP');
        }

        AcceptService.searchCustomer(datafrom, datato, data.propertyname, data.deliveryaddress, data.personname, data.delivery).then(
            (response) => {
                //データなしの場合はメッセージ表示
                if (response.length === 0) {
                    setIsNoData(true);
                } else {
                    setVisible(true);
                    setAcceptDataList(response);
                }
                submitProcessing.current = false;
                setProcessing(false);
            },
            (error) => {
                //検索結果データ取得エラー
                console.log('getData error');
                submitProcessing.current = false;
                setProcessing(false);
            },
        );
    }

    const dateReset = () => {
        //日付の初期化を行う
        setValue('datefrom', null);
        setValue('dateto', null);
        setDateForm(null);
        setDateTo(null);
    };

    const conditionsReset = () => {
        //検索条件の初期化を行う
        reset();
        setValue('datefrom', defaultFromDate);
        setValue('dateto', defaultToDate);
        setDateForm(defaultFromDate);
        setDateTo(defaultToDate);
    };

    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <Box component="main">
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography sx={{ mt: 2, mx: 3 }}>予定納品日</Typography>
                        <Box alignItems="center" sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}>
                            {/* 対象日付範囲選択 */}

                            <DatePickerBase name="datefrom" value={dateform} />
                            <Typography sx={{ flexGrow: 1, mx: 2 }}>～</Typography>
                            <DatePickerBase name="dateto" value={dateto} />

                            <Box>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{ ml: 1, width: 115 }}
                                    onClick={() => {
                                        dateReset();
                                    }}
                                >
                                    日付クリア
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                            {/* 物件名 */}
                            <InputTextBase name="propertyname" placeholder="物件名" />
                            {/* 納品先 */}
                            <InputTextBase name="deliveryaddress" placeholder="納品先住所" sx={{ ml: 1 }} />
                            {/* 担当者入力 */}
                            <InputTextBase name="personname" placeholder="担当者名" sx={{ ml: 1 }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3, mt: 2 }}>
                            {/* 納期未確定分のみ表示 */}
                            <SwitchDeliveryDate name="delivery" />
                        </Box>

                        <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                            <Button type="submit" variant="contained" sx={{ width: 115 }} disabled={processing}>
                                再表示
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{ width: 115 }}
                                onClick={() => {
                                    conditionsReset();
                                }}
                            >
                                条件クリア
                            </Button>
                        </Stack>
                    </Paper>

                    {visible && (
                        <Box sx={{ pt: 2 }}>
                            <AcceptResultCustomer rows={acceptDataList} />
                        </Box>
                    )}
                    {isNoData && (
                        <Stack my={2} mr={2} direction="row" spacing={1}>
                            <Typography>{MESSAGE.NO_DATA_ACCEPT}</Typography>
                        </Stack>
                    )}
                </Box>
            </form>
        </FormProvider>
    );
};
