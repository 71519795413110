// DialogComponent.jsx
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

const ColumnViewDialog = ({ open, handleClose, handleApplyChanges, tempShowItems, handleCheckboxChange, checkboxes }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>表示する項目にチェックしてください</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={0.5}>
                    {checkboxes.map((checkbox) => (
                        <Box key={checkbox.id}>
                            <FormControlLabel
                                control={<Checkbox checked={tempShowItems[checkbox.id]} onChange={() => handleCheckboxChange(checkbox.id)} />}
                                label={checkbox.label}
                                sx={{ ml: 1 }}
                            />
                        </Box>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" style={{ width: 155 }}>
                    キャンセル
                </Button>
                <Button onClick={handleApplyChanges} variant="contained" style={{ width: 155 }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColumnViewDialog;
