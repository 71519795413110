/**
 * 注文書発行検索結果
 */
import { useContext, useState } from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import muiTableCell from '@mui/material/TableCell';
import muiTableHead from '@mui/material/TableHead';
import muiTableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import * as expfnc from '../../common/commonFunction';
import { detailContext, orderContext, totalContext } from '../../components/MainLayout';
import { SelectOrderDisp } from '../../components/select/SelectOrderDisp';
import { TableSearchResult } from '../../components/table/TableSearchResult';
import { LOGIN_TYPE } from '../../constants';
import AcceptPrintStatus from '../../data/AcceptPrintStatus.json';
import acceptStatus from '../../data/AcceptStatus.json';
import group from '../../data/Group.json';
import shippingCompanyCode from '../../data/shippingCompanyCode.json';
import AcceptService from '../../services/Accept.service';
import FileDownloadService from '../../services/FileDownload.service';
import AuthService from '../../services/auth.service';
import { AcceptDetailModal } from '../accept/AcceptDetailModal';
import { AcceptStatusHistory } from '../accept/AcceptStatusHistory';
import { acceptDataListContext } from './AcceptPrint';

const theme = createTheme({
    typography: {
        fontSize: 14,
    },
});

const TableHead = styled(muiTableHead)(() => ({
    borderBottom: 'solid',
    borderBottomColor: grey[200],
    borderBottomWidth: '2px',
}));
const TableHeadCellTop = styled(muiTableCell)(() => ({
    fontWeight: 700,
    fontSize: '14px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableHeadCell = styled(muiTableCell)(() => ({
    //fontWeight: 700,
    fontSize: '14px',
    textAlign: 'left',
    background: '#F9F9F9',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableBodyCell = styled(muiTableCell)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    alignItems: 'center',
    borderBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
}));
const TableRow = styled(muiTableRow)({
    height: 10,
});

export const AcceptPrintResult = (props) => {
    const [statusData, setStatusData] = useState();
    const [showModalHistory, setShowModalHistory] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [acceptDataList, setAcceptDataList] = useContext(acceptDataListContext);
    const [, setOrder] = useContext(orderContext);
    const [, setTotal] = useContext(totalContext);
    const [, setDetail] = useContext(detailContext);
    const [selectedOrder, setSelectedOrder] = useState('delivery_date');
    const [visibleRows, setVisibleRows] = useState(acceptDataList);

    const ViewDetail = (row) => {
        setOrder(row); //注文情報を設定
        //明細情報取得、設定
        AcceptService.getDetail(row.order_no).then(
            (response) => {
                //検索結果データ取得成功
                setDetail(response);

                //合計金額計算
                let t = 0;
                response.forEach((row) => {
                    if (row.cancel_kbn === '1') return; //キャンセルされたものは除く
                    t = t + row.unit_price * row.order_quantity;
                });
                setTotal(t);

                //必ずモーダル表示
                setShowModalDetail(true); //モーダル表示
            },
            (error) => {
                //検索結果データ取得エラー
            },
        );
    };

    const ModalStatusHistory = (row) => {
        setOrder(row); //注文情報を設定
        //ステータス履歴取得
        AcceptService.getStatusLog(row.order_no).then(
            (response) => {
                //ステータス履歴データ取得成功
                console.log('getData success');
                // フロート画面表示用
                setShowModalHistory(true);
                // setResultData(row);
                setStatusData(response);
            },
            (error) => {
                //ステータス履歴データ取得エラー
                console.log('getData error');
            },
        );
    };

    const handleOrderChange = (event) => {
        const newSelectedOrder = event.target.value;
        setSelectedOrder(newSelectedOrder);

        const sortedData = [...props.rows].sort((a, b) => {
            if (newSelectedOrder === 'order_no') {
                return b[newSelectedOrder] - a[newSelectedOrder];
            } else {
                const dateAValue = a.scheduled_delivery_date ? a.scheduled_delivery_date : a.desired_delivery_date;
                const dateBValue = b.scheduled_delivery_date ? b.scheduled_delivery_date : b.desired_delivery_date;
                return new Date(dateBValue) - new Date(dateAValue);
            }
        });
        setVisibleRows(sortedData);
        setAcceptDataList(sortedData);
    };

    const handleClick = async (row) => {
        try {
            const orderno = row.order_no;
            console.log(row.order_no);
            console.log(orderno);

            const fileUrl = await FileDownloadService.getFileOrder(orderno);

            // window.open(fileUrl, '_blank'); ※ブラウザに直接表示はボタン押下ではできない
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.download = `${orderno}.pdf`; // ダウンロード時のファイル名を指定

            // リンクをクリックしてダウンロードを開始
            link.click();

            if (AuthService.getCurrentUser().logintype === LOGIN_TYPE.SUPPLIER) {
                //仕入先の場合は注文書発行ステータスを更新する
                AcceptService.updateOrderIssue(orderno).then(
                    (response) => {
                        //更新後のステータスに変更する
                        const updatedDataList = acceptDataList.map((r) => (r.order_no === orderno ? { ...r, order_issue_status: '03' } : r));
                        setAcceptDataList(updatedDataList);
                    },
                    (error) => {},
                );
            }
        } catch (error) {
            console.error('Error fetching file order:', error);
            // エラーハンドリングを行う
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <SelectOrderDisp selectedOrder={selectedOrder} onOrderChange={handleOrderChange} />
            <TableSearchResult rows={acceptDataList} setVisibleRows={setVisibleRows}>
                <TableHead>
                    <TableRow>
                        <TableHeadCellTop sx={{ width: 180 }}>注文番号</TableHeadCellTop>
                        <TableHeadCellTop sx={{ width: 180 }}>注文日時</TableHeadCellTop>
                        <TableHeadCellTop>物件名・客先</TableHeadCellTop>
                        <TableHeadCellTop sx={{ width: 200 }} rowSpan={1}>
                            注文ステータス
                        </TableHeadCellTop>
                        <TableHeadCellTop sx={{ width: 100 }} rowSpan={3}>
                            注文書
                        </TableHeadCellTop>
                    </TableRow>
                    <TableRow>
                        <TableHeadCell>営業所名</TableHeadCell>
                        <TableHeadCell>希望納品日</TableHeadCell>
                        <TableHeadCell>住所</TableHeadCell>
                        <TableHeadCell rowSpan={2}>(注文書発行ステータス)</TableHeadCell>
                    </TableRow>
                    <TableRow>
                        <TableHeadCell>担当者名</TableHeadCell>
                        <TableHeadCell>予定納品日</TableHeadCell>
                        <TableHeadCell>運送会社・個口・送り状番号</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow key={row.order_no} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableBodyCell>
                                <Box sx={{ m: 1 }}>
                                    {/* 注文番号 */}
                                    <Link style={{ cursor: 'pointer' }} onClick={() => ViewDetail(row)}>
                                        <Typography>{row.order_no}</Typography>
                                    </Link>
                                    <Typography>{expfnc.getJsonData(group, row.group_cd)}</Typography>
                                    <Typography>{row.person_name}</Typography>
                                </Box>
                            </TableBodyCell>
                            <TableBodyCell>
                                <Box sx={{ ml: 1 }}>
                                    <Typography>{expfnc.datetostr(row.order_date, false, true)}</Typography>
                                    <Typography>{expfnc.datetostr(row.desired_delivery_date)}</Typography>
                                    <Typography>{expfnc.datetostr(row.scheduled_delivery_date)}</Typography>
                                </Box>
                            </TableBodyCell>
                            <TableBodyCell>
                                <Typography>
                                    {row.property_name}
                                    {row.customer_name != null && <>・{row.customer_name}</>}
                                </Typography>
                                <Typography>
                                    {row.delivery_add1}
                                    {row.delivery_add2}
                                </Typography>
                                <Typography>
                                    {expfnc.getJsonData(shippingCompanyCode, row.shipping_company_code)}
                                    {row.number_of_pieces != null && <>・{String(row.number_of_pieces)}個口</>}
                                    {row.destination_number != null && <>・{row.destination_number}</>}
                                </Typography>
                            </TableBodyCell>

                            {/* 注文ステータス */}
                            <TableBodyCell>
                                <Typography>
                                    <Link style={{ cursor: 'pointer' }} underline="always" onClick={() => ModalStatusHistory(row)}>
                                        {expfnc.getJsonData(acceptStatus, row.order_status)}
                                    </Link>
                                </Typography>
                                <Typography>({expfnc.getJsonData(AcceptPrintStatus, row.order_issue_status)})</Typography>
                            </TableBodyCell>
                            <TableBodyCell>
                                <Button type="button" variant="outlined" sx={{ width: 80 }} onClick={() => handleClick(row)}>
                                    発行
                                </Button>
                            </TableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
                {showModalHistory ? (
                    <AcceptStatusHistory
                        showModal={showModalHistory}
                        // orderData={resultData}
                        statusData={statusData}
                        setShowModal={setShowModalHistory}
                    />
                ) : (
                    <></>
                )}
                {showModalDetail ? (
                    <AcceptDetailModal
                        showModal={showModalDetail}
                        // orderData={resultData}
                        setShowModal={setShowModalDetail}
                        editflg={false}
                    />
                ) : (
                    <></>
                )}
            </TableSearchResult>
        </ThemeProvider>
    );
};
