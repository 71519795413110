import React from 'react';

import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

const drawerWidth = 300;
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    pr: '24px', // keep right padding when drawer closed
    backgroundColor: 'white',
    color: 'black',
    '@media all': {
        minHeight: 128,
    },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    width: `calc(100% - ${theme.spacing(7)} + 8px)`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
        //marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px + 12px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '@media all': {
            minHeight: 128,
        },
    }),
}));

export const Header = (props) => {
    return (
        <>
            <AppBar position="sticky" open={props.open} sx={{ ml: props.open === true ? 36 : 6 }}>
                <StyledToolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 1,
                        }}
                    >
                        {props.children}
                    </Box>
                </StyledToolbar>
            </AppBar>
        </>
    );
};
