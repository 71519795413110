import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ROUTE_PATH } from './constants';
import AuthService from './services/auth.service';

const defaultTheme = createTheme();

export const NotFound = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        AuthService.logout();
        const segmentList = location.pathname.split('/');
        if (!segmentList.includes('error')) {
            navigate('/error');
            window.location.reload();
        }
    }, []);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // 中央揃えを追加
                    justifyContent: 'center', // 中央揃えを追加
                    minHeight: '100vh', // ウィンドウの高さいっぱいに広がるように設定
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 500,
                        height: 140,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 30 }}>404 Not Found</Typography>
                        <Typography sx={{ fontSize: 30 }}>ページが見つかりませんでした。</Typography>
                    </Box>
                </Paper>
                <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ width: 240, mt: 1, mb: 1 }}
                        onClick={() => {
                            navigate(ROUTE_PATH.CUSTOMER);
                        }}
                    >
                        お客様サイトにログイン
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        sx={{ width: 240, mt: 1, mb: 2 }}
                        onClick={() => {
                            navigate(ROUTE_PATH.SUPPLIER);
                        }}
                    >
                        仕入れ先サイトにログイン
                    </Button>
                    <Link underline="always" sx={{ mt: 1 }} to={ROUTE_PATH.ADMIN}>
                        サンベックサイトにログイン
                    </Link>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
